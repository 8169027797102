import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputBase,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import globalStyles from "../helpers/GlobalStyles";
import dialogStyles from "./DialogStyles";
import Endpoint from "../endpoint";
import { alertError } from "../helpers";

function AddUser(props) {
  const classes = dialogStyles();
  const globalClasses = globalStyles();
  const { handleClose, open, isAdmin, isClientAdmin, clientName } = props;

  const handleSubmit = async (values, { setSubmitting }) => {
    await Endpoint.Users.register(values)
      .then((res) => {
        console.log(res);
        Swal.fire({
          customClass: {
            container: classes.swal,
          },
          title: "Success!",
          html: "User has been added",
          icon: "success",
        }).then(() => {
          handleClose();
        });
      })
      .catch((err) => {
        if (err.response) {
          alertError(`${err.response.data.msg}`, err);
          setSubmitting(false);
        } else {
          alertError("Something went wrong", err);
          setSubmitting(false);
        }
      });
  };

  return (
    <Dialog
      aria-labelledby="Add a user dialog"
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title} variant="h6">
          Add a user
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          username: "",
          // email: '',
          password: "",
          confirmPassword: "",
          role: "user",
          clientName: clientName ? clientName : undefined,
        }}
        validationSchema={object().shape({
          username: string().required("Required.").min(6).max(20),
          // email: string().email().required('Required'),
          password: string().required("Required.").min(6).max(20),
          confirmPassword: string().required("Required.").min(6).max(20),
          clientName: string().required("Required."),
        })}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ values, isSubmitting, handleChange, setFieldValue, errors }) => (
          <Form className={`${classes.form} ${globalClasses.flexColumn}`}>
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="username">Enter Username*</label>
              <InputBase
                className={clsx(classes.dialogInput, globalClasses.input)}
                name="username"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.username}
              />
              {errors.username && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.username}
                </div>
              )}
            </div>
            {/* <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="email">Enter Email*</label>
              <InputBase
                className={clsx(classes.dialogInput, globalClasses.input)}
                name="email"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.email}
              />
              {errors.email && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.username}
                </div>
              )}
            </div> */}
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="password">Enter Password*</label>
              <InputBase
                className={clsx(classes.dialogInput, globalClasses.input)}
                name="password"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.password}
              />
              {errors.password && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.password}
                </div>
              )}
            </div>
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="confirmPassword">Confirm Password*</label>
              <InputBase
                className={clsx(classes.dialogInput, globalClasses.input)}
                name="confirmPassword"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.email}
              />
              {errors.confirmPassword && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.confirmPassword}
                </div>
              )}
            </div>
            {values.role !== "client_admin" && isAdmin ? (
              <FormControlLabel
                className={classes.checkBox}
                control={
                  <Checkbox
                    defaultValue={false}
                    color="primary"
                    onChange={(e) =>
                      setFieldValue("role", e.target.checked ? "admin" : "user")
                    }
                  />
                }
                label="Is an admin"
              />
            ) : (
              <></>
            )}
            {values.role !== "admin" ? (
              <FormControlLabel
                className={classes.checkBox}
                control={
                  <Checkbox
                    defaultValue={false}
                    color="primary"
                    onChange={(e) =>
                      setFieldValue(
                        "role",
                        e.target.checked ? "client_admin" : "user"
                      )
                    }
                  />
                }
                label="Is a Client Admin"
              />
            ) : (
              <></>
            )}

            {values.role === "client_admin" ? (
              <div className={`${classes.field} ${globalClasses.flexColumn}`}>
                <label htmlFor="clientName">Client Company Name*</label>
                <InputBase
                  className={clsx(classes.dialogInput, globalClasses.input)}
                  name="clientName"
                  onChange={handleChange}
                  disabled={isSubmitting}
                  error={!!errors.email}
                />
                {errors.clientName && (
                  <div
                    className={`${clsx(
                      globalClasses.validateErrorRed,
                      globalClasses.validateError
                    )}`}
                  >
                    {errors.clientName}
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={`${globalClasses.button}`}
              >
                Submit
                {isSubmitting && (
                  <LinearProgress
                    color="primary"
                    className={globalClasses.linearProgress}
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default AddUser;
