import React, { useEffect } from "react";
import globalStyles from "../helpers/GlobalStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    dialogTitle: {
      display: "flex",
      justifyContent: "center",
      padding: "3vh",
    },
    closeButton: {
      padding: "5px",
    },
    title: {
      fontSize: "20px",
      fontWeight: 600,
      color: "#147CFF",
      textAlign: "center",
      padding: "3vh",
    },
    body: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#0D8F75",
      textAlign: "center",
      padding: "10px",
    },
    dialogRoot: {
      minWidth: "400px",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // Target the first child of the dialog content
      "&:first-child": {
        padding: "0px",
      },
    },
    loading: {
      marginTop: "15px",
    },
  }),
  { index: 1 }
);

const ZillowCheck = ({
  showUploadBatchDialog,
  setShowUploadBatchDialog,
  handleCloseUploadBatchDialog,
  handleSubmit,
  msg,
  type,
  values,
}) => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  return (
    <Dialog
      open={showUploadBatchDialog}
      classes={{ paper: classes.dialogRoot }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setShowUploadBatchDialog(false);
        }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description" className={classes.title} variant="h6">
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Button
          className={`${globalClasses.actionButton} `}
          style={{ marginLeft: "1%" }}
          onClick={() => setShowUploadBatchDialog(false)}
        >
          No
        </Button>
        <Button
          className={`${globalClasses.actionButton} `}
          style={{ marginLeft: "1%" }}
          type={type == 1 ? "submit" : null}
          onClick={() => {
            if (type == 1) {
              handleSubmit(values, type);
            } else if (type == 2) {
              handleSubmit(values, type);
            }
          }}
        >
          Yes
        </Button>
      </DialogTitle>
    </Dialog>
  );
};

export default ZillowCheck;
