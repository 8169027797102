import { FieldArray, Form, Formik } from "formik";
import globalStyles from "../../helpers/GlobalStyles";
import { getHeaderLabel } from "../../helpers/helper";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(
  (theme) => ({
    content: {
      width: "100%",
      paddingTop: "calc(3vh + 50px)",
      paddingLeft: "2vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },

    updateBatchContent: {
      width: "100%",
      paddingTop: "calc(3vh + 50px)",
      paddingLeft: "1%",
      fontFamily: "Helvetica Neue",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
      alignItems: "center",
      marginBottom: "10px",
    },
    redoButton: {
      backgroundColor: theme.palette.primary.main,

      fontSize: ".8em",
      backgroundColor: "#f8f8f8",
      textTransform: "inherit",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
        color: "white",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
    actionButton: {
      backgroundColor: theme.palette.primary.main,
      fontSize: ".8em",
      color: "#ffffff",
      textTransform: "inherit",
      borderRadius: "50px",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      border: "1px solid #3C72D9",
      padding: "8px",
      minWidth: "100px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
    fileLoadingStyles: {
      marginLeft: "5%",
      display: "flex",
      alignItems: "center",
    },
    uploadBatchText: {
      marginBottom: "30px",
    },
    rightSide: {
      width: "100%",
    },
    caseContainer: {
      width: "98%",
      marginTop: "5vh",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    clientTable: {
      width: "100%",
      tableLayout: "auto",
      fontSize: "1em",
      borderCollapse: "collapse",
      whiteSpace: "nowrap",
    },
    tableContainer: {
      width: "100%",
      overflowX: "auto",
    },
    tableTh: {
      textAlign: "center",
      padding: "10px",
      textTransform: "capitalize",
      whiteSpace: "nowrap",
    },
    tableTd: {
      padding: "10px",
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    tableRow: {
      "&:nth-child(even)": {
        backgroundColor: "#f9f9f9",
      },
    },

    clientthtr: {
      backgroundColor: "#0000",
      borderRadius: "25px",
      paddingTop: "5px",
    },

    tableTd: {
      whiteSpace: "nowrap",
    },
    tableTdName: {
      cursor: "pointer",
      color: "#197EFF",
      paddingLeft: "10px",
      whiteSpace: "nowrap",
    },
    tableTh: {
      textAlign: "center",
      whiteSpace: "nowrap",
      textTransform: "capitalize",
    },
    tableIconsAlert: {
      marginRight: "15px",
      width: "20px",
      "&:hover": {
        color: "#FFD700",
        "& path": {
          fill: "#000",
        },
      },
    },
    tableIconsUnskip: {
      overflow: "visible",
      cursor: "pointer",
      width: "20px",
      "&:hover": {
        color: "#147CFF",
      },
    },
    tableIconsSkip: {
      overflow: "visible",
      cursor: "pointer",
      width: "20px",
      color: "#147CFF",
      "&:hover": {
        color: "red",
      },
    },
    parentChild: {
      height: "50px",
      flex: "1",
      width: "100%",
      "&:nth-child(1)": {
        paddingRight: "5vh",
      },
      "&:nth-child(2)": {
        paddingLeft: "5vh",
      },
    },
    rowContainer: {
      marginTop: "8px",
    },
    fieldContainer: {},
    input: {
      width: "95%",

      "& .MuiInputBase-input": {
        textIndent: "10px",
        textTransform: "capitalize",
        textAlign: "start",
      },
      height: "40px",
      paddingLeft: "3px",
    },
  }),
  { index: 1 }
);

function DataTable({ headers, reportData }) {
  const classes = useStyles();
  const globalClasses = globalStyles();

  return (
    <>
      <div
        className={globalClasses.flexRow}
        style={{ alignContent: "center", marginBottom: 20 }}
      ></div>
      <Formik enableReinitialize initialValues={{ data: reportData }}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <FieldArray
                name="data"
                render={(arrayHelpers) => (
                  <>
                    <div className={classes.tableContainer}>
                      <table className={classes.clientTable}>
                        <thead className={globalClasses.tableHead}>
                          <tr className={globalClasses.clientthtr}>
                            {reportData.length > 0 &&
                              headers.map((header) => (
                                <th
                                  className={classes.tableTh}
                                  key={header}
                                  style={{ padding: 10 }}
                                >
                                  {getHeaderLabel(header)}
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {values.data.map((row, index) => (
                            <tr
                              className={classes.tableRow}
                              key={index}
                              style={{
                                fontSize: "1em",
                                color: "#4e4e4e",
                              }}
                            >
                              {headers.map((header) => (
                                <td
                                  className={classes.tableTd}
                                  key={header}
                                  style={{ padding: 10, textAlign: "center" }}
                                >
                                  {values.data[index][header]}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default DataTable;
