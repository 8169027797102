import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) => ({
    dialogTitle: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px',
      marginRight: '10px',
      padding: 0,
    },
    closeButton: {
      padding: '5px',
    },
    title: {
      fontSize: '20px',
      fontWeight: 600,
      // color: '#0D8F75',
      color: '#147CFF',
      textAlign: 'center',
      padding: '10px',
    },
    body: {
      fontSize: '20px',
      fontWeight: 500,
      color: '#0D8F75',
      textAlign: 'center',
      padding: '10px',
    },
    dialogRoot: {
      minWidth: '400px',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '35px',
    },
    loading: {
      marginTop: '15px',
    },
    downloadContent: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    actionButton: {
      width: '34px',
      height: '34px',
      padding: 0,
      marginLeft: '14px',
      backgroundColor: '#0D8F75',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: '#0D8F75',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    downloadIcon: {
      width: '20px',
      height: '20px',
      padding: '6px',
      borderRadius: '50%',
      backgroundColor: '#0D8F75',
      transform: 'rotate(180deg)',
    },
  }),
  { index: 1 }
);

const NoSearchResults = ({
  showNoSearchResultsDialog,
  handleCloseNoSearchResultsDialog,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={showNoSearchResultsDialog}
      classes={{ paper: classes.dialogRoot }}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleCloseNoSearchResultsDialog();
        }
      }}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseNoSearchResultsDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.title}
          variant="h6"
        >
          The search yielded no results.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default NoSearchResults;
