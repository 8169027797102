import React from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GlobalStyles from "../helpers/GlobalStyles";

const useStyles = makeStyles(
  (theme) => ({
    loading: {
      // width: "100%",
      height: "100%",
      // paddingTop: pageLoading ? "25%" : "0%",
      justifyContent: "center",
      alignItems: "center",
    },
    pageloading: {
      // width: "100%",
      // height: "100%",
      paddingTop: "25%",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
  { index: 1 }
);

export default function Loading({ pageLoading }) {
  const globalClasses = GlobalStyles();
  const classes = useStyles();
  return (
    <>
      {pageLoading ? (
        <div className={`${classes.pageloading} ${globalClasses.flexColumn}`}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div className={`${classes.loading} ${globalClasses.flexColumn}`}>
          <CircularProgress color="primary" />
        </div>
      )}
    </>
  );
}
