import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { AuthWrapper } from "./auth/AuthWrapper";

ReactDOM.render(
  <React.StrictMode>
    <AuthWrapper>
      <App />
    </AuthWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);
