import { createContext, useContext, useState, useEffect } from "react";

import { LOGIN } from "../types/routeConstants";

import Endpoint from "../endpoint";
import { alertError } from "../helpers";

import {
  USER,
  REFRESH_TOKEN,
  ID_TOKEN,
  ACCESS_TOKEN,
  ORDER_TYPE,
} from "../types/miscellaneous-constants";

export const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // For React Routing (Private/Public)
  const [user, setUser] = useState(false); // Stores data for the user as JSON
  const [isLoading, setLoading] = useState(true); // Loading for the app
  const [orderTypes, setOrderTypes] = useState(null); // Get Order types

  useEffect(async () => {
    await getSession(); // get current session on reload
    setLoading(false);
  }, []);

  // useEffect(async () => {
  //   console.log(orderTypes);
  // }, [orderTypes]);

  // Function to get Order Types and store in localstorage
  const getOrderTypes = async () => {
    Endpoint.Clients.getOrderType()
      .then((res) => {
        console.log(res);
        setOrders(res.data);
        setOrderTypes(res.data);
        localStorage.setItem(ORDER_TYPE, JSON.stringify(res.data));
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // login function used in Login.jsx
  const login = async (values) => {
    // Make a call to the authentication API to check the username
    Endpoint.Users.login(values)
      .then(async (result) => {
        if (result.data) {
          setIsAuthenticated(true);

          const accessToken = result?.data?.tokens?.access;
          const idToken = result?.data?.tokens?.idToken;
          const refreshToken = result?.data?.tokens?.refresh;

          // Setup user JSON
          const userData = {
            ...result?.data?.user,
            isAdmin: result?.data?.user?.role == "admin" ? true : false,
            isClientAdmin:
              result?.data?.user?.role == "client_admin" ? true : false,
            clientName: result?.data?.user?.clientName
              ? result?.data?.user?.clientName
              : false,
          };
          // console.log(result.data);

          if (accessToken && idToken && refreshToken) {
            // Store tokens and user data in localstorage
            setSession(accessToken, idToken, refreshToken, userData);
          }

          try {
            await getOrderTypes();
            setLoading(false);
            return result;
          } catch (error) {
            console.log(error);
          }
        }
      })

      .catch((err) => {
        console.log("Login rejected");
        console.log(err);
        if (err.response) {
          alertError(`${err.response.data.msg}`, err);
          // setSubmitting(false);
        } else {
          alertError("Something went wrong", err);
          // setSubmitting(false);
        }
      });
  };

  // Function to be called to update terms and conditions for role "user" when they login.
  const updateUserTandC = async () => {
    setUser({
      ...JSON.parse(localStorage.getItem(USER)),
      terms_and_conditions: true,
    });
    return true;
  };

  // Function to store orderTypes
  const setOrders = async (orderTypes) => {
    localStorage.setItem(ORDER_TYPE, orderTypes);
  };

  // Function to store tokens and user data in localstorage
  const setSession = async (accessToken, idToken, refreshToken, userData) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(ID_TOKEN, idToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(USER, JSON.stringify(userData));
    setUser(JSON.parse(localStorage.getItem(USER)));
    setIsAuthenticated(true);
  };

  // Function to GET tokens, order types and user data from localstorage
  const getSession = async () => {
    if (
      localStorage.getItem(ACCESS_TOKEN) ||
      localStorage.getItem(ID_TOKEN) ||
      localStorage.getItem(REFRESH_TOKEN)
    ) {
      setIsAuthenticated(true);
      setUser({
        ...JSON.parse(localStorage.getItem(USER)),
        terms_and_conditions: true,
      });
      setOrderTypes(JSON.parse(localStorage.getItem(ORDER_TYPE)));
      return true;
    }
    return false;
  };

  // Function to delete tokens and user data in localstorage
  const delSession = async () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(USER);
  };

  // Function to logout user
  const logout = async () => {
    delSession();
    Endpoint.Users.logout()
      .then((res) => {
        setIsAuthenticated(false);
        window.location = "/login";
      })
      .catch((err) => {
        console.log(err);
        // if (err.response && err.response.status !== 401) {
        //   Swal.fire(
        //     "something went wrong",
        //     err.response ? err.response.data.message : err.message,
        //     "error"
        //   );
        // }
      });
    return true;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        user,
        orderTypes,
        updateUserTandC,
        logout,
        isLoading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
