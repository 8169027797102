import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
import globalStyles from "../helpers/GlobalStyles";

const useStyles = makeStyles(
  (theme) => ({
    header: {
      backgroundColor: "#e7e7e7",
    },

    paginator: {
      justifyContent: "center",
      padding: "10px",
    },
    selectedSearchAddress: {
      color: "#0D8F75",
      fontWeight: 800,
    },
    selectedUpdateBatch: {
      color: "#0D8F75",
      fontWeight: 800,
    },
    selectedAccount: {
      color: "#0D8F75",
      fontWeight: 800,
      // width: "50px !important",
    },
    accountContent: {
      width: "100%",
      paddingTop: "calc(30vh + 100px)",
      paddingLeft: "10vh",
      marginRight: "16vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    dialogInput: {
      width: "40%",
    },
  }),
  { index: 1 }
);

function Dashboard(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const { statusList, isAdmin } = props;
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  // const [connectionId, setConncectionId]= useState(false);
  const [noOfPages] = React.useState(
    Math.ceil(statusList?.length / itemsPerPage)
  );

  const floorFigure = (figure, decimals) => {
    if (!decimals) decimals = 2;
    const d = Math.pow(10, decimals);
    return (parseInt(figure * d) / d).toFixed(decimals);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    const floorFigure = (figure, decimals) => {
      if (!decimals) decimals = 2;
      const d = Math.pow(10, decimals);
      return (parseInt(figure * d) / d).toFixed(decimals);
    };

    const handleChange = (event, value) => {
      setPage(value);
    };
  }, [statusList]);
  // console.log(statusList)

  return (
    <>
      <table>
        <thead className={globalClasses.tableHead}>
          <tr>
            <th style={{ minWidth: "20px" }}>#</th>
            <th>First name</th>
            <th>Last name</th>
            <th>Current Street</th>
            <th>Current City</th>
            <th>Current State</th>
            <th>Current Zip Code</th>
            <th>Occupancy Status</th>
            {isAdmin && <th>Score</th>}
          </tr>
        </thead>
        <tbody>
          {statusList
            ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((status, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{status?.first_name}</td>
                <td>{status?.last_name}</td>
                <td>{status?.street}</td>
                <td>{status?.city}</td>
                <td>{status?.state}</td>
                <td>{status?.zipcode}</td>
                <td
                  style={{
                    background:
                      status?.prediction > 0 && status?.prediction < 0.47
                        ? "#86DE74"
                        : status?.prediction >= 0.47 &&
                          status?.prediction <= 0.53
                        ? "#E6CC00"
                        : status?.prediction > 0.53 && status?.prediction < 1
                        ? "#D50000"
                        : "",
                    color:
                      (status?.prediction > 0 && status?.prediction <= 0.47) ||
                      (status?.prediction > 0.53 && status?.prediction) < 1
                        ? "#FFF"
                        : "#333",
                    borderBottomRightRadius:
                      !isAdmin && statusList.length === index + 1 ? "10px" : 0,
                  }}
                >
                  {status?.prediction > 0 && status?.prediction < 0.47
                    ? "Occupied"
                    : status?.prediction >= 0.47 && status?.prediction <= 0.53
                    ? "Unknown"
                    : status?.prediction > 0.53 && status?.prediction < 1
                    ? "Vacant"
                    : ""}
                </td>
                {isAdmin && <td>{floorFigure(status?.prediction, 3)}</td>}
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        count={noOfPages}
        page={page}
        onChange={handleChange}
        defaultPage={1}
        color="primary"
        showFirstButton
        showLastButton
        classes={{ ul: classes.paginator }}
      />
    </>
  );
}
export default Dashboard;
