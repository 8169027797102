import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import globalStyles from "../../helpers/GlobalStyles";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  Input,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  Divider,
  TextareaAutosize,
  Tooltip,
} from "@material-ui/core";
import Select from "react-select";
import {
  useFormikContext,
  getIn,
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
} from "formik";
import { useSnackbar } from "notistack";
import InputMask from "react-input-mask";

import FormikRadioGroup from "./FormikRadioGroup";
import { array, date, number, object, string, boolean } from "yup";
import * as Yup from "yup";
import Endpoint from "../../endpoint";
// import OpenDialogIcon from "../../icons/OpenDialog";
import { states } from "../../helpers/states";
import ClientField from "../../components/shared/InputField";
import FMUInputField from "../../components/shared/FormIkMUFormComponent/FMUInputField";
// import ContactsDialog from "./ContactsDialog";
import { generateQuickGuid, getTaxIDMask } from "../../helpers/helper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MaskField from "../../components/shared/MaskField";
import FeeTab from "./FeeTab";
import { daysOfTheWeek } from "./../../helpers/daysOfTheWeek";
import { getDates } from "./../../helpers/helper";
// import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIosNewIcon";
import InvoiceField from "../../components/shared/InvoiceFields/InvoiceField";
import FormError from "../../components/FormError";

import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";

import { initialState } from "./clientInitialState";
import Loading from "../Loading";
import { CLIENT } from "../../types/routeConstants";
import { AuthContext } from "../../auth/AuthWrapper";

// import DraftField from "./DraftField";
// import { Editor, EditorState } from "draft-js";
// import "draft-js/dist/Draft.css";

const useStyle = makeStyles((theme) => ({
  container: {
    paddingTop: "calc(5vh + 60px)",
    paddingLeft: "2%",
    paddingRight: "2%",
  },
  caseContainer: {
    // width: "928px",
    width: "98%",
    paddingLeft: "1%",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      width: "98%",
      paddingLeft: "1%",
    },
  },
  clientGridBorder: {
    border: "1px solid #EAEAEA",
  },
  clientGridItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  invoiceContainer: {
    display: "flex",
    marginBottom: "12px",
    marginTop: "15px",
    // justifyContent: "space-around",
    // marginLeft: "30px !important",
  },
  invoiceEmailItem: {
    display: "flex",
    marginBottom: "12px",
    // justifyContent: "space-around",
    marginLeft: "30px",
  },
  invoiceOrderTypeSelect: {
    display: "flex",
    // justifyContent: "space-around",
    marginLeft: "30px",
  },
  clientTitle: {
    color: "#147CFF",
    margin: 5,
  },
  clientDetail: {
    // color: "#147CFF",
    margin: 5,
  },
  topBar: {
    height: "50px",
    // backgroundColor: "#f8f8f8",
    padding: "0 0 0 1%",
    display: "flex",
    alignItems: "center",
    // marginTop: "6vh",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: "1.2em",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  postButton: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: "poppins",
    //fontWeight: "bold",
    fontSize: "1em",
    color: "#ffffff",
    textTransform: "inherit",
    // borderRadius: "5px",
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    textAlign: "center",
    marginBottom: "2%",
    // marginRight: "2%",
    border: "1px solid #3C72D9",
    height: "40px",
    marginTop: "20px",
    width: "160px",

    // border: "1px solid #3C72D9",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e7e7e7",
      color: "white",
    },
  },
  errorDaySelectField: {
    fontSize: "12px",
    color: "red",
    // marginTop: "0.25rem",

    display: "flex",
  },
  backButton: {
    border: "1px solid",
    padding: "5px",
    marginRight: "20px",
  },
  addClientButton: {
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    backgroundColor: "#f8f8f8",
    borderRadius: "20px",
    textTransform: "capitalize",
  },
  repeatTypeStyling: {
    marginLeft: "30px",
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
  },
  infoIconStyles: {
    color: "#147CFF",
  },
  warningIconStyles: {
    color: "#F6B217",
  },
  textAreaInput: {
    width: "95%",
    "& .MuiInputBase-input": {
      // textIndent: "10px",
      textAlign: "left",
    },
    marginTop: "15px",
    // width: "65vh",
    border: "none",
    paddingLeft: "3px",
    // border: "1px solid #BABABA",
  },
}));

const customStyles = {
  textAlign: "end",
  control: (base, state) => ({
    ...base,
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
};

const options = [
  {
    label: "Individual",
    value: "individual",
  },
  {
    label: "Batch",
    value: "batch",
  },
  {
    label: "Both",
    value: "both",
  },
];
// const label = { inputProps: { "aria-label": "Checkbox demo" } };

const occurence_type_options = ["Daily", "Recurring"];
const invoice_repeat_type_options = ["Weekly", "Date"];

const invoice_automation = {
  both: false,
  invoiceType: "individual",
  individual: {
    occurence_type: {
      repeats: "Daily", // Daily or Recurring
      repeat_type: "Weekly", // If Recurring => weekly or specific date
      rrule: {
        // if specific dates
        date1: "",
        date2: "",
      },
      day: "", // If Weekly
    },
    invoice_by_order_type: false,
    invoice_email_template: [
      {
        orderID: 0,
        email_to: "",
        cc: "",
        subject: "",
        body: "",
      },
    ],
  },
  batch: {
    occurence_type: {
      repeats: "Daily", // daily or recurring
      repeat_type: "Weekly", // If recurring => weekly or specific date
      rrule: {
        // if specific dates
        date1: "",
        date2: "",
      },
      day: "", // If Weekly
    },
    invoice_by_order_type: false,
    invoice_email_template: [
      {
        orderID: 0,
        email_to: "",
        cc: "",
        subject: "",
        body: "",
      },
    ],
  },
};

/**

A React functional component for rendering the client view of an application.
It provides functionality for displaying the details of a client
It is used to edit the invoice_automation form for the client, and editing the Fee line form.

*/
function ViewClient() {
  const classes = useStyle();
  const globalClasses = globalStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [dialog, setDialog] = useState(false);
  const [fee, setFee] = useState(false);
  const [invoiceType, setInvoiceType] = useState("individual"); // individual || batch \\ both
  const [checkedIsDaily, setCheckedIsDaily] = useState(true);
  const formRef = useRef();
  const [emailOrderTypes, setEmailOrderTypes] = useState(null);

  const { orderTypes } = useContext(AuthContext);
  // const [orderTypes, setOrderTypes] = useState(null);
  const [currentOrderType, setCurrentOrderType] = useState(null);

  const [initState, setInitState] = useState(initialState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // console.log(initState);

    if (initState.client_id == "") {
      getClient(id);
    } else {
      setLoading(false);
    }
  }, [initState]);

  // Function for retrieving a single client's information
  const getClient = (id) => {
    Endpoint.Clients.getClient(id)
      .then((res) => {
        setInitState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function which makes and API call to get Order Types.
  const createOrderTypeTemplates = () => {
    const tempList = [];
    orderTypes.map((orderType) => {
      tempList.push({
        orderID: orderType.orderID,
        email_to: "",
        cc: "",
        subject: "",
        body: "",
      });
    });
    setEmailOrderTypes(tempList);
  };

  useEffect(() => {
    // console.log(location.pathname.split("/").pop());
    if (location.pathname.split("/").pop() == "fee") {
      setFee(true);
    }
    createOrderTypeTemplates();
  }, []);

  const filterList = (list, keyValue) => {
    return list.filter((item) => item.orderID === keyValue);
  };
  const parseEmails = (value) => {
    return value.split(",").map((email) => email.trim());
  };

  const CustomInput = ({ field, form, ...props }) => {
    return <input {...field} {...props} />;
  };

  // Handle Submit Function for adding/updating a client's automation invoice form
  const handleSubmit = (v) => {
    let obj;
    if (v.invoice_automation.both) {
      obj = {
        attention: v.attention,
        city: v.city,
        client_id: v.client_id,
        client_name: v.client_name,
        end_date: v.end_date,
        fee_lines: v.fee_lines,
        start_date: v.start_date,
        state: v.state,
        street_address: v.street_address,
        tax_id: v.tax_id,
        zip_code: v.zip_code,
        invoice_automation: {
          both: true,
          individual: v.invoice_automation.individual,
          batch: v.invoice_automation.individual,
        },
      };
    } else {
      obj = v;
    }

    console.log(obj.invoice_automation);
    enqueueSnackbar("Client automation updated!", {
      variant: "success",
    });
    // console.log(obj);
    // Endpoint.Clients.addClient(obj)
    //   .then((res) => {
    //     enqueueSnackbar("Client added successfully.", {
    //       variant: "success",
    //     });
    //     // window.location.reload();
    //     setRefresh(true);
    //     SetView(!view);
    //   })
    //   .catch((err) => {
    //     enqueueSnackbar("Sorry something went wrong, please try again later.", {
    //       variant: "error",
    //     });
    //   });
  };

  // individual and batch schema update, the schema is same for them and hence defined here.
  const individualAndBatchSchema = Yup.object().shape({
    occurence_type: Yup.object().shape({
      repeats: Yup.string().required(),
      repeat_type: Yup.string().when("repeats", {
        is: (repeats) => repeats === "Recurring",
        then: Yup.string().required(),
        otherwise: Yup.string().optional(),
      }),
      rrule: Yup.object()
        .required("1st Date is required")
        .when(["repeats", "repeat_type"], {
          is: (repeats, repeat_type) =>
            repeats === "Recurring" && repeat_type === "Date",
          then: Yup.object().shape({
            date1: Yup.string().required("1st Date is required"),
            date2: Yup.string().optional(),
          }),
          otherwise: Yup.object().optional(),
        }),

      day: Yup.string().when(["repeats", "repeat_type"], {
        is: (repeats, repeat_type) =>
          repeats === "Recurring" && repeat_type === "Weekly",
        then: Yup.string().required("Day is required"),
        otherwise: Yup.string().optional(),
      }),
    }),
    invoice_by_order_type: Yup.boolean().optional(),
    invoice_email_template: Yup.array().of(
      Yup.object({
        orderID: Yup.number().required(),
        email_to: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        CC: Yup.string().optional(),
        subject: Yup.string().optional(),
        body: Yup.string().optional(),
      })
    ),
  });

  // validation schema for invoice automation
  const validateSchema = object().shape({
    invoice_automation: Yup.object().shape({
      both: Yup.boolean().optional(),
      invoiceType: Yup.string().required(),
      individual: Yup.object().when("invoiceType", {
        is: (invoiceType) =>
          invoiceType === "individual" || invoiceType === "both",
        then: individualAndBatchSchema,
      }),
      batch: Yup.object().when("invoiceType", {
        is: (invoiceType) => invoiceType === "batch",
        then: individualAndBatchSchema,
      }),
    }),
  });

  const handleChange = (e) => {
    // console.log(e);
    setCheckedIsDaily(!checkedIsDaily);
  };

  const updateField = (values, setFieldValue) => {
    if (invoiceType == "both") {
      setFieldValue(
        `invoice_automation.individual.invoice_by_order_type`,
        !values.invoice_automation.individual.invoice_by_order_type
      );
    } else {
      setFieldValue(
        `invoice_automation.${invoiceType}.invoice_by_order_type`,
        !values.invoice_automation[invoiceType].invoice_by_order_type
      );
    }
  };

  // const handleEditorChange = (editorState, name) => {
  //   console.log(editorState);
  //   setFieldValue(name, editorState);
  //   // helpers.setValue(editorState);
  // };
  if (loading) return <Loading pageLoading={true} left={"60%"} />;
  return (
    <div className={`${globalClasses.root}`}>
      <div className={`${globalClasses.flexColumn}`}>
        <div className={classes.container}>
          <div>
            {!fee ? (
              <>
                <div className={classes.topBar}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      className={classes.backButton}
                      onClick={() => {
                        navigate(CLIENT.path);
                      }}
                    >
                      <ArrowBackIosNewIcon fontSize="small" />
                    </IconButton>
                    <span className={classes.heading}>{"Client Profile"}</span>
                  </span>
                  {initState.client_id === "" ? (
                    <></>
                  ) : (
                    <Button
                      className={classes.addClientButton}
                      style={
                        fee
                          ? { backgroundColor: "#4e4e4e", color: "white" }
                          : null
                      }
                      onClick={(e) => {
                        setFee(!fee);
                        navigate(
                          `${CLIENT.childPathView}/${id}${CLIENT.childPathFee}`
                        );
                      }}
                    >
                      Fee
                    </Button>
                  )}
                </div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{ ...initState, invoice_automation }}
                  validationSchema={validateSchema}
                  validateOnBlur={true}
                  innerRef={formRef}
                  validateOnChange
                  onSubmit={(e) => {
                    // console.log(e);
                    handleSubmit(e);
                  }}
                  render={({
                    isSubmitting,
                    dirty,
                    touched,
                    values,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form>
                      {/* {console.log(values)} */}
                      <div className={classes.caseContainer}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={4}
                            className={classes.clientGridBorder}
                          >
                            <div className={classes.clientGridItem}>
                              <p className={classes.clientTitle}>Client Name</p>
                              <p className={classes.clientDetail}>
                                {initState.client_name}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className={classes.clientGridBorder}
                          >
                            <div className={classes.clientGridItem}>
                              <p className={classes.clientTitle}>Attn.</p>
                              <p className={classes.clientDetail}>
                                {initState.attention}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className={classes.clientGridBorder}
                          >
                            <div className={classes.clientGridItem}>
                              <p className={classes.clientTitle}>Start Date</p>
                              <p className={classes.clientDetail}>
                                {initState.start_date}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className={classes.clientGridBorder}
                          >
                            <div className={classes.clientGridItem}>
                              <p className={classes.clientTitle}>Tax ID</p>
                              <p className={classes.clientDetail}>
                                {initState.tax_id}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className={classes.clientGridBorder}
                          >
                            <div className={classes.clientGridItem}>
                              <p className={classes.clientTitle}>Address</p>
                              <p className={classes.clientDetail}>
                                {initState.street_address} {initState.city}{" "}
                                {initState.state} {initState.zip_code}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className={classes.clientGridBorder}
                          >
                            <div className={classes.clientGridItem}>
                              <p className={classes.clientTitle}>End Date</p>
                              <p className={classes.clientDetail}>
                                {initState.end_date}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className={classes.clientGridBorder}
                          >
                            <div className={classes.clientGridItem}>
                              <p className={classes.clientTitle}>
                                Invoice Type
                              </p>
                              <Select
                                value={
                                  options
                                    ? options.find(
                                        (option) => option.value === invoiceType
                                      )
                                    : ""
                                }
                                onChange={(e) => {
                                  // console.log(e.value);
                                  setInvoiceType(e.value);
                                  setFieldValue(
                                    `invoice_automation.invoiceType`,
                                    e.value
                                  );
                                  if (e.value == "both") {
                                    setFieldValue(
                                      `invoice_automation.both`,
                                      true
                                    );
                                  } else {
                                    setFieldValue(
                                      `invoice_automation.both`,
                                      false
                                    );
                                  }
                                }}
                                options={options}
                                styles={customStyles}
                                style={{ width: "200px" }}
                              />
                            </div>
                            <div className={classes.invoiceContainer}>
                              <Field
                                name={`invoice_automation.${
                                  invoiceType == "both"
                                    ? "individual"
                                    : invoiceType
                                }.occurence_type.repeats`}
                                options={occurence_type_options}
                                component={FormikRadioGroup}
                              />
                            </div>
                            <Divider />
                            {values.invoice_automation[
                              invoiceType == "both" ? "individual" : invoiceType
                            ].occurence_type.repeats === "Recurring" ? (
                              <>
                                <div className={classes.invoiceContainer}>
                                  <Field
                                    name={`invoice_automation.${
                                      invoiceType == "both"
                                        ? "individual"
                                        : invoiceType
                                    }.occurence_type.repeat_type`}
                                    options={invoice_repeat_type_options}
                                    component={FormikRadioGroup}
                                  />
                                </div>
                                {values.invoice_automation[
                                  invoiceType == "both"
                                    ? "individual"
                                    : invoiceType
                                ].occurence_type.repeat_type == "Weekly" ? (
                                  <div className={classes.repeatTypeStyling}>
                                    <div style={{ display: "flex" }}>
                                      <Typography>
                                        Choose day of the week
                                      </Typography>
                                      <Tooltip
                                        style={{ marginLeft: "10px" }}
                                        title="Choose a day of the week from the dropdown below"
                                      >
                                        <InfoIcon
                                          fontSize="small"
                                          className={classes.infoIconStyles}
                                        />
                                      </Tooltip>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <Select
                                        value={
                                          options
                                            ? options.find(
                                                (option) =>
                                                  option.value ===
                                                  values.invoice_automation[
                                                    invoiceType == "both"
                                                      ? "individual"
                                                      : invoiceType
                                                  ].occurence_type.day
                                              )
                                            : ""
                                        }
                                        onChange={(e) => {
                                          // console.log(e.value);
                                          setFieldValue(
                                            `invoice_automation.${
                                              invoiceType == "both"
                                                ? "individual"
                                                : invoiceType
                                            }.occurence_type.day`,
                                            e.value
                                          );
                                        }}
                                        options={daysOfTheWeek}
                                        styles={customStyles}
                                      />
                                    </div>
                                    <ErrorMessage
                                      name={`invoice_automation.${
                                        invoiceType == "both"
                                          ? "individual"
                                          : invoiceType
                                      }.occurence_type.day`}
                                      component={FormError}
                                      className={classes.errorDaySelectField}
                                    />
                                  </div>
                                ) : (
                                  <div className={classes.repeatTypeStyling}>
                                    <div>
                                      <div style={{ display: "flex" }}>
                                        <Typography>
                                          Choose dates for recurrence
                                        </Typography>
                                        <Tooltip
                                          style={{ marginLeft: "10px" }}
                                          title="Choose atleast one recurring date to send the invoice"
                                        >
                                          <InfoIcon
                                            fontSize="small"
                                            className={classes.infoIconStyles}
                                          />
                                        </Tooltip>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            width: "150px",
                                          }}
                                        >
                                          <Select
                                            value={
                                              options
                                                ? options.find(
                                                    (option) =>
                                                      option.value ===
                                                      values.invoice_automation[
                                                        invoiceType == "both"
                                                          ? "individual"
                                                          : invoiceType
                                                      ].occurence_type.rrule
                                                        .date1
                                                  )
                                                : ""
                                            }
                                            onChange={(e) => {
                                              // console.log(e.value);
                                              setFieldValue(
                                                `invoice_automation.${
                                                  invoiceType == "both"
                                                    ? "individual"
                                                    : invoiceType
                                                }.occurence_type.rrule.date1`,
                                                e.value
                                              );
                                            }}
                                            options={getDates()}
                                            styles={customStyles}
                                          />
                                          <ErrorMessage
                                            name={`invoice_automation.${
                                              invoiceType == "both"
                                                ? "individual"
                                                : invoiceType
                                            }.occurence_type.rrule.date1`}
                                            component={FormError}
                                            className={
                                              classes.errorDaySelectField
                                            }
                                          />
                                        </span>

                                        <span
                                          style={{
                                            marginLeft: "30px",
                                            width: "150px",
                                          }}
                                        >
                                          <Select
                                            value={
                                              options
                                                ? options.find(
                                                    (option) =>
                                                      option.value ===
                                                      values.invoice_automation[
                                                        invoiceType == "both"
                                                          ? "individual"
                                                          : invoiceType
                                                      ].occurence_type.rrule
                                                        .date2
                                                  )
                                                : ""
                                            }
                                            onChange={(e) => {
                                              // console.log(e.value);
                                              setFieldValue(
                                                `invoice_automation.${
                                                  invoiceType == "both"
                                                    ? "individual"
                                                    : invoiceType
                                                }.occurence_type.rrule.date2`,
                                                e.value
                                              );
                                            }}
                                            options={getDates()}
                                            styles={customStyles}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            style={{
                              height: "450px",
                              backgroundColor: "#F6F6F6",
                              boxShadow: "inset 0 0 4 0 #000000",
                            }}
                            className={classes.clientGridBorder}
                          >
                            <FieldArray
                              name={`invoice_automation[${
                                invoiceType == "both"
                                  ? "individual"
                                  : invoiceType
                              }].invoice_email_template`}
                              render={(arrayHelpers) => (
                                <>
                                  <div className={classes.clientGridItem}>
                                    <div
                                      className={classes.invoiceOrderTypeSelect}
                                      style={{ alignItems: "center" }}
                                    >
                                      <FormControlLabel
                                        // style={{ marginLeft: "30px" }}
                                        control={<Checkbox color="default" />}
                                        label="Invoice by Order Type"
                                        checked={
                                          values.invoice_automation[
                                            invoiceType == "both"
                                              ? "individual"
                                              : invoiceType
                                          ].invoice_by_order_type
                                        }
                                        onChange={() => {
                                          if (
                                            !values.invoice_automation[
                                              invoiceType == "both"
                                                ? "individual"
                                                : invoiceType
                                            ].invoice_by_order_type
                                          ) {
                                            arrayHelpers.remove(0);

                                            setFieldValue(
                                              `invoice_automation[${
                                                invoiceType == "both"
                                                  ? "individual"
                                                  : invoiceType
                                              }].invoice_email_template`,
                                              emailOrderTypes
                                            );
                                          } else {
                                            setFieldValue(
                                              `invoice_automation[${
                                                invoiceType == "both"
                                                  ? "individual"
                                                  : invoiceType
                                              }].invoice_email_template`,
                                              [
                                                {
                                                  orderID: 0,
                                                  email_to: "",
                                                  cc: "",
                                                  subject: "",
                                                  body: "",
                                                },
                                              ]
                                            );
                                            // push();
                                          }
                                          updateField(values, setFieldValue);
                                        }}
                                      />
                                      {values.invoice_automation[
                                        invoiceType == "both"
                                          ? "individual"
                                          : invoiceType
                                      ].invoice_by_order_type ? (
                                        <div
                                          className={
                                            classes.invoiceOrderTypeSelect
                                          }
                                          style={{ alignItems: "center" }}
                                        >
                                          <Select
                                            // value={{ label: values.order_name }}
                                            value={
                                              orderTypes
                                                ? orderTypes.find(
                                                    (option) =>
                                                      option?.orderID ===
                                                      currentOrderType
                                                  )
                                                : ""
                                            }
                                            onChange={(option) =>
                                              setCurrentOrderType(
                                                option.orderID
                                              )
                                            }
                                            getOptionLabel={(option) =>
                                              option.ordertype
                                            }
                                            getOptionValue={(option) =>
                                              option.orderID
                                            }
                                            options={orderTypes}
                                            styles={customStyles}
                                          />
                                          <Tooltip
                                            style={{ marginLeft: "10px" }}
                                            title="Please make sure you fill all templates"
                                          >
                                            <WarningIcon
                                              fontSize="small"
                                              className={
                                                classes.warningIconStyles
                                              }
                                            />
                                          </Tooltip>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  {values.invoice_automation[
                                    invoiceType == "both"
                                      ? "individual"
                                      : invoiceType
                                  ].invoice_by_order_type &&
                                  currentOrderType ? (
                                    <>
                                      {values.invoice_automation[
                                        invoiceType == "both"
                                          ? "individual"
                                          : invoiceType
                                      ].invoice_email_template.map(
                                        (emailTemplate, index) => {
                                          return (
                                            <div
                                              key={`${emailTemplate.orderID}-index`}
                                            >
                                              {/* {console.log(index)} */}
                                              {/* {console.log(
                                        emailTemplate,
                                        currentOrderType,
                                        index,
                                        emailTemplate.orderID ==
                                          currentOrderType
                                      )} */}

                                              {emailTemplate.orderID ==
                                              currentOrderType ? (
                                                <>
                                                  <div
                                                    className={
                                                      classes.invoiceEmailItem
                                                    }
                                                  >
                                                    <InvoiceField
                                                      label={"Email to: "}
                                                      placeHolder={
                                                        "Add Email Address"
                                                      }
                                                      type={"text"}
                                                      isEmail={true}
                                                      name={`invoice_automation.${
                                                        invoiceType == "both"
                                                          ? "individual"
                                                          : invoiceType
                                                      }.invoice_email_template[${index}].email_to`}
                                                    />
                                                  </div>
                                                  <div
                                                    className={
                                                      classes.invoiceEmailItem
                                                    }
                                                  >
                                                    <InvoiceField
                                                      label={"CC: "}
                                                      placeHolder={
                                                        "Add Email Address and seperated by ,"
                                                      }
                                                      type={"text"}
                                                      isEmail={true}
                                                      name={`invoice_automation.${
                                                        invoiceType == "both"
                                                          ? "individual"
                                                          : invoiceType
                                                      }.invoice_email_template[${index}].cc`}
                                                    />
                                                  </div>
                                                  <div
                                                    className={
                                                      classes.invoiceEmailItem
                                                    }
                                                  >
                                                    <InvoiceField
                                                      label={"Subject: "}
                                                      placeHolder={"Subject"}
                                                      type={"text"}
                                                      isEmail={true}
                                                      name={`invoice_automation.${
                                                        invoiceType == "both"
                                                          ? "individual"
                                                          : invoiceType
                                                      }.invoice_email_template[${index}].subject`}
                                                    />
                                                  </div>
                                                  <div
                                                    className={
                                                      classes.invoiceEmailItem
                                                    }
                                                  >
                                                    <TextareaAutosize
                                                      maxRows={10}
                                                      minRows={10}
                                                      aria-label="maximum height"
                                                      placeholder="Message body"
                                                      defaultValue=""
                                                      onChange={(e) => {
                                                        // console.log(e);
                                                        setFieldValue(
                                                          `invoice_automation.${
                                                            invoiceType ==
                                                            "both"
                                                              ? "individual"
                                                              : invoiceType
                                                          }.invoice_email_template[${index}].body`,
                                                          e.target.value
                                                        );
                                                      }}
                                                      className={
                                                        classes.textAreaInput
                                                      }
                                                    />
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  {/* {console.log(
                                            `Skipping field with index ${index}: key = ${emailTemplate.orderID}, value = ${emailTemplate.email_to}`
                                          )} */}
                                                </>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <div className={classes.invoiceEmailItem}>
                                        <InvoiceField
                                          label={"Email to: "}
                                          placeHolder={"Add Email Address"}
                                          type={"text"}
                                          isEmail={true}
                                          name={`invoice_automation.${
                                            invoiceType == "both"
                                              ? "individual"
                                              : invoiceType
                                          }.invoice_email_template[${0}].email_to`}
                                        />
                                      </div>
                                      <div className={classes.invoiceEmailItem}>
                                        <InvoiceField
                                          label={"CC: "}
                                          placeHolder={
                                            "Add Email Address seperated by ,"
                                          }
                                          type={"text"}
                                          isEmail={true}
                                          name={`invoice_automation.${
                                            invoiceType == "both"
                                              ? "individual"
                                              : invoiceType
                                          }.invoice_email_template[${0}].cc`}
                                        />
                                      </div>
                                      <div className={classes.invoiceEmailItem}>
                                        <InvoiceField
                                          label={"Subject: "}
                                          placeHolder={"Subject"}
                                          type={"text"}
                                          isEmail={true}
                                          name={`invoice_automation.${
                                            invoiceType == "both"
                                              ? "individual"
                                              : invoiceType
                                          }.invoice_email_template[${0}].subject`}
                                        />
                                      </div>
                                      <div className={classes.invoiceEmailItem}>
                                        <TextareaAutosize
                                          maxRows={10}
                                          minRows={10}
                                          aria-label="maximum height"
                                          placeholder="Message body"
                                          defaultValue=""
                                          onChange={(e) => {
                                            // console.log(e);
                                            setFieldValue(
                                              `invoice_automation.${
                                                invoiceType == "both"
                                                  ? "individual"
                                                  : invoiceType
                                              }.invoice_email_template[${0}].body`,
                                              e.target.value
                                            );
                                          }}
                                          className={classes.textAreaInput}
                                        />

                                        {/* {console.log(
                                    values.invoice_automation[
                                      invoiceType
                                    ].invoice_email_template[0].body
                                      .getCurrentContent()
                                      .getPlainText()
                                  )}
                                  <Editor
                                    style={{
                                      backgroundColor: "#fff",
                                      border: "1px solid",
                                      height: "100px",
                                      width: "100px",
                                    }}
                                    placeholder="Make Something Great."
                                    editorState={
                                      values.invoice_automation[invoiceType]
                                        .invoice_email_template[0].body
                                    }
                                    onChange={(editorState) => {
                                      setFieldValue(
                                        `invoice_automation.${invoiceType}.invoice_email_template[${0}].body`,
                                        editorState
                                      );
                                    }}
                                  /> */}
                                        {/* <DraftField
                                    value={
                                      values.invoice_automation[invoiceType]
                                        .invoice_email_template[0].body
                                    }
                                    setFieldValue={setFieldValue}
                                    name={`invoice_automation.${invoiceType}.invoice_email_template[${0}].body`}
                                  /> */}
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <Button
                        className={classes.postButton}
                        style={{ marginTop: "40px", float: "right" }}
                        type={"submit"}
                      >
                        Save Changes
                      </Button>

                      {/* <ContactsDialog
                dialog={dialog}
                handleCloseDialog={(e) => {
                  setDialog(false);
                }}
                values={values}
                setFieldValue={setFieldValue}
              /> */}
                    </Form>
                  )}
                />
              </>
            ) : (
              <FeeTab
                setFee={setFee}
                initState={initState}
                getClient={getClient}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewClient;
