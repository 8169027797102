import { makeStyles, Grid, Button } from "@material-ui/core";

import FileField from "./FileField";
import AddressField from "./AddressField";

const useStyle = makeStyles((theme) => ({
  caseContainer: {
    // width: "928px",
    width: "98%",
    // paddingLeft: "1%",
    // marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },

  clientGridBorder: {
    border: "1px solid #EAEAEA",
  },
  clientGridItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  clientTitle: {
    color: "#147CFF",
    margin: 5,
  },
  clientDetail: {
    // color: "#147CFF",
    margin: "5px 0px 0px 0px",
  },
  postButton: {
    backgroundColor: theme.palette.primary.main,
    // fontFamily: "poppins",
    //fontWeight: "bold",
    fontSize: ".8em",
    color: "#ffffff",
    textTransform: "inherit",
    // borderRadius: "5px",
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    textAlign: "center",
    marginRight: "2%",
    border: "1px solid #3C72D9",

    width: "110px",
    // border: "1px solid #3C72D9",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e7e7e7",
      color: "white",
    },
  },
}));

const customStyles = {
  textAlign: "end",
  control: (base, state) => ({
    ...base,
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
};

const fileDetails = [
  {
    title: "Client Name",
    key: "client_name",
  },
  {
    title: "Client Loan #",
    key: "client_loan_no",
  },
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Address",
    key: "",
  },
  {
    title: "Master Servicer Name",
    key: "master_servicer_name",
  },
  {
    title: "Master Servicer Loan #",
    key: "master_servicer_loan_no",
  },
  {
    title: "Servicer Name",
    key: "servicer_name",
  },
  {
    title: "Servicer Loan #",
    key: "servicer_loan_no",
  },
  {
    title: "Investor Name",
    key: "investor_name",
  },
  {
    title: "Investor Loan #",
    key: "investor_loan_no",
  },
  {
    title: "Loan Category",
    key: "loan_category",
  },
  {
    title: "Loan Status",
    key: "loan_status",
  },
];

/**

A React functional component for rendering the File Detail Component


*/
function FileDetail({ values, setFieldValue }) {
  const classes = useStyle();

  return (
    <>
      <div className={classes.caseContainer}>
        <Grid container spacing={2}>
          {fileDetails.map((fileDetail) => {
            if (fileDetail.title == "Address") {
              return (
                <Grid item xs={4} className={classes.clientGridBorder}>
                  <div className={classes.clientGridItem}>
                    <p className={classes.clientTitle}>Address</p>
                    <p
                      className={classes.clientDetail}
                      style={{ display: "flex" }}
                    >
                      <AddressField
                        placeHolder={"Street"}
                        type={"text"}
                        name={`street_address`}
                        address_type={"street"}
                      />
                      <AddressField
                        placeHolder={"City"}
                        type={"text"}
                        name={`city`}
                        address_type={"city"}
                      />
                      <AddressField
                        placeHolder={"State"}
                        type={"select"}
                        values={values}
                        value={"value"}
                        label={"label"}
                        name={`state`}
                        address_type={"state"}
                        setFieldValue={setFieldValue}
                      />
                      <AddressField
                        placeHolder={"Zip"}
                        type={"text"}
                        name={`zip_code`}
                        address_type={"zip_code"}
                      />
                      {/* {values.street_address} {values.city} {values.state}{" "}
                      {values.zip_code} */}
                    </p>
                  </div>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={4} className={classes.clientGridBorder}>
                  <div className={classes.clientGridItem}>
                    <p className={classes.clientTitle}>{fileDetail.title}</p>
                    <FileField
                      placeHolder={fileDetail.title}
                      type={"text"}
                      name={`${[fileDetail.key]}`}
                    />
                    {/* <p className={classes.clientDetail}>
                      {values[fileDetail.key] ? values[fileDetail.key] : "N/A"}
                    </p> */}
                  </div>
                </Grid>
              );
            }
          })}
        </Grid>
      </div>
    </>
  );
}

export default FileDetail;
