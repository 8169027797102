import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, InputBase, LinearProgress } from "@material-ui/core";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import Cookies from "js-cookie";
import Endpoint from "../endpoint";
import globalStyles from "../helpers/GlobalStyles";
import { alertError } from "../helpers";
import clsx from "clsx";
import TermsAndConditions from "../dialogs/TermsAndConditions";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthWrapper";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(
  () => ({
    loginPage: {
      background: "#147CFF",
      justifyContent: "center",
    },
    form: {
      maxWidth: "350px",
      minWidth: "350px",
      maxHeight: "350px",
      minHeight: "350px",
      background: "white",
      padding: "20px",
      margin: "10px",
      borderRadius: "4vh",
      boxShadow: "3px 3px 5px",
    },
    logo: {
      marginTop: "25px",
      marginBottom: "25px",
      // width: '100%',
      height: "60px",
    },
    field: {
      width: "80%",
      margin: "auto",
      marginTop: "20px",
      "& label": {
        fontSize: "18px",
        color: "#147CFF",
      },
    },
    error: {
      color: "#f3c2c2",
      // margin: "3px",
      marginLeft: "10px",
      fontSize: "11px",
      fontWeight: "bolder",
      position: "absolute",
      marginTop: "8vh",
      // width: "100%",
    },
  }),
  { index: 1 }
);

/**
 *
 * React Page for LOGIN.
 */
function Login() {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [termsDialog, setTermsDialog] = useState(false);
  const [id, setId] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, login, user, updateUserTandC } =
    useContext(AuthContext);

  const openTermsDialog = () => {
    setTermsDialog(true);
  };
  const closeTermsDialog = () => {
    setTermsDialog(false);
  };

  const handleSubmit = (values) => {
    async function loginFunc() {
      try {
        const res = await login(values);
        enqueueSnackbar("Login successful", {
          variant: "success",
        });
        console.log(user);
      } catch (error) {
        console.log("error", error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      }
    }
    loginFunc(values);
  };

  useEffect(() => {
    console.log(user);
    if (user != false && !user.terms_and_conditions) {
      openTermsDialog();
    }
  }, [user != false]);

  const acceptTerms = () => {
    Cookies.set("_id", id);
    updateUserTandC();
    navigate("/");
    closeTermsDialog();
  };

  return (
    <div
      className={`${classes.loginPage} ${globalClasses.root}  ${globalClasses.flexColumn}`}
    >
      <TermsAndConditions
        isLoading={isLoading}
        acceptTerms={acceptTerms}
        handleClose={closeTermsDialog}
        open={termsDialog}
      />
      <Formik
        enableReinitialize
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={object().shape({
          username: string().required("Required.").min(6).max(20),
          password: string().required("Required.").min(6).max(20),
        })}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ isSubmitting, handleChange, errors }) => (
          <Form className={`${classes.form} ${globalClasses.flexColumn}`}>
            <img className={classes.logo} src="/logo.png" alt="logo" />
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="username">username*</label>
              <InputBase
                className={globalClasses.input}
                name="username"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.username}
              />
              {errors.username && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    classes.error
                  )}`}
                >
                  {errors.username}
                </div>
              )}
            </div>
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="password">password*</label>
              <InputBase
                className={globalClasses.input}
                name="password"
                type="password"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.password}
              />
              {errors.password && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    classes.error
                  )}`}
                >
                  {errors.password}
                </div>
              )}
            </div>
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={`${globalClasses.button}`}
              >
                Login
                {isSubmitting && (
                  <LinearProgress
                    color="primary"
                    className={globalClasses.linearProgress}
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default Login;
