import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import "./UploadBatchTable.css";

import clsx from "clsx";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";

import Select from "react-select";
import { Button, Tooltip } from "@material-ui/core";

import globalStyles from "../../../helpers/GlobalStyles";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportIconProblem from "@mui/icons-material/ReportProblem";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import FMUInputField from "../../../components/shared/FormIkMUFormComponent/FMUInputField";
import UploadBatchField from "../../../components/shared/UploadBatchField";
import { states } from "../../../helpers/states";

import ZillowCheck from "../../../dialogs/ZillowCheck";
import Endpoint from "../../../endpoint";
import { useSnackbar } from "notistack";
import Loading from "../../Loading";
import { getHeaderLabel } from "../../../helpers/helper";

/**

A React functional component for rendering the Upload Batch page

*/
function UploadBatchTable({
  classes,
  customStyles,
  data,
  headers,
  allHeaders,
  handleNext,
  setupDataForZillowResponse,
  checkingZillow,
  setCheckingZillow,
  setSkippingZillow,
  connectionId,
  setData,
}) {
  const globalClasses = globalStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [showZillowCheckDialog, setShowZillowCheckDialog] = useState(false);
  const [type, setType] = useState(null);

  const handleCloseZillowCheckDialog = (type) => {
    setShowZillowCheckDialog(false);
    if (type) {
      handleNext(type);
    }
  };
  const handleSubmit = (values, type) => {
    setCheckingZillow(true);
    console.log("Form data submitted:", values);
    console.log(type, "1");
    handleCloseZillowCheckDialog();
    // handleNext(type);
    Endpoint.Clients.batchUploadZillowCheck({
      obj: values,
      connectionId: connectionId,
    })
      .then((res) => {
        // setCheckingZillow(false);
        console.log(res);
        enqueueSnackbar("Data retrieved successfully", {
          variant: "success",
        });
        // setupDataForZillowResponse(res.data);
      })
      .catch((err) => {
        console.log(err);
        // enqueueSnackbar("Something went wrong, please try again", {
        //   variant: "error",
        // });
      });
  };

  const handleSkipZillowSubmit = async (values, type) => {
    try {
      setSkippingZillow(true);
      handleCloseZillowCheckDialog(type);
      await Endpoint.Clients.batchUploadZillowCheck({
        skip_zillow: true,
        obj: values,
        connectionId: connectionId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRow = (values, arrayHelpers, index) => {
    const currentRowData = { ...values.data[index] };

    const generateNextNumber = (key, baseValue) => {
      return (
        values.data
          .filter((row) => row[key].startsWith(baseValue))
          .reduce((max, row) => {
            const suffix = parseInt(row[key].split("-")[1] || 0, 10);
            return Math.max(max, suffix);
          }, 0) + 1
      );
    };

    const baseOrderNumber = currentRowData.order_number.split("-")[0];
    const baseWorkNumber = currentRowData.work_number.split("-")[0];

    const newOrderSuffix = generateNextNumber("order_number", baseOrderNumber);
    const newWorkSuffix = generateNextNumber("work_number", baseWorkNumber);

    currentRowData.order_number = `${baseOrderNumber}-${newOrderSuffix}`;
    currentRowData.work_number = `${baseWorkNumber}-${newWorkSuffix}`;

    arrayHelpers.insert(index + 1, currentRowData);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ data }}
        // validationSchema={validationSchema}
        onSubmit={(values) => {}}
      >
        {({ values, setFieldValue }) => {
          console.log(values);
          return (
            <Form>
              <FieldArray
                name="data" // Use data directly
                render={(arrayHelpers) => (
                  <>
                    <div style={{ width: "98%" }}>
                      <table className={classes.clientTable}>
                        <thead className={globalClasses.tableHead}>
                          <tr className={globalClasses.clientthtr}>
                            <>
                              <th className={classes.tableTh} style={{ width: "40px" }}>
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    values.data.forEach((_, index) => {
                                      setFieldValue(`data.${index}.skip_status`, !isChecked);
                                    });
                                  }}
                                />
                              </th>
                              {headers.map((header) => {
                                if (header === "skip_status") return null;
                                return (
                                  <th className={classes.tableTh} key={header}>
                                    {getHeaderLabel(header)}
                                  </th>
                                );
                              })}
                              {/* <th
                              className={classes.tableTh}
                              style={{ paddingRight: "30px" }}
                            >
                              Options
                            </th> */}
                            </>
                          </tr>
                        </thead>
                        <tbody>
                          {values.data.map((row, index) => (
                            <tr
                              style={{
                                fontSize: "1em",
                                color: "#4e4e4e",
                                position: "relative",
                              }}
                              key={index}
                            >
                              <td className={classes.tableTd} style={{ textAlign: "center" }}>
                                <div style={{ textAlign: "center" }}>
                                  {values.data[index]["skip_status"] ? (
                                    <Tooltip title="Skip Address">
                                      <CheckBoxOutlineBlankIcon
                                        className={classes.tableIconsUnskip}
                                        onClick={() =>
                                          setFieldValue(
                                            `data.${index}.${"skip_status"}`,
                                            !values.data[index]["skip_status"],
                                            false
                                          )
                                        }
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Unskip Address">
                                      <CheckBoxIcon
                                        className={classes.tableIconsSkip}
                                        onClick={() =>
                                          setFieldValue(
                                            `data.${index}.${"skip_status"}`,
                                            !values.data[index]["skip_status"],
                                            false
                                          )
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              </td>
                              {headers.map((header) => {
                                if (header === "skip_status") return null;
                                return (
                                  <td className={classes.tableTd} key={header}>
                                    {header == "state" || header == "new_state" ? (
                                      <div className={classes.fieldContainer}>
                                        <div className={classes.parentChild}>
                                          <div className={classes.rowContainer}>
                                            <Select
                                              value={{
                                                label: values.data[index][header],
                                              }}
                                              onChange={(e) => {
                                                setFieldValue(`data.${index}.${header}`, e.value, false);
                                              }}
                                              options={states}
                                              styles={customStyles}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className={classes.fieldContainer}>
                                        <UploadBatchField
                                          type={"text"}
                                          name={`data.${index}.${header}`}
                                          setFieldValue={setFieldValue}
                                          value={values.data[index][header]}
                                        />
                                        <ErrorMessage name={`data.${index}.${header}`} />
                                      </div>
                                    )}
                                  </td>
                                );
                              })}
                              <td className={classes.tableTd} style={{}}>
                                <div style={{ textAlign: "center" }}>
                                  {values.data[index]["notes"] && (
                                    <Tooltip title="Missing Units">
                                      <ReportIconProblem className={classes.tableIconsAlert} />
                                    </Tooltip>
                                  )}
                                </div>
                              </td>

                              <td
                                style={{
                                  position: "absolute",
                                  right: "-50px",
                                  top: "6px",
                                }}
                              >
                                <Tooltip title="Add Row Below">
                                  <Button onClick={() => handleAddRow(values, arrayHelpers, index)}>
                                    <AddIcon />
                                  </Button>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      className={globalClasses.flexRow}
                      style={{ alignContent: "center", alignItems: "center" }}
                    >
                      <Tooltip title="Check Zillow">
                        <Button
                          className={`${classes.actionButton} `}
                          style={{ marginLeft: "1%", marginRight: "1%" }}
                          onClick={() => {
                            setType(1);
                            setShowZillowCheckDialog(true);
                          }}
                          disabled={checkingZillow}
                        >
                          {checkingZillow ? "Checking Zillow..." : "Check Zillow"}
                        </Button>
                      </Tooltip>
                      {checkingZillow ? (
                        <>
                          <Loading />
                        </>
                      ) : (
                        <Tooltip title="Skip Zillow">
                          <Button
                            className={`${classes.actionButton} `}
                            style={{}}
                            onClick={() => {
                              setType(2);
                              setShowZillowCheckDialog(true);
                            }}
                            disabled={checkingZillow}
                          >
                            Skip Zillow
                          </Button>
                        </Tooltip>
                      )}
                    </div>
                    {type == 1 && (
                      <ZillowCheck
                        showUploadBatchDialog={showZillowCheckDialog}
                        setShowUploadBatchDialog={setShowZillowCheckDialog}
                        handleSubmit={handleSubmit}
                        handleCloseUploadBatchDialog={handleCloseZillowCheckDialog}
                        msg={"Are you sure you want to check Zillow?"}
                        type={1}
                        values={values}
                      />
                    )}
                    {type == 2 && (
                      <ZillowCheck
                        showUploadBatchDialog={showZillowCheckDialog}
                        setShowUploadBatchDialog={setShowZillowCheckDialog}
                        handleCloseUploadBatchDialog={handleCloseZillowCheckDialog}
                        handleSubmit={handleSkipZillowSubmit}
                        msg={"Are you sure you want to skip the Zillow check?"}
                        type={2}
                        values={values}
                      />
                    )}
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default UploadBatchTable;
