import "./UploadBatchTable.css";
import { FieldArray, Form, Formik } from "formik";
import { Button, Tooltip } from "@material-ui/core";
import globalStyles from "../../../helpers/GlobalStyles";
import { getHeaderLabel } from "../../../helpers/helper";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

/**

A React functional component for rendering the Upload Batch page

*/
function OutputTable({ classes, headers, data, batchNo, selectedClient }) {
  const globalClasses = globalStyles();

  const downloadExcel = (jsonData) => {
    // Convert JSON data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a binary string (Excel file)
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Use FileSaver to save the file
    const fileName = `${selectedClient}_${batchNo}.xlsx`;
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, fileName);
  };

  return (
    <>
      <div className={globalClasses.flexRow} style={{ alignContent: "center", marginBottom: 20 }}>
        <Tooltip title="DOWNLOAD CSV">
          <Button
            className={`${classes.actionButton} `}
            onClick={() => {
              downloadExcel(data);
            }}
          >
            DOWNLOAD CSV
          </Button>
        </Tooltip>
      </div>
      <Formik enableReinitialize initialValues={{ data }} onSubmit={(values) => {}}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <FieldArray
                name="data"
                render={(arrayHelpers) => (
                  <>
                    <div style={{}}>
                      <table className={classes.clientTable}>
                        <thead className={globalClasses.tableHead}>
                          <tr className={globalClasses.clientthtr}>
                            <>
                              {headers.map((header) => {
                                return (
                                  <th className={classes.tableTh} key={header} style={{ minWidth: "150px" }}>
                                    {getHeaderLabel(header)}
                                  </th>
                                );
                              })}
                            </>
                          </tr>
                        </thead>
                        <tbody>
                          {values.data.map((row, index) => (
                            <tr
                              style={{
                                fontSize: "1em",
                                color: "#4e4e4e",
                              }}
                              key={index}
                            >
                              {headers.map((header) => {
                                return (
                                  <td
                                    className={classes.tableTd}
                                    key={header}
                                    style={{ textAlign: "center" }}
                                  >
                                    {values.data[index][header]}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default OutputTable;
