import React, { useEffect, useState, useContext } from "react";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  Outlet,
} from "react-router-dom";
import { AuthContext } from "./auth/AuthWrapper";
import Cookies from "js-cookie";
import NotFound from "./pages/NotFound";
import { ThemeProvider } from "@material-ui/styles";
import Login from "./pages/Login";

import Account from "./pages/Account/index";

import { SnackbarProvider } from "notistack";

import { createTheme, makeStyles } from "@material-ui/core";
import Home from "./pages/Home";
import Client from "./pages/Clients/Client";
import Header from "./components/Header/Header";
import {
  ACCOUNT,
  CLIENT,
  DASHBOARD,
  HOME,
  SEARCH,
} from "./types/routeConstants";
import Invoices from "./pages/Dashboard/Invoices";
import File from "./pages/FilePage/File";

import AddClient from "./pages/Clients/AddClient";
import ViewClient from "./pages/Clients/ViewClient";
import ViewFile from "./pages/FilePage/ViewFile";

// import AxiosInterceptor from "./endpoint/AxiosInterceptor";

let theme = createTheme({
  palette: {
    primary: {
      main: "#147CFF",
    },
    secondary: {
      main: "#ffffff",
    },
    error: {
      main: "#E47C69",
    },
  },
  typography: {
    fontFamily: "Arial",
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "20px",
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1400,
  },
}));
function App() {
  const { isAuthenticated, isLoading, user } = useContext(AuthContext);
  const classes = useStyles();

  if (isLoading) return <div>Loading</div>;
  return (
    <React.Fragment>
      {/* <AxiosInterceptor> */}
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          classes={{
            root: classes.root,
          }}
          dense={true}
          maxSnack={5}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <BrowserRouter>
            <Routes>
              {/* Private Routes */}
              <Route
                path="/"
                element={
                  <PrivateRoutes
                    isAuthenticated={isAuthenticated}
                    user={user}
                  />
                }
              >
                <Route path={HOME.path} element={<Home />} exact>
                  <Route path={HOME.childPath1} element={<Home />} />
                  <Route path={HOME.childPath2} element={<Home />} />
                </Route>
                <Route path={DASHBOARD.path} element={<Invoices />} />
                <Route path={CLIENT.path} element={<Client />} />

                <Route
                  path={`${CLIENT.childPathAdd}`}
                  element={<AddClient />}
                />
                <Route
                  path={`${CLIENT.childPathView}/:id/*`}
                  element={<ViewClient />}
                />
                <Route
                  path={`${CLIENT.childPathEdit}/:id/*`}
                  element={<AddClient />}
                />
                <Route path={SEARCH.path} element={<File />} />
                <Route path={SEARCH.childPathFile} element={<ViewFile />} />
                <Route path={ACCOUNT.path} element={<Account />} />
              </Route>

              {/* Public Routes */}
              <Route
                element={
                  <AnonymousRoutes
                    isAuthenticated={isAuthenticated}
                    user={user}
                  />
                }
              >
                <Route element={<Login />} path="/login" />
              </Route>

              {/* Not Found */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
      {/* </AxiosInterceptor> */}
    </React.Fragment>
  );
}

const PrivateRoutes = ({ isAuthenticated, user }) => {
  return isAuthenticated && user.terms_and_conditions ? (
    <>
      <Header />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

const AnonymousRoutes = ({ isAuthenticated, user }) => {
  return !isAuthenticated || !user.terms_and_conditions ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export default App;
