import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../auth/AuthWrapper";
import { HOME, CLIENT, DASHBOARD, ACCOUNT } from "../../types/routeConstants";

import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import {
  Button,
  IconButton,
  InputBase,
  LinearProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { Pagination, Skeleton } from "@material-ui/lab";

import { alertError } from "../../helpers";

import globalStyles from "../../helpers/GlobalStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import Endpoint from "../../endpoint";

import AdminIcon from "../../icons/Admin.js";
import { ReactComponent as UserIcon } from "../../icons/User.svg";
import { ReactComponent as ClientAdminIcon } from "../../icons/ClientAdmin.svg";
import { ReactComponent as ClientUserIcon } from "../../icons/ClientUser.svg";

import SearchComponent from "../../pages/FilePage/SearchComponent";
import UserAvatar from "./UserAvatar";

const useStyles = makeStyles(
  (theme) => ({
    header: {
      width: "100%",
      position: "fixed",
      paddingTop: "2vh",
      zIndex: 2,
      background: "white",
      alignItems: "center",
    },
    headerContent: {
      width: "100%",
      justifyContent: "space-between",
    },
    tabContent: {
      display: "inherit",
      alignItems: "center",
    },
    tabContentStyle: {
      marginTop: 8,
      marginLeft: 12,
      // fontFamily: "Helvetica Neue",
      "&$selected": {
        backgroundColor: "#147CFF",
      },
    },
    indicator: {
      paddingBottom: "0.75px",
      marginBottom: "12px",
      width: "110px !important",
      marginLeft: "24px",
    },
    logo: {
      width: "165px",
      height: "30px",
      marginLeft: "4vh",
    },
    menu: {
      // marginRight: "3vh",
      alignItems: "center",
    },
    headerDividerContent: {
      width: "100%",
      marginTop: "10px",
    },
    headerDivider: {
      margin: 0,
      // marginLeft: "30px",
      // marginRight: "30px",
      borderTop: "1px solid #808080",
    },
    content: {
      width: "100%",
      paddingTop: "calc(3vh + 50px)",
      paddingLeft: "4vh",
      marginRight: "50vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    tabStyles: {
      fontSize: ".7em !important",
    },
    selectedSearchAddress: {
      color: "#147CFF",
      fontWeight: 800,
      fontSize: ".7em !important",
    },
    selectedUpdateBatch: {
      color: "#147CFF",
      fontWeight: 800,
      fontSize: ".7em !important",
    },
    selectedAccount: {
      color: "#147CFF",
      fontWeight: 800,
      fontSize: ".7em !important",
      // width: "50px !important",
    },
    userIcons: {
      width: "26px",
      marginLeft: "18px",
      marginRight: "10px",
    },
  }),
  { index: 1 }
);

/**

A React functional component for HEADER LAYOUT.
This header is rendered on all protected routes.

*/
function Header() {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [isLoading, setLoading] = useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const { user, logout } = useContext(AuthContext);

  // Function to handle tab changes for Home, dashboard, and client etc
  const handleTabChange = (event, newValue) => {
    // console.log(newValue);
    setTabValue(newValue);
    // if (newValue == 2) {
    //   navigate("/client");
    // }
    switch (newValue) {
      case HOME.index:
        return navigate(HOME.path);
      case DASHBOARD.index:
        return navigate(DASHBOARD.path);
      case CLIENT.index:
        return navigate(CLIENT.path);
      default:
        return navigate("/");
    }
  };

  // use effect to redirect user to the correct path based on pathname
  useEffect(() => {
    var tempPath = location.pathname.split("/")[1];
    // Check for path for when user Refreshes the page and update the tab Value
    if (tempPath == HOME.path.split("/")[1]) {
      setTabValue(HOME.index);
    } else if (tempPath == DASHBOARD.path.split("/")[1]) {
      setTabValue(DASHBOARD.index);
    } else if (tempPath == CLIENT.path.split("/")[1]) {
      setTabValue(CLIENT.index);
    }

    if (user) {
      setLoading(false);
    }
  }, []);

  // const logout = () => {
  //   Endpoint.Users.logout()
  //     .then((res) => {
  //       Cookies.remove("_id");
  //       window.location = "/login";
  //     })
  //     .catch((err) => {
  //       if (err.response && err.response.status !== 401) {
  //         Swal.fire(
  //           "something went wrong",
  //           err.response ? err.response.data.message : err.message,
  //           "error"
  //         );
  //       }
  //     });
  // };

  return (
    <div className={`${classes.header} ${globalClasses.flexColumn}`}>
      <div className={`${classes.headerContent} ${globalClasses.flexRow}`}>
        <div className={classes.tabContent}>
          <img className={classes.logo} src="/logo.png" alt="logo" />
          <Tabs
            classes={{ indicator: classes.indicator }}
            TabIndicatorProps={{ style: { backgroundColor: "#147CFF" } }}
            value={tabValue}
            indicatorColor="primary"
            onChange={handleTabChange}
            aria-label="simple tabs example"
            className={classes.tabContentStyle}
          >
            <Tab
              classes={{ selected: classes.selectedSearchAddress }}
              className={classes.tabStyles}
              label="Home"
              {...a11yProps(0)}
            />

            <Tab
              classes={{ selected: classes.selectedUpdateBatch }}
              className={classes.tabStyles}
              label="Dashboard"
              {...a11yProps(1)}
            />

            {user.isAdmin ? (
              <Tab
                classes={{ selected: classes.selectedAccount }}
                className={classes.tabStyles}
                label="Client"
                {...a11yProps(1)}
              />
            ) : (
              <></>
            )}
          </Tabs>
        </div>

        <div className={`${classes.menu} ${globalClasses.flexRow}`}>
          <span>
            <SearchComponent />
          </span>

          {isLoading ? (
            <>
              <Skeleton variant="circle" width={40} height={40} />
            </>
          ) : (
            <div>
              {user.isClientAdmin && !user.isAdmin ? (
                <Tooltip title="Client Admin">
                  <div>
                    <ClientAdminIcon className={`${classes.userIcons}`} />
                  </div>
                </Tooltip>
              ) : (
                <>
                  {user.clientName && !user.isAdmin ? (
                    <Tooltip title="Client User">
                      <div>
                        <ClientUserIcon className={`${classes.userIcons}`} />
                      </div>
                    </Tooltip>
                  ) : (
                    <>
                      {user.isAdmin ? (
                        <Tooltip title="Site Admin">
                          <div>
                            <AdminIcon className={`${classes.userIcons}`} />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Site User">
                          <div>
                            <UserIcon className={`${classes.userIcons}`} />
                          </div>
                        </Tooltip>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}

          <span
            // className={`${exitClass} ${globalClasses.flexRow}`}
            className={` ${globalClasses.flexRow}`}
            style={{ marginRight: "20px" }}
            // onClick={logout}
          >
            {/* <ExitToAppIcon />
                Logout */}
            {user.username ? (
              <UserAvatar username={user.username} handleLogout={logout} />
            ) : (
              <Skeleton variant="circular" width={40} height={40} />
            )}
          </span>
          {/* <CustomMenu onClick={setClick} onCheck={setCheck}/> */}
        </div>
      </div>
      <div className={classes.headerDividerContent}>
        <hr className={classes.headerDivider} />
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanelUploadBatch(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpaneluploadbatch"
      hidden={value !== index}
      id={`simple-tabpaneluploadbatch-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelUploadBatch.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yPropsUploadBatch(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpaneluploadbatch-${index}`,
  };
}

const defaultProps = {
  bgcolor: "background.paper",
  color: "#147CFF",
  m: 1,
  border: 2,
  style: {
    width: "17rem",
    height: "27rem",
    paddingTop: "20px",
    borderRadius: "20px",
    display: "inline-table",
    paddingRight: "5px",
    paddingLeft: "5px",
  },
};
const dashboardFilterBoxProps = {
  bgcolor: "background.paper",
  color: "#147CFF",
  m: 2,
  border: 2,
  style: {
    width: "40rem",
    height: "14rem",
    paddingTop: "20px",
    borderRadius: "20px",
    display: "flex",
    margin: "auto",
  },
};
const currentUsernameProps = {
  m: 1,
  // border: 2,
  style: {
    width: "8.5rem",
    height: "0.2rem",
    alignContents: "center",
    display: "inline-table",
    margin: 0,
    marginLeft: "23%",
    paddingRight: "5px",
    paddingLeft: "5px",
  },
};

export default Header;
