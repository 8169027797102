import * as React from "react";
import { createSvgIcon } from "@material-ui/core";

export default createSvgIcon(
  
    <image
      width="26"
      height="26"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAACG0lEQVRIS+2Wu4vUUBTGv3OHICqyio/MzR0fTL3gsrOluKigjY2FIBaCdlv6H2ijhWAj2FqpoCC4dgouCD4WGddeFnSZnAiZyscOTLI5kiGRGGaYSTLbedvknt853z3fvYewDcu27UNEdDAMw41ut/szRtC0OcaYJRG5n8T9oZQ62+l0VqcKykA+KaWWoih6BmAPAD01UK6SrlLqNICdURStisixAcgYs19ELgM4QEQz4+SMomhLKfXUdd0Pyf5UrhUiuisiTwD8BrAB4Hi/399HjUYjpn6JeeMAue83mflGppIVAOeZeVNrfYGIYtkiEbnied5DMsbMicgagHPM/AqATAocBmk2mzO9Xu8lES2kkEHXaa1PEtELZh4rWTaBIpABqF6vLyqlnjPz3u2oJI1ZGFS0klKgspBC0k0KcRznHhE9cl33ffYoJpJOa32UiNYBfARwJm7hUd3lOE7ctYPWLwxyHOcSgMcisuh53ptRkDhwVdAdANctyzocBMEsgFsA5rM+SbOvCnoN4FRGikBErsaOz1uiKug2gFkR+QxgLQzDd77vfx/mu0qgSY1c+Yz+g0r7aCrSJc/EcpHbexS41WpZnuf1h1aktZ4nojaAE8z8tkj2+X+NMReTZ/waMz/45wqybXt3rVb7Gs8LAH4B2CoJswDsArAeBMGc7/txrL9rMJwkc8MRpdSOkpB026Zt29/a7XaQj/MHktzCNfyu3UYAAAAASUVORK5CYII="
      data-name="Vector Smart Object"
    //   transform="translate(1425 371)"
    />
  ,
  "Download"
);
