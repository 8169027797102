import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import { getHeaderLabel } from "./helper";

const downloadInvoices = async (
  clientDetails,
  data,
  headers,
  totalAmount,
  accountWorkSheetData,
  clientCode
) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Client Invoice");

    const imagePath = `${process.env.PUBLIC_URL}/logo.png`;
    const response = await fetch(imagePath);
    const blob = await response.blob();

    const base64String = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.readAsDataURL(blob);
    });

    const imageId = workbook.addImage({
      base64: `data:image/png;base64,${base64String}`,
      extension: "png",
    });

    worksheet.addImage(imageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 350, height: 100 },
    });

    worksheet.mergeCells("A7:D7");
    worksheet.getCell("A7").value = "Make Checks Payable To:";
    worksheet.getCell("A7").font = { bold: true };

    worksheet.mergeCells("A8:D8");
    worksheet.getCell("A8").value = "Quality Claims Management Corp.";

    worksheet.mergeCells("A10:D10");
    worksheet.getCell("A10").value = "Mailing Address:";
    worksheet.getCell("A10").font = { bold: true };

    worksheet.mergeCells("A11:D11");
    worksheet.getCell("A11").value = "Attn: FACT";

    worksheet.mergeCells("A12:D12");
    worksheet.getCell("A12").value = "2763 Camino Del Rio South, 3rd Floor";

    worksheet.mergeCells("A13:D13");
    worksheet.getCell("A13").value = "San Diego, CA 92108";

    worksheet.mergeCells("A15:B15");
    worksheet.getCell("A15").value = `Invoice #: ${clientCode}`;

    worksheet.mergeCells("C15:F15");
    worksheet.getCell("C15").value = `Client: ${clientDetails.client_name}/OccuTrack`;

    worksheet.mergeCells("A16:B16");
    worksheet.getCell("A16").value = `Created On: ${moment().format("M/D/YY")}`;

    worksheet.mergeCells("C16:F16");
    worksheet.getCell("C16").value = `Client ID #: ${clientDetails.client_id}`;

    worksheet.mergeCells("A17:B17");
    worksheet.getCell("A17").value = `Total Fees Due: $${totalAmount}`;
    worksheet.getCell("A17").font = {
      bold: true,
      color: { argb: "FF0000" },
    };
    worksheet.getCell("A17").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFF00" },
    };

    worksheet.mergeCells("C17:F17");
    worksheet.getCell("C17").value = "Item ID: OCCUTRACK";
    const boldGreenBorder = {
      top: { style: "medium", color: { argb: "006400" } },
      left: { style: "medium", color: { argb: "006400" } },
      bottom: { style: "medium", color: { argb: "006400" } },
      right: { style: "medium", color: { argb: "006400" } },
    };

    worksheet.getCell("A15").border = {
      top: boldGreenBorder.top,
      left: boldGreenBorder.left,
    };
    worksheet.getCell("B15").border = {
      top: boldGreenBorder.top,
    };
    worksheet.getCell("C15").border = {
      top: boldGreenBorder.top,
    };
    worksheet.getCell("D15").border = {
      top: boldGreenBorder.top,
      right: boldGreenBorder.right,
    };
    worksheet.getCell("E15").border = {
      top: boldGreenBorder.top,
      right: boldGreenBorder.right,
    };
    worksheet.getCell("F15").border = {
      top: boldGreenBorder.top,
      right: boldGreenBorder.right,
    };

    worksheet.getCell("A16").border = {
      left: boldGreenBorder.left,
    };
    worksheet.getCell("D16").border = {
      right: boldGreenBorder.right,
    };
    worksheet.getCell("E16").border = {
      right: boldGreenBorder.right,
    };
    worksheet.getCell("F16").border = {
      right: boldGreenBorder.right,
    };
    worksheet.getCell("A17").border = {
      bottom: boldGreenBorder.bottom,
      left: boldGreenBorder.left,
    };
    worksheet.getCell("B17").border = {
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("C17").border = {
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("F17").border = {
      bottom: boldGreenBorder.bottom,
      right: boldGreenBorder.right,
    };
    worksheet.addRow([]);

    worksheet.mergeCells("A19:K19");
    worksheet.getCell("A19").value =
      "Remittance Info: Please send email to FACT@qualityclaims.com and reference the invoice number and order number.";
    worksheet.getCell("A19").font = { bold: true, color: { argb: "000000" } };

    worksheet.getCell("A19").border = {
      top: boldGreenBorder.top,
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("B19").border = {
      top: boldGreenBorder.top,
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("C19").border = {
      top: boldGreenBorder.top,
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("D19").border = {
      top: boldGreenBorder.top,
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("E19").border = {
      top: boldGreenBorder.top,
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("F19").border = {
      top: boldGreenBorder.top,
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("G19").border = {
      top: boldGreenBorder.top,
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("I19").border = {
      top: boldGreenBorder.top,
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("J19").border = {
      top: boldGreenBorder.top,
      bottom: boldGreenBorder.bottom,
    };
    worksheet.getCell("K19").border = {
      top: boldGreenBorder.top,
      right: boldGreenBorder.right,
      bottom: boldGreenBorder.bottom,
    };

    worksheet.addRow([]);

    const formattedHeaders = headers.map((header) => getHeaderLabel(header));
    worksheet.addRow(formattedHeaders);

    const headerRowNumber = worksheet.lastRow.number;
    const headerRow = worksheet.getRow(headerRowNumber);

    headerRow.font = { bold: true, color: { argb: "FFFFFF" } };
    headerRow.alignment = { horizontal: "center", vertical: "middle" };
    headerRow.height = 30;

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "7AA7CE" },
      };
    });

    formattedHeaders.forEach((header, colIndex) => {
      let maxLength = header.length;
      data.forEach((row) => {
        const cellValue = row[colIndex];
        if (cellValue) {
          maxLength = Math.max(maxLength, cellValue.toString().length);
        }
      });
      worksheet.getColumn(colIndex + 1).width = maxLength + 2;
    });

    const dataRows = data;
    dataRows.forEach((rowData) => {
      const row = worksheet.addRow(rowData);
      row.alignment = { horizontal: "center" };
    });

    const amountColumnIndex = formattedHeaders.indexOf("Amount") + 1;

    const totalsRow = worksheet.addRow([]);
    totalsRow.getCell(1).value = "Totals";
    totalsRow.getCell(1).font = { bold: true };

    totalsRow.getCell(amountColumnIndex).value = `$${totalAmount.toFixed(2)}`;
    totalsRow.getCell(amountColumnIndex).font = { bold: true };

    worksheet.mergeCells(
      `A${totalsRow.number}:${String.fromCharCode(64 + amountColumnIndex - 1)}${totalsRow.number}`
    );

    totalsRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "C0C0C0" },
      };
      cell.border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
    });

    const accountingWorksheet = workbook.addWorksheet("Accounting Upload");

    const accountingHeaders = accountWorkSheetData.headers;

    accountingWorksheet.addRow(accountingHeaders);

    const accountingHeaderRow = accountingWorksheet.getRow(1);
    accountingHeaderRow.font = { bold: true, color: { argb: "FFFFFF" } };
    accountingHeaderRow.alignment = { horizontal: "center", vertical: "middle" };
    accountingHeaderRow.height = 25;

    accountingHeaderRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4F81BD" },
      };
      cell.border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
    });

    accountingHeaders.forEach((header, colIndex) => {
      accountingWorksheet.getColumn(colIndex + 1).width = header.length + 5;
    });

    accountWorkSheetData.data.forEach((rowData) => {
      const row = accountingWorksheet.addRow(rowData);
      row.alignment = { horizontal: "center" };
    });

    accountingHeaders.forEach((header, colIndex) => {
      let maxLength = header.length;
      data.forEach((row) => {
        const cellValue = row[colIndex];
        if (cellValue) {
          maxLength = Math.max(maxLength, cellValue.toString().length);
        }
      });
      accountingWorksheet.getColumn(colIndex + 1).width = maxLength + 2;
    });

    accountingWorksheet.columns.forEach((column) => {
      column.alignment = { horizontal: "left", vertical: "middle" };
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `invoice_${moment().format("M-D-YY")}.xlsx`);
  } catch (error) {
    console.log(error);
  }
};

export default downloadInvoices;
