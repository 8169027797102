import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Endpoint from "../../endpoint";
import { Tooltip } from "@material-ui/core";
import GlobalStyles from "../../helpers/GlobalStyles";
import { useSnackbar } from "notistack";
// import Loader from "react-loader-spinner";
import Loading from "../Loading";
import "./ClientList.css";

// import CustomLoader from '../../components/CustomLoader'
import EditClient from "../../icons/EditClient";
import DeleteIcon from "@mui/icons-material/Delete";
import { CLIENT } from "../../types/routeConstants";

const initialClientList = [
  {
    client_id: "23124",
    client_name: "Alex",
    street_address: "504 Street 3",
    city: "Lahore",
    zip_code: "2424",
    state: "MD",
    attention: "yes",
    tax_id: "",
    start_date: "2023-03-10",
    end_date: "",
    fee_lines: [
      {
        order_type_id: "30392",
        amount: "20",
        start_date: "2023-03-20",
        end_date: "",
      },
    ],
  },
  {
    client_id: "23120",
    client_name: "John Doe",
    street_address: "504 Street 3",
    city: "Lahore",
    zip_code: "2424",
    state: "MD",
    attention: "no",
    tax_id: "",
    start_date: "2023-03-10",
    end_date: "",
  },
];

/**

A React functional component for rendering the list of clients.
It has functions to delete/edit clients

*/
function ClientList() {
  const globalClasses = GlobalStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [clientList, setClientList] = useState(initialClientList);
  const [loading, setLoading] = useState(true);

  // Function for retrieving list of clients
  const getClients = () => {
    Endpoint.Clients.getClients()
      .then((res) => {
        setClientList(res.data);
        setLoading(false);
        enqueueSnackbar("Clients fetched successfully!", {
          variant: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    getClients();

    // setRefresh(false);
  }, []);

  // Function for deleting a client
  const deleteClient = (clientId) => {
    Endpoint.Clients.deleteClient(clientId)
      .then((res) => {
        // console.log(res.data);
        const updatedList = clientList.filter(
          (item) => item.client_id !== clientId
        );
        setClientList(updatedList);
        enqueueSnackbar(res.data.message, {
          variant: "success",
        });
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Sorry something went wrong, please try again later.", {
          variant: "error",
        });
      });
  };

  if (loading) return <Loading pageLoading={true} left={"60%"} />;
  return (
    <div style={{ overflowX: "auto" }}>
      <table className={globalClasses.clientTable}>
        <thead className={globalClasses.tableHead}>
          <tr className={globalClasses.clientthtr}>
            <th className={globalClasses.tableTh}>#</th>
            <th className={globalClasses.tableTh}>Client</th>
            <th className={globalClasses.tableTh}>Street address</th>
            <th className={globalClasses.tableTh}>City</th>
            <th className={globalClasses.tableTh}>State</th>
            <th className={globalClasses.tableTh}>Zip code</th>
            <th className={globalClasses.tableTh}>Attention</th>
            <th className={globalClasses.tableTh}>Tax ID</th>
            <th className={globalClasses.tableTh}>Start Date</th>
            <th className={globalClasses.tableTh}>End Date</th>
            <th
              className={globalClasses.tableTh}
              style={{ paddingRight: "30px" }}
            >
              Options
            </th>
          </tr>
        </thead>
        {clientList.map((val, key) => {
          return (
            <tbody key={`${key}-body`}>
              <tr style={{ fontSize: "1em", color: "#4e4e4e" }} key={key}>
                <td className={globalClasses.tableTh} key={`${key}-#`}>
                  {key + 1}
                </td>
                <td
                  className={globalClasses.tableTdName}
                  onClick={(e) => {
                    navigate(`${CLIENT.childPathView}/${val.client_id}`);
                  }}
                >
                  {val.client_name}
                </td>
                <td className={`${globalClasses.tableTd}`}>
                  {val.street_address}
                </td>
                <td className={globalClasses.tableTd}>{val.city}</td>
                <td className={globalClasses.tableTd}>{val.state}</td>
                <td className={globalClasses.tableTd}>{val.zip_code}</td>
                <td className={globalClasses.tableTd}>{val.attention}</td>
                <td className={globalClasses.tableTd}>{val.tax_id}</td>
                <td className={globalClasses.tableTd}>{val.start_date}</td>
                <td className={globalClasses.tableTd}>{val.end_date}</td>
                <td
                  className={globalClasses.tableTd}
                  style={{ paddingTop: "10px" }}
                >
                  <Tooltip title="Edit client">
                    <EditClient
                      className={globalClasses.tableIcons}
                      onClick={(e) => {
                        navigate(`${CLIENT.childPathEdit}/${val.client_id}`);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <DeleteIcon
                      className={globalClasses.tableIcons}
                      onClick={(e) => {
                        //delete code here
                        // console.log(val.client_id);
                        deleteClient(val.client_id);
                      }}
                    />
                  </Tooltip>
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
}

export default ClientList;
