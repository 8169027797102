import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../auth/AuthWrapper";

import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import {
  Button,
  IconButton,
  InputBase,
  LinearProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { Pagination } from "@material-ui/lab";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

import globalStyles from "../../helpers/GlobalStyles";

import AddUser from "../../dialogs/AddUser";
import ChangePassword from "../../dialogs/ChangePassword";
import NoSearchResults from "../../dialogs/NoSearchResults";
// import ChangeUsername from '../../dialogs/ChangeUsername';
import Endpoint from "../../endpoint";
import { alertError } from "../../helpers";
import Loading from "../Loading";

const useStyles = makeStyles(
  (theme) => ({
    header: {
      width: "100%",
      position: "fixed",
      paddingTop: "4vh",
      zIndex: 2,
      background: "white",
      alignItems: "center",
    },
    headerContent: {
      width: "100%",
      justifyContent: "space-between",
    },
    tabContent: {
      display: "inherit",
      alignItems: "center",
    },
    tabContentStyle: {
      marginTop: 12,
      marginLeft: 25,
      fontFamily: "Helvetica Neue",
      "&$selected": {
        backgroundColor: "#147CFF",
      },
    },
    tabContentStyleUploadBatch: {
      // marginTop: 12,
      // marginLeft: 25,
      fontFamily: "Helvetica Neue",
      "&$selected": {
        backgroundColor: "#147CFF",
      },
    },
    indicator: {
      paddingBottom: "0.75px",
      marginBottom: "12px",
      width: "140px !important",
      marginLeft: "9px",
    },
    indicatorUploadBatch: {
      paddingBottom: "2px",
      marginBottom: "12px",
      width: "90px !important",
      marginLeft: "35px",
      color: "#147CFF",
      fontWeight: 800,
    },
    logo: {
      width: "170px",
      height: "30px",
      marginLeft: "4vh",
    },
    menu: {
      // marginRight: "3vh",
      alignItems: "center",
    },
    menuItem: {
      height: "max-content",
      marginRight: "18px",
      marginLeft: "4px",
      color: "#435465",
      cursor: "pointer",
      WebkitTransition: "all 0.2s ease-in-out",
      MozTransition: "all 0.2s ease-in-out",
      msTransition: "all 0.2s ease-in-out",
      transform: "all 0.2s ease-in-out",
      "&:before": {
        content: "''",
        display: "inline-block",
        width: "15px",
        height: "15px",
        marginRight: "6px",
        marginBottom: "-1px",
        MozBorderRadius: "50%",
        WebkitBorderRadius: "50%",
        borderRadius: "50%",
        backgroundColor: "#147CFF",
      },
    },
    menuItemAdmin: {
      "&.collapsed:nth-child(1)": {
        WebkitTransition: "translate(+345%, 0)",
        MozTransition: "translate(+345%, 0)",
        msTransition: "translate(+345%, 0)",
        transform: "translate(+345%, 0)",
      },
      "&.collapsed:nth-child(2)": {
        WebkitTransition: "translate(+175%, 0)",
        MozTransition: "translate(+175%, 0)",
        msTransition: "translate(+175%, 0)",
        transform: "translate(+175%, 0)",
      },
      "&.collapsed:nth-child(3)": {
        WebkitTransition: "translate(+60%, 0)",
        MozTransition: "translate(+60%, 0)",
        msTransition: "translate(+60%, 0)",
        transform: "translate(+60%, 0)",
      },
    },
    menuItemUser: {
      "&.collapsed:nth-child(1)": {
        WebkitTransition: "translate(+175%, 0)",
        MozTransition: "translate(+175%, 0)",
        msTransition: "translate(+175%, 0)",
        transform: "translate(+175%, 0)",
      },
      "&.collapsed:nth-child(2)": {
        WebkitTransition: "translate(+60%, 0)",
        MozTransition: "translate(+60%, 0)",
        msTransition: "translate(+60%, 0)",
        transform: "translate(+60%, 0)",
      },
    },
    exit: {
      marginRight: "18px",
      cursor: "pointer",
      color: "#435465",
      WebkitTransition: "all 0.2s ease-in-out",
      MozTransition: "all 0.2s ease-in-out",
      msTransition: "all 0.2s ease-in-out",
      transform: "all 0.2s ease-in-out",
      "& :first-child": {
        marginRight: "5px",
        color: "#147CFF",
      },
      "&.collapsed": {
        WebkitTransition: "translate(+50%, 0)",
        MozTransition: "translate(+50%, 0)",
        msTransition: "translate(+50%, 0)",
        transform: "translate(+50%, 0)",
      },
    },
    openItemMenu: {
      opacity: 1,
      alignItems: "center",
    },
    closeItemMenu: {
      opacity: 0,
      cursor: "default",
    },
    headerDividerContent: {
      width: "100%",
      marginTop: "2px",
    },
    headerDivider: {
      margin: 0,
      // marginLeft: "30px",
      // marginRight: "30px",
      borderTop: "1px solid #808080",
    },
    filterDivider: {
      margin: 0,
      color: "#00713E",
      height: "145px",
      alignSelf: "center",
    },
    contentDivider: {
      margin: 0,
      marginLeft: "3vh",
      marginRight: "3vh",
      marginTop: "30px",
      borderLeft: "1px solid #00713E",
    },
    content: {
      width: "100%",
      paddingTop: "calc(3vh + 50px)",
      paddingLeft: "4vh",
      marginRight: "50vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    uploadBatchcontentTableDashboard: {
      width: "100%",
      // paddingTop: "calc(3vh + 50px)",
      paddingLeft: "5vh",
      paddingRight: "2vh",
      marginRight: "50vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    updateBatchContent: {
      width: "100%",
      paddingTop: "calc(10vh + 100px)",
      paddingLeft: "36%",
      // marginRight: "10vh",
      fontFamily: "Helvetica Neue",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
      alignItems: "center",
      marginBottom: "10px",
    },
    actions: {
      alignItems: "center",
      marginBottom: "10px",
    },
    actionsUpdateBatch: {
      // marginTop: "200px",
      // fontFamily: "Helvetica Neue",
    },
    contentTitle: {
      color: "#147CFF",
      marginBottom: "10px",
      fontSize: "25px",
    },
    tableSearchAddress: {
      paddingRight: "1vh",
    },
    uploadBatchcontentTitle: {
      color: "#147CFF",
      marginBottom: "10px",
      marginRight: "25px",
      marginLeft: "27px",
      fontSize: "25px",
    },
    actionButton: {
      width: "300px",
      height: "60px",
      // top: "200px",
      padding: 0,
      color: "#FFFFF",
      marginLeft: "32px",
      backgroundColor: "#147CFF",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#147CFF",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    actionButtonAgain: {
      width: "200px",
      height: "35px",
      float: "right",
      padding: 0,
      color: "#FFFFF",
      marginLeft: "32px",
      backgroundColor: "#147CFF",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#147CFF",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    actionUsernameButton: {
      width: "200px",
      height: "35px",
      // float: 'right',
      padding: 0,
      color: "#FFFFF",
      marginLeft: "14%",

      backgroundColor: "#147CFF",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#147CFF",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    actionPasswordButton: {
      width: "200px",
      height: "35px",
      // float: 'right',
      padding: 0,
      color: "#FFFFF",
      marginLeft: "14%",
      marginTop: "30px",
      backgroundColor: "#147CFF",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#147CFF",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    actionUserButton: {
      width: "200px",
      height: "35px",
      // float: 'right',
      padding: 0,
      color: "#FFFFF",
      marginLeft: "14%",
      marginTop: "30px",
      backgroundColor: "#147CFF",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#147CFF",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    uploadBatchText: {
      textAlign: "center",
      fontFamily: "Helvetica Neue",
      color: "#147CFF",
      marginBottom: "50px",
    },
    rightSide: {
      width: "100%",
    },
    form: {
      width: "30vh",
      minWidth: "250px",
      background: "#147CFF",
      padding: "25px",
      alignSelf: "flex-start",
      borderRadius: "3vh",
    },
    input: {
      height: "30px",
      width: "100%",
      padding: theme.spacing(2),
      marginTop: "3px",
      borderRadius: "30px",
      fontSize: "14px",
      backgroundColor: "#FFFFFF",
    },
    button: {
      width: "120px",
      height: "30px",
      fontSize: "16px",
      margin: "auto",
      marginTop: "12px",
      textTransform: "none",
    },
    field: {
      // border: '1px solid red',
      width: "100%",
      margin: "0auto",
      // marginTop: '12px',
      marginTop: "0",
      "& label": {
        fontSize: "18px",
        color: "#FFFFFF",
      },
    },
    buttonField: {
      marginTop: 0,
    },
    paginator: {
      justifyContent: "center",
      padding: "10px",
    },
    tabStyles: {
      fontSize: "12px !important",
    },
    selectedSearchAddress: {
      color: "#147CFF",
      fontWeight: 800,
      fontSize: "12px !important",
    },
    selectedUpdateBatch: {
      color: "#147CFF",
      fontWeight: 800,
      fontSize: "12px !important",
    },
    selectedAccount: {
      color: "#147CFF",
      fontWeight: 800,
      fontSize: "12px !important",
      // width: "50px !important",
    },
    accountContent: {
      width: "100%",
      paddingTop: "calc(8vh + 100px)",
      paddingLeft: "40%",
      paddingRight: "32%",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    dialogInput: {
      marginLeft: "32px",
      marginRight: "32px",
      width: "75% !important",
    },
    userIcons: {
      width: "30px",
      // marginTop: "8px",
      marginRight: "10px",
    },
    filterFields: {
      width: "100%",
      margin: "auto",
      marginTop: "12px",
      "& label": {
        fontSize: "12px",
        color: "black",
      },
      justifyContent: "space-evenly",
    },
  }),
  { index: 1 }
);

/**

A React functional component for rendering the Account Page.
User can change their password
Admin's can add new users/admins.

*/
function Account({ tabData }) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const globalClasses = globalStyles();
  const [isLoading, setLoading] = useState(true);

  const [addUserDialog, setAddUserDialog] = useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, []);

  const openAddUserDialog = () => {
    setAddUserDialog(true);
  };
  const closeAddUserDialog = () => {
    setAddUserDialog(false);
  };
  const openChangePasswordDialog = () => {
    setChangePasswordDialog(true);
  };

  const closeChangePasswordDialog = () => {
    setChangePasswordDialog(false);
  };

  return (
    <div className={`${classes.accountContent}`}>
      {/* <div className={`${globalClasses.flexRow}`}> */}

      <div className={`${globalClasses.flexColumn}`}>
        <AddUser
          handleClose={closeAddUserDialog}
          open={addUserDialog}
          isAdmin={user.isAdmin}
          isClientAdmin={user.isClientAdmin}
          clientName={user.clientName}
        />

        <ChangePassword
          username={user.username}
          handleClose={closeChangePasswordDialog}
          open={changePasswordDialog}
        />
        <Box borderColor="#808080" {...defaultProps}>
          <Typography
            variant="h4"
            style={{
              // color: '#147CFF',
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "Helvetica Neue",
            }}
          >
            Account
          </Typography>
          <Box bgcolor="#147CFF" {...currentUsernameProps}>
            <Typography
              variant="body1"
              style={{
                color: "#FFFF",
                top: "10px",
                textAlign: "center",
                fontFamily: "Helvetica Neue",
              }}
            >
              Current Username
            </Typography>
          </Box>
          <Box {...currentUsernameProps}>
            <Typography
              variant="body1"
              style={{
                color: "#147CFF",
                textAlign: "center",
                fontFamily: "Helvetica Neue",
              }}
            >
              {user.username}
            </Typography>
          </Box>

          {user.isClientAdmin && user.clientName ? (
            <div style={{}}>
              <Box bgcolor="#147CFF" {...currentUsernameProps}>
                <Typography
                  variant="body1"
                  style={{
                    color: "#FFFF",
                    top: "10px",
                    textAlign: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  Client Name
                </Typography>
              </Box>
              <Box {...currentUsernameProps}>
                <Typography
                  variant="body1"
                  style={{
                    color: "#147CFF",
                    textAlign: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {user.clientName}
                </Typography>
              </Box>
            </div>
          ) : (
            <></>
          )}
          {/* <Tooltip title="Change Username">
                    <IconButton
                      component="span"
                      className={classes.actionUsernameButton}
                      style={{ marginTop: clientName ? '30px' : '80px' }}
                      onClick={openChangeUsernameDialog}
                    >
                      <Typography variant="body1" style={{ color: '#FFFF' }}>
                        Change Username
                      </Typography>
                    </IconButton>
                  </Tooltip> */}
          <Tooltip title="Change Password">
            <IconButton
              component="span"
              className={classes.actionPasswordButton}
              onClick={openChangePasswordDialog}
            >
              <Typography variant="body1" style={{ color: "#FFFF" }}>
                Change Password
              </Typography>
            </IconButton>
          </Tooltip>
          {user.isAdmin || user.isClientAdmin ? (
            <Tooltip title="Change Password">
              <IconButton
                component="span"
                className={classes.actionUserButton}
                onClick={openAddUserDialog}
              >
                <Typography variant="body1" style={{ color: "#FFFF" }}>
                  Add User
                </Typography>
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Box>
        {/* </div>  */}
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanelUploadBatch(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpaneluploadbatch"
      hidden={value !== index}
      id={`simple-tabpaneluploadbatch-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelUploadBatch.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yPropsUploadBatch(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpaneluploadbatch-${index}`,
  };
}

const defaultProps = {
  bgcolor: "background.paper",
  color: "#147CFF",
  m: 1,
  border: 2,
  style: {
    width: "17rem",
    height: "27rem",
    paddingTop: "20px",
    borderRadius: "20px",
    display: "inline-table",
    paddingRight: "5px",
    paddingLeft: "5px",
  },
};
const dashboardFilterBoxProps = {
  bgcolor: "background.paper",
  color: "#147CFF",
  m: 2,
  border: 2,
  style: {
    width: "40rem",
    height: "14rem",
    paddingTop: "20px",
    borderRadius: "20px",
    display: "flex",
    margin: "auto",
  },
};
const currentUsernameProps = {
  m: 1,
  // border: 2,
  style: {
    width: "8.5rem",
    height: "0.2rem",
    alignContents: "center",
    display: "inline-table",
    margin: 0,
    marginLeft: "23%",
    paddingRight: "5px",
    paddingLeft: "5px",
  },
};

export default Account;
