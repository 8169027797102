const ColumnNames = [
  { label: "File Number", value: "file_number" },
  { label: "Work Number", value: "work_number" },
  { label: "Client File Number", value: "client_file_number" },
  { label: "Order Number", value: "order_number" },
  { label: "Order Date", value: "order_date" },
  { label: "Street", value: "street" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Zip Code", value: "zip_code" },
  { label: "Skip Status", value: "skip_status" },
  { label: "Client Name", value: "client_name" },
  { label: "Batch Number", value: "batch_number" },
  { label: "Order Type", value: "order_type" },
  { label: "Client Code", value: "client_code" },
  { label: "Borrower 1", value: "borrower_1" },
  { label: "Borrower 2", value: "borrower_2" },
  { label: "Notes", value: "notes" },
  { label: "Username", value: "user_name" },

  { label: "Occupancy Status", value: "occupancy_status" },
  { label: "Confidence Score", value: "confidence_score" },
  { label: "Owner Name", value: "owner_name" },
  { label: "Deceased Flag 1", value: "deceased_flag_1" },
  { label: "Deceased Flag 2", value: "deceased_flag_2" },
  { label: "Order Complete Date", value: "order_complete_date" },
  {
    label: "Borrower 1 Deceased Indicator",
    value: "borrower_1_deceased_indicator",
  },
  {
    label: "Borrower 2 Deceased Indicator",
    value: "borrower_2_deceased_indicator",
  },
  { label: "Occupant ID", value: "occupant_id" },
  { label: "Borrower 1 Primary Address", value: "borrower_1_primary_address" },
  { label: "Borrower 1 Primary City", value: "borrower_1_primary_city" },
  { label: "Borrower 1 Primary State", value: "borrower_1_primary_state" },
  { label: "Borrower 1 Primary Zip", value: "borrower_1_primary_zip" },
  {
    label: "Borrower 1 Secondary Address",
    value: "borrower_1_secondary_address",
  },
  { label: "Borrower 1 Secondary City", value: "borrower_1_secondary_city" },
  { label: "Borrower 1 Secondary State", value: "borrower_1_secondary_state" },
  { label: "Borrower 1 Secondary Zip", value: "borrower_1_secondary_zip" },
  { label: "Borrower 1 Phone 1", value: "borrower_1_phone_1" },
  { label: "Borrower 1 Phone 2", value: "borrower_1_phone_2" },
  { label: "Borrower 1 Phone 3", value: "borrower_1_phone_3" },
  { label: "Borrower 1 Phone 4", value: "borrower_1_phone_4" },
  { label: "Borrower 1 Email 1", value: "borrower_1_email_1" },
  { label: "Borrower 1 Email 2", value: "borrower_1_email_2" },
  { label: "Borrower 1 Email 3", value: "borrower_1_email_3" },
  { label: "Borrower 2 Primary Address", value: "borrower_2_primary_address" },
  { label: "Borrower 2 Primary City", value: "borrower_2_primary_city" },
  { label: "Borrower 2 Primary State", value: "borrower_2_primary_state" },
  { label: "Borrower 2 Primary Zip", value: "borrower_2_primary_zip" },
  {
    label: "Borrower 2 Secondary Address",
    value: "borrower_2_secondary_address",
  },
  { label: "Borrower 2 Secondary City", value: "borrower_2_secondary_city" },
  { label: "Borrower 2 Secondary State", value: "borrower_2_secondary_state" },
  { label: "Borrower 2 Secondary Zip", value: "borrower_2_secondary_zip" },
  { label: "Borrower 2 Phone 1", value: "borrower_2_phone_1" },
  { label: "Borrower 2 Phone 2", value: "borrower_2_phone_2" },
  { label: "Borrower 2 Phone 3", value: "borrower_2_phone_3" },
  { label: "Borrower 2 Phone 4", value: "borrower_2_phone_4" },
  { label: "Borrower 2 Email 1", value: "borrower_2_email_1" },
  { label: "Borrower 2 Email 2", value: "borrower_2_email_2" },
  { label: "Borrower 2 Email 3", value: "borrower_2_email_3" },
  { label: "Occupant 1 First Name", value: "occupant_1_first_name" },
  { label: "Occupant 1 Last Name", value: "occupant_1_last_name" },
  { label: "Occupant 1 Phone 1", value: "occupant_1_phone_1" },
  { label: "Occupant 1 Phone 2", value: "occupant_1_phone_2" },
  { label: "Occupant 1 Phone 3", value: "occupant_1_phone_3" },
  { label: "Occupant 2 First Name", value: "occupant_2_first_name" },
  { label: "Occupant 2 Last Name", value: "occupant_2_last_name" },
  { label: "Occupant 2 Phone 1", value: "occupant_2_phone_1" },
  { label: "Occupant 2 Phone 2", value: "occupant_2_phone_2" },
  { label: "Occupant 2 Phone 3", value: "occupant_2_phone_3" },
];

export default ColumnNames;
