import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { S3_BUCKET_NAME } from "../../../types/miscellaneous-constants";
import { makeStyles } from "@material-ui/styles";
import * as XLSX from "xlsx";
// import "./UploadBatch.css";

import clsx from "clsx";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { object, string } from "yup";
import Select from "react-select";
import { Button, IconButton, InputBase, LinearProgress, Tooltip, Typography } from "@material-ui/core";

import Box from "@material-ui/core/Box";

import globalStyles from "../../../helpers/GlobalStyles";

import Endpoint from "../../../endpoint";
import { alertError } from "../../../helpers";
import Loading from "../../Loading";

import UploadBatchTable from "./UploadBatchTable";
import ZillowTable from "./ZillowTable";
import DuplicateTable from "./DuplicateTable";
import OutputTable from "./OutputTable";

import UploadCSV from "./UploadCSV";
import DashboardFilterAddress from "../../../dialogs/DashboardFilterAddress";

import Papa from "papaparse";
import CsvDownload from "react-json-to-csv";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportIconProblem from "@mui/icons-material/ReportProblem";

import FMUInputField from "../../../components/shared/FormIkMUFormComponent/FMUInputField";
import UploadBatchField from "../../../components/shared/UploadBatchField";
import { states } from "../../../helpers/states";
import { clients, downloadTemplate, findMissingHeaders, orderTypes } from "../../../helpers/helper";
import { generateQuickGuid } from "../../../helpers/helper";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../auth/AuthWrapper";

const useStyles = makeStyles(
  (theme) => ({
    content: {
      width: "100%",
      paddingTop: "calc(3vh + 50px)",
      paddingLeft: "2vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },

    updateBatchContent: {
      width: "100%",
      paddingTop: "calc(3vh + 50px)",
      paddingLeft: "1%",
      // marginRight: "10vh",
      fontFamily: "Helvetica Neue",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
      alignItems: "center",
      marginBottom: "10px",
    },
    redoButton: {
      backgroundColor: theme.palette.primary.main,

      fontSize: ".8em",
      backgroundColor: "#f8f8f8",
      textTransform: "inherit",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
        color: "white",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
    actionButton: {
      backgroundColor: theme.palette.primary.main,
      fontSize: ".8em",
      color: "#ffffff",
      textTransform: "inherit",
      borderRadius: "50px",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      border: "1px solid #3C72D9",
      // height: "15px",
      padding: "8px",
      minWidth: "100px",
      // border: "1px solid #3C72D9",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
    fileLoadingStyles: {
      marginLeft: "5%",
      display: "flex",
      alignItems: "center",
    },
    uploadBatchText: {
      // textAlign: "center",
      // fontFamily: "Helvetica Neue",
      // color: "#147CFF",
      marginBottom: "30px",
    },
    rightSide: {
      width: "100%",
    },
    caseContainer: {
      width: "98%",
      marginTop: "5vh",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    clientTable: {
      width: "100%",
      textAlign: "left",
      // color: "#4e4e4e",
      // borderCollapse: "separate",
      // borderSpacing: "0 15px",
      fontSize: "1em",
      // maxWidth:"fit-content",
      // overflow: "visible",
      // display: "block",
      // overflowX: "auto",
      whiteSpace: "nowrap",
    },
    clientthtr: {
      backgroundColor: "#0000",
      borderRadius: "25px",
      paddingTop: "5px",
      // height: "30px",
    },

    tableTd: {
      whiteSpace: "nowrap",
    },
    tableTdName: {
      cursor: "pointer",
      color: "#197EFF",
      paddingLeft: "10px",
      whiteSpace: "nowrap",
    },
    tableTh: {
      textAlign: "center",
      whiteSpace: "nowrap",
      textTransform: "capitalize",
    },
    tableIconsAlert: {
      // cursor: "pointer",
      marginRight: "15px",
      width: "20px",
      "&:hover": {
        color: "#FFD700", // outer color on hover (yellow)
        // transition: "color 0.3s ease", // smooth transition on hover
        "& path": {
          fill: "#000", // inner exclamation color on hover (black)
          // transition: "fill 0.3s ease", // smooth transition for inner part on hover
        },
      },
    },
    tableIconsUnskip: {
      overflow: "visible",
      cursor: "pointer",
      width: "20px",
      "&:hover": {
        color: "#147CFF",
      },
    },
    tableIconsSkip: {
      overflow: "visible",
      cursor: "pointer",
      width: "20px",
      color: "#147CFF",
      "&:hover": {
        color: "red",
      },
    },
    parentChild: {
      height: "50px",
      flex: "1",
      width: "100%",
      "&:nth-child(1)": {
        paddingRight: "5vh",
      },
      "&:nth-child(2)": {
        paddingLeft: "5vh",
      },
    },
    rowContainer: {
      // display: "flex",
      marginTop: "8px",
    },
    fieldContainer: {
      // width: "100%",
    },
    input: {
      width: "95%",

      "& .MuiInputBase-input": {
        textIndent: "10px",
        textTransform: "capitalize",
        textAlign: "start",
      },
      height: "40px",
      paddingLeft: "3px",
      // borderBottom: "1px solid #BABABA",
    },
  }),
  { index: 1 }
);

const customStylesClientSelect = {
  textAlign: "end",
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "2px 0px 2px 8px",
  }),
  control: (base, state) => ({
    ...base,
    minHeight: "40px !important",
    minWidth: "150px",
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStyles = {
  textAlign: "end",
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "2px 0px 2px 8px",
  }),
  control: (base, state) => ({
    ...base,
    minHeight: "40px !important",
    borderRadius: null,
    paddingLeft: "15px",
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    // borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

/**

A React functional component for rendering the Upload Batch page

*/

function UploadBatch() {
  const classes = useStyles();
  const globalClasses = globalStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [currentStage, setCurrentStage] = useState(null);

  const [selectedClient, setSelectedClient] = useState("SafeGuard");
  const [selectedOrderType, setSelectedOrderType] = useState("Occupancy");
  const [batchNo, setBatchNo] = useState(generateQuickGuid());

  const { user } = useContext(AuthContext);
  const [fileType, setFileType] = React.useState(undefined);
  const [fileName, setFileName] = React.useState(undefined);
  const [headersMissingErrors, setHeadersMissingErrors] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [allHeaders, setAllHeaders] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [data, setData] = useState(null);
  const [zillowData, setZillowData] = useState(null);

  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileProcessing, setFileProcessing] = useState(false);

  const [checkingZillow, setCheckingZillow] = useState(null);
  const [skippingZillow, setSkippingZillow] = useState(false);
  const [outputData, setOutputData] = useState([]);
  const [connectionId, setConnectionId] = useState(null);
  const [s3Key, setS3Key] = useState("");
  const [duplicateCheckDate, setDuplicateCheckDate] = useState([]);
  const [duplicateCheckInProgress, setDuplicateCheckInProgress] = useState(true);
  const [uploadingToInputTable, setUploadingToInputTable] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Refreshing will close your connection. Are you sure you want to continue?";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Connect to websocket
  useEffect(() => {
    // setConnectionId(generateQuickGuid);
    // Initialize WebSocket connection
    const socket = new WebSocket("wss://8u13kwxw2k.execute-api.us-west-1.amazonaws.com/testing_stage");

    // Connection opened
    socket.onopen = (event) => {
      console.log("Connected to WebSocket");
      enqueueSnackbar("Connected to WebSocket", {
        variant: "success",
      });
      socket.send(JSON.stringify({ action: "defaultRoute" }));
    };

    // Listen for messages
    socket.onmessage = async (message) => {
      var msg = JSON.parse(message.data);
      console.log(msg);
      // console.log(typeof msg);

      if (msg.hasOwnProperty("connectionId")) {
        var wsConnectionId = msg["connectionId"];
        localStorage.setItem("connectionId", wsConnectionId);
        setConnectionId(wsConnectionId);
      }

      if (msg.presigned_url) {
        try {
          // Fetch data from S3 using axios
          const response = await axios.get(msg.presigned_url);
          console.log(response.data);

          if (msg.type == "usps") {
            setupDataForUSPSResponse(response.data);
          } else if (msg.type == "zillow") {
            setS3Key(msg.s3_key);
            if (response.data.skip_zillow) setSkippingZillow(false);
            else setupDataForZillowResponse(response.data.data);
          }
        } catch (error) {
          console.error("Error fetching data from S3:", error);
        }
      }
      if (msg.type == "output") {
        setOutputData(msg.data);
        const keysArray = Object.keys(msg.data[0]);
        setHeaders(keysArray);
        setUploadingToInputTable(false);
        handleNext("4");
      }
    };

    // Handle connection close
    socket.onclose = () => {
      console.log("Disconnected from WebSocket");
    };

    // Cleanup on component unmount
    return () => {
      socket.close();
    };
  }, []);

  function setupDataForUSPSResponse(msg) {
    // Add 'skip_status' field to each message object and set it to false
    // const updatedMsg = msg.map((item) => ({
    //   ...item,
    //   skip_status: false,
    // }));

    // Define the desired order of headers
    const desiredOrder = ["order_number", "street", "city", "state", "zip_code", "notes", "skip_status"];

    // Extract headers, excluding the unwanted ones
    const excludeHeaders = new Set(["work_id", "input_name_1", "input_name_2", "status_flag"]);

    const extractedHeaders = Object.keys(msg[0]).filter((header) => !excludeHeaders.has(header));

    // Order the extracted headers based on the desired order
    const orderedHeaders = desiredOrder.filter((header) => extractedHeaders.includes(header));

    // console.log(orderedHeaders);

    setAllHeaders(Object.keys(msg[0]));
    setHeaders(orderedHeaders);
    setData(msg);
  }

  // Here we get the response from the WebSocket and then store data into data and go to 0th stage
  useEffect(() => {
    if (data) {
      // console.log(data);
      setCurrentStage(0);
      setFileUploaded(true);
    }
  }, [data]);

  function setupDataForZillowResponse(msg) {
    // Add 'skip_status' field to each message object and set it to false

    const updatedMsg = msg.map((item) => ({
      ...item,
      skip_status: false,
    }));

    // Define the desired order of headers
    const desiredOrder = [
      "order_number",
      "street",
      "city",
      "state",
      "zip_code",
      // "notes",
      "status_type",
      "property_status",
      "skip_status",
    ];

    // Extract headers, excluding the unwanted ones
    const excludeHeaders = new Set(["work_id"]);

    const extractedHeaders = Object.keys(updatedMsg[0]).filter((header) => !excludeHeaders.has(header));

    // Order the extracted headers based on the desired order
    const orderedHeaders = desiredOrder.filter((header) => extractedHeaders.includes(header));

    console.log(orderedHeaders);

    setCheckingZillow(false);
    setAllHeaders(Object.keys(updatedMsg[0]));
    setHeaders(orderedHeaders);
    setZillowData(updatedMsg);
  }

  function setupDataForDuplicateCheckResponse(dupData) {
    // Define the desired order of headers
    const desiredOrder = [
      "file_number",
      "order_number",
      "street",
      "city",
      "state",
      "zip_code",
      "latest_order_date",
      "skip_status",
    ];

    // Extract headers
    const extractedHeaders = Object.keys(dupData[0]);

    // Order the extracted headers based on the desired order
    const orderedHeaders = desiredOrder.filter((header) => extractedHeaders.includes(header));

    setAllHeaders(Object.keys(dupData[0]));
    setHeaders(orderedHeaders);
    setDuplicateCheckDate(dupData);
    setDuplicateCheckInProgress(false);
  }

  // Here we get the response from the setupDataForZillowResponse and then store data into zillowData and go to 1st stage
  useEffect(() => {
    if (zillowData) {
      console.log(data);
      setCurrentStage(1);
      // setFileUploaded(true);
    }
  }, [zillowData]);

  function dataURItoBlob(dataURI) {
    console.log("dataURI:", dataURI);
    if (typeof dataURI !== "string") {
      throw new Error("Invalid input: dataURI must be a string");
    }

    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  // useEffect(() => {
  //   console.log(connectionId);
  // }, [connectionId]);

  const handleChooseCSV = ({ target }) => {
    const files = target.files;
    setHeadersMissingErrors([]);
    setFileName(files[0].name);
    const fileExtension = files[0].name.split(".").pop().toLowerCase();
    setFileType(fileExtension);

    if (files && files.length) {
      const file = files[0];
      if (fileExtension === "csv" || fileExtension === "xlsx") {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          if (fileExtension === "csv") {
            const csvString = e.target.result;
            const lines = csvString.split(/\r?\n/);
            const headers = lines[0].split(",");
            processHeadersAndData(headers, lines.slice(1), fileExtension);
          } else if (fileExtension === "xlsx") {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: "binary" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
              blankrows: false,
              defval: "",
            });

            if (jsonData.length > 0) {
              const headers = jsonData[0].map((header) => (header ? header.toString().trim() : ""));

              const data = jsonData.slice(1).filter((row) => row.some((cell) => cell !== ""));

              processHeadersAndData(headers, data, fileExtension);
            } else {
              alert("The uploaded XLSX file appears to be empty.");
            }
          }
        };

        if (fileExtension === "csv") {
          fileReader.readAsText(file);
        } else if (fileExtension === "xlsx") {
          fileReader.readAsBinaryString(file);
        }
      } else {
        alert("Unsupported file type. Please upload a .csv or .xlsx file.");
      }
    }
  };

  const processHeadersAndData = (headers, data, fileType) => {
    const missingHeaders = findMissingHeaders(headers);

    if (missingHeaders.length > 0) {
      setFileProcessing(false);
      setFileUploading(false);
      setHeadersMissingErrors(missingHeaders);
    } else {
      if (fileType === "xlsx") {
        data = data.map((row) => {
          while (row.length < headers.length) {
            row.push("");
          }
          return row.slice(0, headers.length);
        });
      }

      const fullData = [headers].concat(data);
      uploadCSV(fullData, fileType);
    }
  };

  // upload CSV
  const uploadCSV = (fileData, fileType) => {
    setFileUploading(true);
    let data;

    if (fileType === "csv") {
      const csvContent = fileData.map((row) => (Array.isArray(row) ? row.join(",") : row)).join("\n");
      data = new Blob([csvContent], { type: "text/csv" });
    } else if (fileType === "xlsx") {
      const csvContent = fileData.map((row) => (Array.isArray(row) ? row.join(",") : row)).join("\n");
      data = new Blob([csvContent], { type: "text/csv" });
    } else {
      console.error("Unsupported file type");
      setFileUploading(false);
      return;
    }
    const payload = {
      data: data,
      client_name: selectedClient,
      order_type: selectedOrderType,
      batch_no: batchNo,
      connectionId: connectionId,
      // fileType: fileType,
    };

    Endpoint.Clients.getPresignedURL(payload)
      .then(async (res) => {
        // console.log(res.data);
        // Parse the 'body' field if it's a JSON string
        const presignedData = res.data;

        if (!presignedData || !presignedData.url || !presignedData.fields) {
          throw new Error("Invalid presigned URL response");
        }
        // console.log(presignedData, presignedData.url, presignedData.fields);
        // Step 2: Create FormData and append fields
        const formData = new FormData();
        Object.entries(presignedData.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });

        // Append the file to FormData
        formData.append("file", data);
        try {
          // Step 3: Upload the file to S3
          const uploadResponse = await fetch(presignedData.url, {
            method: "POST",
            body: formData,
          });

          if (uploadResponse.ok) {
            enqueueSnackbar("File uploaded successfully", {
              variant: "success",
            });
            setFileUploading(false);
            setFileProcessing(true);
          } else {
            enqueueSnackbar("File upload failed", {
              variant: "error",
            });
          }
        } catch (uploadError) {
          alertError("Something went wrong during the file upload", uploadError);
        }

        // setFileUploaded(true);
      })
      .catch((err) => {
        alertError("something went wrong", err);
        // setLoading(false);
      });
  };

  const handleGetTemplate = () => {
    downloadTemplate(["loan_number", "street", "city", "state", "zip_code"]);
    // Endpoint.Users.getTemplate()
    //   .then((res) => {
    //     // console.log(res.data);
    //     window.open(res.data.URL, "_blank", "noopener,noreferrer");
    //   })
    //   .catch((err) => {
    //     // console.log("Error!!!!!!!");
    //     console.log(err);
    //     // alertError("Error!!!!!!!", err);
    //   });
  };

  useEffect(() => {
    // console.log(currentStage);
  }, [currentStage]);

  const handleNext = (val) => {
    console.log("currentStage: ", currentStage, " => ", val);
    setCurrentStage(val);
  };

  const handlePrevious = () => {
    if (currentStage > 0) {
      setCurrentStage(currentStage - 1);
    }
  };

  const reset = () => {
    window.location.reload();
  };

  const uploadDataToInputTable = async (inputTableData) => {
    try {
      setUploadingToInputTable(true);
      const updatedTableData = inputTableData.map((row) => {
        return {
          ...row,
          user_name: user.username,
        };
      });

      await Endpoint.BatchUpload.uploadDataToInputTable(updatedTableData);
      enqueueSnackbar("Data uploaded", {
        variant: "success",
      });
      handleFinalLambda({
        batch_number: updatedTableData[0].batch_number,
        client_name: updatedTableData[0].client_name,
        order_type: updatedTableData[0].order_type,
      });
    } catch (error) {
      enqueueSnackbar("Failed to upload data to input_table", {
        variant: "error",
      });
      console.log("Error in uploadDataToInputTable: ", error);
      setUploadingToInputTable(false);
    }
  };

  const handleFinalLambda = async (obj) => {
    try {
      await Endpoint.BatchUpload.finalFunction(obj);
    } catch (error) {
      enqueueSnackbar("Failed to upload data to final_lambda", {
        variant: "error",
      });
      console.log("Error in handleFinalLambda: ", error);
    }
  };

  return (
    <div className={`${globalClasses.root} ${classes.content}`}>
      {!fileUploaded ? (
        <div className={globalClasses.flexColumn}>
          <div
            className={`${classes.updateBatchContent} ${classes.actionsUpdateBatch} ${globalClasses.flexRow}`}
          >
            <div className={globalClasses.flexColumn}>
              <Box className={classes.uploadBatchText}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "30px",
                  }}
                >
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    Client Name
                  </Typography>
                  {clients ? (
                    <span
                      style={{
                        marginLeft: "16px",
                      }}
                    >
                      <Select
                        // value={{ label: values.order_name }}
                        value={clients ? clients.find((option) => option?.value === selectedClient) : ""}
                        onChange={(option) => setSelectedClient(option.value)}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        options={clients}
                        styles={customStylesClientSelect}
                      />
                    </span>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "30px",
                  }}
                >
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    Order Type
                  </Typography>
                  {orderTypes ? (
                    <span
                      style={{
                        marginLeft: "16px",
                      }}
                    >
                      <Select
                        value={
                          orderTypes ? orderTypes.find((option) => option?.value === selectedOrderType) : ""
                        }
                        onChange={(option) => setSelectedOrderType(option.value)}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        options={orderTypes}
                        styles={customStylesClientSelect}
                      />
                    </span>
                  ) : null}
                </div>
                <Typography variant="body1">
                  Upload File for batch processing. For more information follow the{" "}
                  <a href="#" onClick={handleGetTemplate}>
                    Template
                  </a>
                </Typography>
              </Box>

              <div className={globalClasses.flexRow}>
                <input
                  accept=".csv, .xlsx"
                  onChange={handleChooseCSV}
                  id="button-csv-upload"
                  style={{ display: "none" }}
                  type="file"
                />
                <label htmlFor="button-csv-upload">
                  <Tooltip title="Upload CSV">
                    <IconButton component="span" className={classes.actionButton} disabled={fileUploading}>
                      <Typography variant="body2">Upload Batch</Typography>
                    </IconButton>
                  </Tooltip>
                </label>
                {fileUploading && !fileProcessing ? (
                  <>
                    <span className={classes.fileLoadingStyles}>
                      <Loading /> <p style={{ marginLeft: "10px" }}></p>
                    </span>
                  </>
                ) : (
                  <>
                    {!fileUploading && fileProcessing ? (
                      <span className={classes.fileLoadingStyles}>
                        <Loading /> <p style={{ marginLeft: "10px" }}>Processing Addresses...</p>
                      </span>
                    ) : null}
                  </>
                )}
              </div>
              <div className={globalClasses.flexRow}>
                {headersMissingErrors.length > 0 && (
                  <div style={{ color: "red" }}>
                    <p>Missing required headers:</p>
                    <ul>
                      {headersMissingErrors.map((header, index) => (
                        <li key={index}>{header}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.caseContainer}>
          {currentStage == 0 && (
            <UploadBatchTable
              classes={classes}
              customStyles={customStyles}
              data={data}
              setData={setData}
              headers={headers}
              allHeaders={allHeaders}
              handleNext={handleNext}
              setupDataForZillowResponse={setupDataForZillowResponse}
              checkingZillow={checkingZillow}
              setCheckingZillow={setCheckingZillow}
              setSkippingZillow={setSkippingZillow}
              connectionId={connectionId}
            />
          )}
          {currentStage == 1 && (
            <ZillowTable
              classes={classes}
              customStyles={customStyles}
              data={zillowData}
              headers={headers}
              allHeaders={allHeaders}
              handleNext={handleNext}
              reset={reset}
            />
          )}

          {(currentStage == 2 || currentStage == 3) && (
            <DuplicateTable
              skippingZillow={skippingZillow}
              classes={classes}
              customStyles={customStyles}
              data={duplicateCheckDate}
              headers={headers}
              s3Key={s3Key}
              duplicateCheckInProgress={duplicateCheckInProgress}
              currentStage={currentStage}
              uploadingToInputTable={uploadingToInputTable}
              setDuplicateCheckInProgress={setDuplicateCheckInProgress}
              reset={reset}
              setupDataForDuplicateCheckResponse={setupDataForDuplicateCheckResponse}
              uploadDataToInputTable={uploadDataToInputTable}
              setUploadingToInputTable={setUploadingToInputTable}
            />
          )}

          {currentStage == 4 && (
            <OutputTable
              classes={classes}
              data={outputData}
              headers={headers}
              batchNo={batchNo}
              selectedClient={selectedClient}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default UploadBatch;
