import React from "react";
import PropTypes from "prop-types";
import { Input, makeStyles, FormLabel } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  cssLabel: {
    "&$cssFocused": {
      color: red[500],
    },
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: red[500],
    },
  },
  inputIdent: {
    "& .MuiInputBase-input": {
      textIndent: "10px",
    },
  },
  cssFocused: {},
  notchedOutline: {},
}));

const FMUInputField = ({
  label,
  field,
  form: { dirty, touched, errors },
  ...other
}) => {
  const errorText = errors[field.name];
  const classes = useStyles();
  const hasError = dirty && touched[field.name] && errorText !== undefined;
  return (
    <Input
      // InputProps={{
      //   classes: {
      //     root: classes.cssOutlinedInput,
      //     focused: classes.cssFocused,
      //     notchedOutline: classes.notchedOutline,
      //   },
      // }}
      autoCapitalize={"true"}
      className={classes.inputIdent}
      disableUnderline
      placeholder={label}
      error={hasError}
      {...field}
      {...other}
    />
  );
};

FMUInputField.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
  // fullWidth: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
};

// FMUInputField.defaultProps = {
//   fullWidth: "true",
// };
export default FMUInputField;
