import React from "react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Grid } from "@material-ui/core";

const renderOptions = (options) => {
  return options.map((option, key) => (
    // <Grid item xs={6} styles={{ border: "1px solid #EAEAEA" }}>
    <FormControlLabel
      style={{
        marginLeft: options.length == key + 1 ? "20px" : "20px",
        marginRight: "90px",
        width: "100px",
      }}
      key={option}
      value={option}
      control={<Radio color={"default"} />}
      label={option}
    />
    // </Grid>
  ));
};

const FormikRadioGroup = ({
  field,
  form: { touched, errors },
  name,
  options,
  ...props
}) => {
  const fieldName = name || field.name;

  return (
    <React.Fragment>
      <RadioGroup
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
        {...field}
        {...props}
        name={fieldName}
      >
        {/* Here you either map over the props and render radios from them,
         or just render the children if you're using the function as a child*/}
        {options ? renderOptions(options) : null}
      </RadioGroup>

      {touched[fieldName] && errors[fieldName] && (
        <span style={{ color: "red", fontFamily: "sans-serif" }}>
          {errors[fieldName]}
        </span>
      )}
    </React.Fragment>
  );
};

export default FormikRadioGroup;
