// Function which returns a guuid
function generateQuickGuid(type) {
  if (type === "client") {
    return `${Math.floor(100000 + Math.random() * 900000)}`;
  } else if (type === "work_order") {
    return `${Math.floor(1000000 + Math.random() * 9000000)}`;
  } else if (type === "order") {
    return `${Math.floor(10000 + Math.random() * 90000)}`;
  } else {
    return `${Math.floor(1000000000 + Math.random() * 90000000)}`;
  }
}

// Function which returned TaxID mask.
const getTaxIDMask = () => {
  return "99-9999999";
};

// Given array and ID, find object with id.
const getClient = (arr, id) => {
  var result = arr.find((x) => x.client_id === id);
  return result;
};

// Given array and ID, find object with id.
const getOrderType = (arr, id) => {
  var result = arr.find((x) => x.orderID === id);
  return result;
};

// Given String - get acronym.
const convertToAcronym = (str) => {
  const words = str.split(" ");
  let acronym = "";

  words.forEach((word) => {
    acronym += word.charAt(0).toUpperCase();
  });

  return acronym;
};

// Given work orders list - add selected boolean field to each row object
const addSelectedField = (file) => {
  // Adding Selected field to work_orders in file
  if (file?.work_orders?.length > 0) {
    var tempWorkData = file.work_orders.map((item) => ({
      ...item,
      selected: false,
    }));
    var fileTemp = {
      ...file,
      work_orders: tempWorkData,
    };
    return fileTemp;
  } else {
    return file;
  }
};

// Function to get dates in string format.
const getDates = () => {
  const list = [];
  for (let i = 1; i <= 28; i++) {
    if (i % 10 === 1 && i !== 11) {
      //st
      list.push({ value: i, label: `${i}st` });
    } else if (i % 10 === 2 && i !== 12) {
      // nd
      list.push({ value: i, label: `${i}nd` });
    } else if (i % 10 === 3 && i !== 13) {
      // rd
      list.push({ value: i, label: `${i}rd` });
    } else {
      // th
      list.push({ value: i, label: `${i}th` });
    }
  }
  return list;
};

const clients = [
  { value: "SafeGuard", label: "SafeGuard" },
  { value: "Freedom", label: "Freedom" },
  { value: "FNMA", label: "FNMA" },
];

const orderTypes = [
  { value: "Occupancy", label: "Occupancy" },
  { value: "CPR", label: "Complete Profile Report" },
];

const columnPatterns = {
  loan_number: /(^|\W)(appl_)?(loan\s?(number|id|no)?|loan_number)(\W|$)/i, // Extended loanNumber to catch "loan" as well
  street: /(^|\W)(appl_)?(address|street|addr|prop_address|prop_addr)(\W|$)/i, // Including prop_addr
  city: /(^|\W)(appl_)?(city|town)(\W|$)/i,
  state: /(^|\W)(appl_)?(state|province)(\W|$)/i,
  zip_code:
    /(^|\W)(appl_)?(zip\s?(code)?|postal\s?code|postcode|zip|zip_code|applzip)(\W|$)/i, // Added zip_code to pattern
};

const checkColumnExists = (columns, columnKey) => {
  const pattern = columnPatterns[columnKey];
  return columns.some((columnName) => pattern.test(columnName));
};

const requiredBatchUploadHeaders = [
  "loan_number",
  "street",
  "city",
  "state",
  "zip_code",
];

// Function to find missing headers
const findMissingHeaders = (headers) => {
  const missingHeaders = [];
  console.log(requiredBatchUploadHeaders);
  requiredBatchUploadHeaders.forEach((columnKey) => {
    const exists = checkColumnExists(headers, columnKey);
    if (!exists) {
      missingHeaders.push(columnKey);
    }
  });

  return missingHeaders;
};

const downloadTemplate = (requiredHeaders) => {
  // Create a CSV string with the headers
  const csvContent = requiredHeaders.join(",") + "\n" + "\n";

  // Create a Blob from the CSV string
  const blob = new Blob([csvContent], { type: "text/csv" });

  // Create a link and trigger the download
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "template.csv";
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link after download
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const headersMapping = {
  skip_status: "Skip Status",
  property_status: "Property Status",
  status_type: "Status Type",
  latest_order_date: "Latest Order Date",
  file_number: "File Number",
  work_number: "Work Number",
  client_file_number: "Client File Number",
  order_number: "Order Number",
  order_date: "Order Date",
  street: "Street",
  city: "City",
  state: "State",
  zip_code: "Zip Code",
  client_name: "Client Name",
  batch_number: "Batch Number",
  order_type: "Order Type",
  client_code: "Client Code",
  borrower_1: "Borrower 1",
  borrower_2: "Borrower 2",
  notes: "Notes",
  user_name: "Username",
  occupancy_status: "Occupancy Status",
  confidence_score: "Confidence Score",
  owner_name: "Owner Name",
  deceased_flag_1: "Deceased Flag 1",
  deceased_flag_2: "Deceased Flag 2",
  order_complete_date: "Order Complete Date",
  borrower_1_deceased_indicator: "Borrower 1 Deceased Indicator",
  borrower_2_deceased_indicator: "Borrower 2 Deceased Indicator",
  occupant_id: "Occupant ID",
  borrower_1_primary_address: "Borrower 1 Primary Address",
  borrower_1_primary_city: "Borrower 1 Primary City",
  borrower_1_primary_state: "Borrower 1 Primary State",
  borrower_1_primary_zip: "Borrower 1 Primary Zip",
  borrower_1_secondary_address: "Borrower 1 Secondary Address",
  borrower_1_secondary_city: "Borrower 1 Secondary City",
  borrower_1_secondary_state: "Borrower 1 Secondary State",
  borrower_1_secondary_zip: "Borrower 1 Secondary Zip",
  borrower_1_phone_1: "Borrower 1 Phone 1",
  borrower_1_phone_2: "Borrower 1 Phone 2",
  borrower_1_phone_3: "Borrower 1 Phone 3",
  borrower_1_phone_4: "Borrower 1 Phone 4",
  borrower_1_email_1: "Borrower 1 Email 1",
  borrower_1_email_2: "Borrower 1 Email 2",
  borrower_1_email_3: "Borrower 1 Email 3",
  borrower_2_primary_address: "Borrower 2 Primary Address",
  borrower_2_primary_city: "Borrower 2 Primary City",
  borrower_2_primary_state: "Borrower 2 Primary State",
  borrower_2_primary_zip: "Borrower 2 Primary Zip",
  borrower_2_secondary_address: "Borrower 2 Secondary Address",
  borrower_2_secondary_city: "Borrower 2 Secondary City",
  borrower_2_secondary_state: "Borrower 2 Secondary State",
  borrower_2_secondary_zip: "Borrower 2 Secondary Zip",
  borrower_2_phone_1: "Borrower 2 Phone 1",
  borrower_2_phone_2: "Borrower 2 Phone 2",
  borrower_2_phone_3: "Borrower 2 Phone 3",
  borrower_2_phone_4: "Borrower 2 Phone 4",
  borrower_2_email_1: "Borrower 2 Email 1",
  borrower_2_email_2: "Borrower 2 Email 2",
  borrower_2_email_3: "Borrower 2 Email 3",
  occupant_1_first_name: "Occupant 1 First Name",
  occupant_1_last_name: "Occupant 1 Last Name",
  occupant_1_phone_1: "Occupant 1 Phone 1",
  occupant_1_phone_2: "Occupant 1 Phone 2",
  occupant_1_phone_3: "Occupant 1 Phone 3",
  occupant_2_first_name: "Occupant 2 First Name",
  occupant_2_last_name: "Occupant 2 Last Name",
  occupant_2_phone_1: "Occupant 2 Phone 1",
  occupant_2_phone_2: "Occupant 2 Phone 2",
  occupant_2_phone_3: "Occupant 2 Phone 3",
  amount: "Amount",
};

const getHeaderLabel = (header) => {
  return headersMapping[header] || header;
};

export {
  getOrderType,
  generateQuickGuid,
  getTaxIDMask,
  getClient,
  convertToAcronym,
  getDates,
  addSelectedField,
  clients,
  orderTypes,
  requiredBatchUploadHeaders,
  findMissingHeaders,
  downloadTemplate,
  getHeaderLabel,
};
