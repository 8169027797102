import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  Input,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  Divider,
  TextareaAutosize,
  Tooltip,
} from "@material-ui/core";
import Select from "react-select";
import {
  useFormikContext,
  getIn,
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
} from "formik";
import { useSnackbar } from "notistack";
import InputMask from "react-input-mask";
import React, { useRef, useState, useEffect, useContext } from "react";

import { array, date, number, object, string, boolean } from "yup";
import * as Yup from "yup";
import Endpoint from "../../endpoint";
// import OpenDialogIcon from "../../icons/OpenDialog";
import { states } from "../../helpers/states";
import ClientField from "../../components/shared/InputField";
import FMUInputField from "../../components/shared/FormIkMUFormComponent/FMUInputField";
// import ContactsDialog from "./ContactsDialog";
import {
  convertToAcronym,
  generateQuickGuid,
  getTaxIDMask,
} from "../../helpers/helper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MaskField from "../../components/shared/MaskField";

import { daysOfTheWeek } from "../../helpers/daysOfTheWeek";
import { getDates } from "../../helpers/helper";
// import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIosNewIcon";
import InvoiceField from "../../components/shared/InvoiceFields/InvoiceField";
import FormError from "../../components/FormError";

import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";

import fileData from "./fileHelperData.json";

import FileDetail from "./FileDetail";
import WorkOrderTable from "./WorkOrderTable";
import { AuthContext } from "../../auth/AuthWrapper";
import { useParams } from "react-router-dom";
import Loading from "../Loading";

import { DASHBOARD } from "../../types/routeConstants";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  caseContainer: {
    // width: "928px",
    // paddingTop: "calc(10vh + 100px)",
    width: "98%",
    paddingLeft: "2%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  topBar: {
    height: "50px",
    // backgroundColor: "#f8f8f8",
    padding: "0 2% 0 2%",
    display: "flex",
    alignItems: "center",
    // marginTop: "8vh",
  },
  heading: {
    fontSize: "1.2em",
    color: "#147CFF",
    //fontWeight: "bold",
  },
  postButton: {
    backgroundColor: theme.palette.primary.main,
    // fontFamily: "poppins",
    //fontWeight: "bold",
    fontSize: ".8em",
    color: "#ffffff",
    textTransform: "inherit",
    // borderRadius: "5px",
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    textAlign: "center",

    border: "1px solid #3C72D9",
    // height: "30px",

    width: "120px",
    // border: "1px solid #3C72D9",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e7e7e7",
      color: "white",
    },
  },
}));

const customStyles = {
  textAlign: "end",
  control: (base, state) => ({
    ...base,
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
};

const fileDetails = [
  {
    title: "Client Name",
    key: "client_name",
  },
  {
    title: "Client Loan #",
    key: "client_loan_no",
  },
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Address",
    key: "",
  },
  {
    title: "Master Servicer Name",
    key: "master_servicer_name",
  },
  {
    title: "Master Servicer Loan #",
    key: "master_servicer_loan_no",
  },
  {
    title: "Servicer Name",
    key: "servicer_name",
  },
  {
    title: "Servicer Loan #",
    key: "servicer_loan_no",
  },
  {
    title: "Investor Name",
    key: "investor_name",
  },
  {
    title: "Investor Loan #",
    key: "investor_loan_no",
  },
  {
    title: "Loan Category",
    key: "loan_category",
  },
  {
    title: "Loan Status",
    key: "loan_status",
  },
];

/**

A React functional component for rendering the file
It runs the FileDetail and WorkOrderTable components.

*/
function ViewFile({ initialState }) {
  const classes = useStyle();
  const navigate = useNavigate();
  const { id } = useParams();
  const { orderTypes } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();

  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [orderTypesLabel, setOrderTypeslabel] = useState(null);

  // Function which takes ordertypes and adds a chiplabel to it for acronyms
  const setOrderTypeChipLabel = () => {
    var tempArray = [];
    orderTypes.map((orderType) => {
      tempArray.push({
        ...orderType,
        chipLabel: convertToAcronym(orderType.ordertype),
      });
    });
    setOrderTypeslabel(tempArray);
  };

  // TODO Function to set file given id - currently demo data is used, will be updated with API endpoint
  const getFile = () => {
    const file = fileData.find((file) => file.id === id);

    setFile(file);
  };

  useEffect(() => {
    setOrderTypeChipLabel();
    getFile();
  }, [orderTypes || id]);

  useEffect(() => {
    if (file) {
      setLoading(false);
    }
  }, [file]);

  // Handle Submit Function for adding/updating a client
  const handleSubmit = (v) => {
    let obj;
    console.log(v);
    enqueueSnackbar("File changes saved!", {
      variant: "success",
    });
  };

  if (loading) return <Loading pageLoading={true} left={"60%"} />;

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",

          paddingTop: "calc(10vh + 50px)",
          marginBottom: "20px",
        }}
      >
        <div className={classes.topBar}>
          <span className={classes.heading}>{"File Details "}</span>
        </div>
        <Button
          className={classes.postButton}
          onClick={(e) => {
            // handleTabChange(e, 1);
            navigate(DASHBOARD.path);
          }}
        >
          Upload New
        </Button>
      </div>
      <div className={classes.caseContainer}>
        <Formik
          enableReinitialize={true}
          initialValues={file ? file : null}
          // validationSchema={validateSchema}
          validateOnBlur={true}
          innerRef={formRef}
          validateOnChange
          onSubmit={(e) => {
            // console.log(e);
            handleSubmit(e);
          }}
          render={({
            isSubmitting,
            dirty,
            touched,
            values,
            errors,
            setFieldValue,
          }) => (
            <Form>
              <FileDetail values={values} setFieldValue={setFieldValue} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "20px",
                  marginTop: "20px",
                  marginRight: "2%",
                }}
              >
                <Button className={classes.postButton} type={"submit"}>
                  Save Changes
                </Button>
              </div>
              <WorkOrderTable
                workOrders={values?.work_orders}
                setFieldValue={setFieldValue}
                orderTypes={orderTypesLabel}
              />
            </Form>
          )}
        />
      </div>
    </>
  );
}

export default ViewFile;
