export const initialState = {
  client_id: "",
  client_code: "",
  sub_account: "",
  client_name: "",
  street_address: "",
  city: "",
  zip_code: "",
  state: "",
  attention: "",
  tax_id: "",
  start_date: "",
  end_date: "",
  fee_lines: [],
};
