import React from "react";
import { Input, makeStyles } from "@material-ui/core";
import { Field, ErrorMessage } from "formik";
import FormError from "../../components/FormError";
import { states } from "../../helpers/states";
import FMUInputField from "../../components/shared/FormIkMUFormComponent/FMUInputField";
import { Typography } from "@material-ui/core";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-input": {
      fontSize: "1rem",
      textTransform: "capitalize",
      textAlign: "end",
    },
    height: "30px",
    paddingLeft: "3px",
    // borderBottom: "1px solid #BABABA",
    backgroundColor: "white",
  },
  emailInput: {
    // width: "95%",
    "& .MuiInputBase-input": {
      fontSize: "1rem",
      // textIndent: "10px",
      textAlign: "end",
    },
    height: "30px",
    paddingLeft: "3px",

    // borderRadius: "10px",
    // border: "1px solid #9dc8d2",
    // backgroundColor: "#f8f8f8",
    // borderBottom: "1px solid #BABABA",
    backgroundColor: "white",
  },
  dateInput: {
    // width: "95%",
    "& .MuiInputBase-input": {
      // textIndent: "10px",
      fontSize: "1rem",
      textAlign: "end",
    },
    height: "30px",
    paddingLeft: "3px",
    // borderBottom: "1px solid #BABABA",
  },
  textAreaInput: {
    // width: "95%",
    "& .MuiInputBase-input": {
      // textIndent: "10px",
      fontSize: "1rem",
      textAlign: "left",
    },
    // height: "100px",

    paddingLeft: "3px",
    // border: "1px solid #BABABA",
  },
  error: {
    fontSize: "12px",
    color: "red",
    // marginTop: "0.25rem",
    paddingLeft: "9%",
    display: "flex",
  },
}));
const customStyles = {
  textAlign: "end",
  // option: (styles) => ({ ...styles, width: "100px" }),
  menu: (styles) => ({ ...styles, width: "150px" }),
  valueContainer: (provided, state) => ({
    ...provided,
    // width: "20px",
    // marginBottom: "10px",
    // paddingBottom: "1px",
    // paddingTop: "10px",
    // padding: "6px 0px 7px",
    // paddingRight:
  }),
  input: (styles) => ({ ...styles, margin: "0px" }),
  control: (base, state) => ({
    ...base,
    width: "50px",
    maxHeight: "20px",
    minHeight: "20px",
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: null,
    // Removes weird border around container
    boxShadow: null,
  }),
};

/**

A React functional component for rendering the Address fields - Street, City, State, ZipCode in a table. 

*/
function AddressField({
  label,
  name,
  type,
  disabled,
  placeHolder,
  isEmail,
  values,
  required,
  input,
  customOnChange,
  address_type,
  setFieldValue,
}) {
  const handleOnChange = (e) => {
    return customOnChange(e);
  };
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          width:
            address_type == "street"
              ? "100px"
              : address_type == "city"
              ? "100px"
              : address_type == "state"
              ? "40px"
              : "60px",
        }}
        // style={{ width: `${width} !important` }}
      >
        {type == "text" ? (
          <Field
            name={name}
            type={type}
            disabled={disabled}
            fullwidth={true}
            // style={style}
            disableUnderline={true}
            className={
              isEmail
                ? classes.emailInput
                : type === "date"
                ? classes.dateInput
                : type === "TextArea"
                ? classes.textAreaInput
                : classes.input
            }
            component={FMUInputField}
            label={placeHolder ? placeHolder : label}
          />
        ) : (
          <span
            style={{ marginBottom: "10px", textAlign: "end", height: "20px" }}
          >
            <Select
              // className={classes.input}

              value={{ label: values?.state }}
              onChange={(e) => {
                setFieldValue("state", e.value, false);
              }}
              options={states}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              getOptionLabel={(option) => option["label"]}
              getOptionValue={(option) => option["value"]}
              placeholder={placeHolder}
            />
          </span>
        )}
      </div>

      <div>
        <ErrorMessage
          name={name}
          component={FormError}
          className={classes.error}
        />
      </div>
    </>
  );
}

export default AddressField;
