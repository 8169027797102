import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import "./UploadBatchTable.css";

import clsx from "clsx";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";

import Select from "react-select";
import { Button, Tooltip } from "@material-ui/core";

import globalStyles from "../../../helpers/GlobalStyles";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportIconProblem from "@mui/icons-material/ReportProblem";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import FMUInputField from "../../../components/shared/FormIkMUFormComponent/FMUInputField";
import UploadBatchField from "../../../components/shared/UploadBatchField";
import { states } from "../../../helpers/states";

import DuplicateCheck from "../../../dialogs/DuplicateCheck";
import Endpoint from "../../../endpoint";
import { getHeaderLabel } from "../../../helpers/helper";

/**

A React functional component for rendering the Upload Batch page

*/
function ZillowTable({ classes, customStyles, data, headers, allHeaders, handleNext, reset }) {
  const globalClasses = globalStyles();

  const [showProceedDialog, setShowProceedDialog] = useState(false);
  const [type, setType] = useState(null);

  const handleCloseProceedDialog = (type) => {
    setShowProceedDialog(false);
    if (type == 1) {
      reset();
    } else {
      handleNext(type);
    }
  };
  const handleSubmit = (values, type) => {
    // setCheckingZillow(true);
    console.log("Form data submitted:", values);
    console.log(type, "3");
    handleNext(type);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ data }}
        // validationSchema={validationSchema}
        onSubmit={(values) => {}}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray
              name="data" // Use data directly
              render={(arrayHelpers) => (
                <>
                  <div style={{}}>
                    <table className={classes.clientTable}>
                      <thead className={globalClasses.tableHead}>
                        <tr className={globalClasses.clientthtr}>
                          <>
                            <th className={classes.tableTh} style={{ width: "40px" }}>
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  values.data.forEach((_, index) => {
                                    setFieldValue(`data.${index}.skip_status`, !isChecked);
                                  });
                                }}
                              />
                            </th>
                            {headers.map((header) => {
                              if (header === "skip_status") return null;
                              return (
                                <th className={classes.tableTh} key={header}>
                                  {getHeaderLabel(header)}
                                </th>
                              );
                            })}
                            {/* <th
                              className={classes.tableTh}
                              style={{ paddingRight: "30px" }}
                            >
                              Options
                            </th> */}
                          </>
                        </tr>
                      </thead>
                      <tbody>
                        {values.data.map((row, index) => (
                          <tr
                            style={{
                              fontSize: "1em",
                              color: "#4e4e4e",
                            }}
                            key={index}
                          >
                            <td className={classes.tableTd} style={{ textAlign: "center" }}>
                              <div style={{ textAlign: "center" }}>
                                {values.data[index]["skip_status"] ? (
                                  <Tooltip title="Skip Address">
                                    <CheckBoxOutlineBlankIcon
                                      className={classes.tableIconsUnskip}
                                      onClick={() =>
                                        setFieldValue(
                                          `data.${index}.${"skip_status"}`,
                                          !values.data[index]["skip_status"],
                                          false
                                        )
                                      }
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Unskip Address">
                                    <CheckBoxIcon
                                      className={classes.tableIconsSkip}
                                      onClick={() =>
                                        setFieldValue(
                                          `data.${index}.${"skip_status"}`,
                                          !values.data[index]["skip_status"],
                                          false
                                        )
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </td>
                            {headers.map((header) => {
                              if (header === "skip_status") return null;
                              return (
                                <td className={classes.tableTd} key={header}>
                                  {header == "state" || header == "new_state" ? (
                                    <>
                                      <div className={classes.fieldContainer}>
                                        <div className={classes.parentChild}>
                                          <div className={classes.rowContainer}>
                                            <Select
                                              value={{
                                                label: values.data[index][header],
                                              }}
                                              onChange={(e) => {
                                                setFieldValue(`data.${index}.${header}`, e.value, false);
                                              }}
                                              options={states}
                                              styles={customStyles}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <>
                                        <div className={classes.fieldContainer}>
                                          {" "}
                                          <UploadBatchField
                                            type={"text"}
                                            name={`data.${index}.${header}`}
                                            setFieldValue={setFieldValue}
                                            value={values.data[index][header]}
                                          />
                                          <ErrorMessage name={`data.${index}.${header}`} />
                                        </div>
                                      </>
                                    </>
                                  )}
                                </td>
                              );
                            })}{" "}
                            <td className={classes.tableTd} style={{}}>
                              <div style={{ textAlign: "center" }}>
                                {values.data[index]["notes"] && (
                                  <Tooltip title="Missing Units">
                                    <ReportIconProblem className={classes.tableIconsAlert} />
                                  </Tooltip>
                                )}

                                {/* <Tooltip title="Delete">
                                  <DeleteIcon
                                    className={classes.tableIconsDelete}
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                </Tooltip> */}
                              </div>
                            </td>
                            {/* <td>
                                  <button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    Delete
                                  </button>
                                </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className={globalClasses.flexRow} style={{ alignContent: "center" }}>
                    {/* <Tooltip title="Add New Row">
                      <Button
                        className={classes.redoButton}
                        onClick={() => {
                          arrayHelpers.push(
                            allHeaders.reduce((acc, header) => {
                              acc[header] = header === "Checkbox" ? false : "";
                              return acc;
                            }, {})
                          );
                        }}
                      >
                        <AddIcon />
                      </Button>
                    </Tooltip> */}
                    <Tooltip title="End Batch Upload Process">
                      <Button
                        className={`${classes.actionButton} `}
                        style={{ marginLeft: "1%" }}
                        onClick={() => {
                          setType(1);
                          setShowProceedDialog(true);
                        }}
                      >
                        End
                      </Button>
                    </Tooltip>
                    <Tooltip title="Proceed to Next Step">
                      <Button
                        className={`${classes.actionButton} `}
                        style={{ marginLeft: "1%" }}
                        onClick={() => {
                          setType(3);
                          setShowProceedDialog(true);
                        }}
                      >
                        Proceed
                      </Button>
                    </Tooltip>
                  </div>
                  {type == 1 && (
                    <DuplicateCheck
                      showUploadBatchDialog={showProceedDialog}
                      setShowUploadBatchDialog={setShowProceedDialog}
                      handleCloseUploadBatchDialog={handleCloseProceedDialog}
                      msg={"Are you sure you want to end the batch upload process?"}
                      type={type}
                    />
                  )}
                  {type == 3 && (
                    <DuplicateCheck
                      showUploadBatchDialog={showProceedDialog}
                      handleCloseUploadBatchDialog={handleCloseProceedDialog}
                      setShowUploadBatchDialog={setShowProceedDialog}
                      handleSubmit={handleSubmit}
                      msg={"Are you sure you want to Proceed?"}
                      type={type}
                      values={values}
                    />
                  )}
                </>
              )}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ZillowTable;
