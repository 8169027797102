import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import Select from "react-select";
import { Button } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import { Chip, InputBase, Box } from "@material-ui/core";
import { Close as CloseIcon } from "@mui/icons-material";
import moment from "moment";
import Endpoint from "../../endpoint";
import DataTable from "./DataTable";
import Loading from "../Loading";
import { getHeaderLabel } from "../../helpers/helper";
import ColumnNames from "../../constants/ColumnNames";
import downloadInvoices from "../../helpers/downloadInvoices";

const sampleDate = {
  sql_result: [
    {
      file_number: "A0955564405",
      work_number: "C9677456760",
      client_file_number: "70980894",
      order_number: "347447549",
      order_date: "01-03-2025",
      street: "3557 COLLIER DR NW ",
      city: "ATLANTA",
      state: "GA",
      zip_code: "30331",
      skip_status: 0,
      client_name: "SafeGuard",
      batch_number: 1052033932,
      order_type: "Occupancy",
      borrower_1: "",
      borrower_2: "",
      notes: "",
      client_code: null,
      user_name: "lbrana",
      occupancy_status: "Occupied",
      confidence_score: "0.92",
      order_complete_date: "2025-01-03",
    },
  ],
  client_details: [
    {
      city: "Washington",
      end_date: "",
      client_id: "275514",
      client_code: "12345",
      sub_account: "388",
      start_date: "2023-05-04",
      zip_code: "21634",
      tax_id: "34-2345434",
      client_name: "Usman Khalid",
      street_address: "Testing Address",
      fee_lines: [
        {
          end_date: "2023-05-02",
          amount: "20",
          orderID: 1.0,
          start_date: "2023-05-02",
        },
        {
          end_date: "",
          amount: "20",
          orderID: 2.0,
          start_date: "2023-05-02",
        },
      ],
      attention: "No",
      state: "MD",
    },
  ],
};

const useStyles = makeStyles(
  (theme) => ({
    input: {
      height: "40px",
      width: "150px",
      borderRadius: "8px",
      outline: "1px solid black",
      border: "none",
      padding: "0 10px",
      "&::placeholder": {
        fontSize: "16px",
      },
      "&:hover": {
        outline: "2px solid #b3b3b3",
      },
      "&:focus": {
        outline: "2px solid #2563eb",
      },
    },

    dateInput: {
      width: "130px",
      height: "40px",
      backgroundColor: "white",
      outline: "1px solid black",
      borderRadius: "8px",
      padding: "0 10px",
      border: "none",
      fontSize: "16px",
      "&:hover": {
        outline: "2px solid #b3b3b3",
      },
      "&:focus": {
        outline: "2px solid #2563eb",
      },
    },
    invoiceFormContainer: {
      width: "100%",
      paddingTop: "calc(3vh + 60px)",
      paddingLeft: "5vh",
      paddingRight: "2vh",
      marginRight: "50vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
  }),
  { index: 1 }
);

const columnFilterOptions = [
  { label: "In a List", value: "in_a_list" },
  { label: "Blank", value: "blank" },
  { label: "Equal to", value: "equal_to" },
  { label: "Greater Than", value: "greater_than" },
  { label: "Greater Than or Equal to", value: "greater_than_or_equal_to" },
  { label: "Less Than", value: "less_than" },
  { label: "Less Than or Equal to", value: "less_than_or_equal_to" },
  { label: "From [] To []", value: "from_to" },
  { label: "Contains", value: "contains" },
  { label: "Last", value: "last" },
];

const lastDropdownOptions = [
  { label: "Last n days", value: "days" },
  { label: "Last n months", value: "months" },
  { label: "Last n quarters", value: "quarters" },
  { label: "Last n years", value: "years" },
];

const initialRowValue = {
  id: 1,
  operator: "",
  columnName: "",
  columnFilter: "",
  columnFilterNotOperator: false,
  textValue: "",
  multiTextValues: [],
  fromDate: "",
  toDate: "",
  selectedValueFromLastDropdown: "",
  lastNDays: "",
  lastNMonths: "",
  lastNYears: "",
  lastNQuarters: "",
  LastNIncludeCurrent: false,
};

function Invoices() {
  const classes = useStyles();
  const [columnsOrder, setColumnsOrder] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([initialRowValue]);
  const [clientDetails, setClientDetails] = useState();
  const [query, setquery] = useState("");

  const handleColumnOrderChange = (columnName) => {
    if (columnsOrder.includes(columnName)) return;
    setColumnsOrder([...columnsOrder, columnName]);
  };

  const buildQuery = () => {
    let whereClauses = [];
    rows.forEach((cond) => {
      let clause = "";

      if (cond.columnFilterNotOperator) {
        clause += "NOT ";
      }

      switch (cond.columnFilter) {
        case "equal_to":
          clause += `${cond.columnName} = '${cond.textValue}'`;
          break;
        case "greater_than":
          clause += `${cond.columnName} > '${cond.textValue}'`;
          break;
        case "greater_than_or_equal_to":
          clause += `${cond.columnName} >= '${cond.textValue}'`;
          break;
        case "less_than":
          clause += `${cond.columnName} < '${cond.textValue}'`;
          break;
        case "less_than_or_equal_to":
          clause += `${cond.columnName} <= '${cond.textValue}'`;
          break;
        case "from_to":
          clause += `${cond.columnName} BETWEEN '${cond.fromDate}' AND '${cond.toDate}'`;
          break;
        case "contains":
          clause += `${cond.columnName} LIKE '%${cond.textValue}%'`;
          break;
        case "blank":
          clause += `${cond.columnName} IS NULL OR ${cond.columnName} = ''`;
          break;
        case "in_a_list":
          if (Array.isArray(cond.multiTextValues) && cond.multiTextValues.length > 0) {
            const formattedValues = cond.multiTextValues.map((value) => `'${value}'`).join(", ");
            clause += `${cond.columnName} IN (${formattedValues})`;
          } else {
            console.warn("No values provided for 'In a List' condition.");
          }
          break;
        case "last":
          const unit = cond.selectedValueFromLastDropdown;
          const value = getLastValue(cond.selectedValueFromLastDropdown, cond);
          const includeCurrent = cond.LastNIncludeCurrent;

          if (unit && value) {
            switch (unit) {
              case "days":
                if (includeCurrent) {
                  clause += `${cond.columnName} >= DATE_SUB(CURDATE(), INTERVAL ${value - 1} DAY)`;
                } else {
                  clause += `${cond.columnName} BETWEEN DATE_SUB(CURDATE(), INTERVAL ${value} DAY) AND DATE_SUB(CURDATE(), INTERVAL 1 DAY)`;
                }
                break;

              case "months":
                if (includeCurrent) {
                  clause += `${cond.columnName} >= DATE_SUB(CURDATE(), INTERVAL ${value - 1} MONTH)`;
                } else {
                  const startOfLastMonths = `DATE_SUB(DATE_FORMAT(CURDATE(), '%Y-%m-01'), INTERVAL ${value} MONTH)`;
                  const endOfLastMonth = `LAST_DAY(DATE_SUB(CURDATE(), INTERVAL 1 MONTH))`;
                  clause += `${cond.columnName} BETWEEN ${startOfLastMonths} AND ${endOfLastMonth}`;
                }
                break;

              case "quarters":
                if (includeCurrent) {
                  const startOfCurrentQuarter = `DATE_FORMAT(DATE_SUB(CURDATE(), INTERVAL MOD(MONTH(CURDATE()) - 1, 3) MONTH), '%Y-%m-01')`;
                  clause += `${cond.columnName} >= DATE_SUB(${startOfCurrentQuarter}, INTERVAL ${
                    (value - 1) * 3
                  } MONTH)`;
                } else {
                  const startOfLastQuarters = `DATE_FORMAT(DATE_SUB(CURDATE(), INTERVAL ${
                    value * 3 + "+ MOD(MONTH(CURDATE()) - 1, 3)"
                  } MONTH), '%Y-%m-01')`;
                  const endOfLastQuarter = `LAST_DAY(DATE_SUB(CURDATE(), INTERVAL MOD(MONTH(CURDATE()) - 1, 3) MONTH + 1 QUARTER))`;
                  clause += `${cond.columnName} BETWEEN ${startOfLastQuarters} AND ${endOfLastQuarter}`;
                }
                break;

              case "years":
                if (includeCurrent) {
                  clause += `${cond.columnName} >= DATE_SUB(CURDATE(), INTERVAL ${value - 1} YEAR)`;
                } else {
                  const startOfLastYears = `DATE_SUB(DATE_FORMAT(CURDATE(), '%Y-01-01'), INTERVAL ${value} YEAR)`;
                  const endOfLastYear = `DATE_SUB(CURDATE(), INTERVAL 1 YEAR)`;
                  clause += `${cond.columnName} BETWEEN ${startOfLastYears} AND ${endOfLastYear}`;
                }
                break;

              default:
                console.warn("Unsupported time unit.");
                break;
            }
          } else {
            console.warn("Missing 'lastOption' or 'lastValue' for 'last' operator.");
          }
          break;
        default:
          console.warn(`Unsupported operator: ${cond.operator}`);
          break;
      }

      whereClauses.push({ logic: cond.operator, clause });
    });

    let query = "SELECT * FROM unified_view WHERE ";
    query += whereClauses
      .map((item, index) => (index === 0 ? item.clause : `${item.logic} ${item.clause}`))
      .join(" ");

    setquery(query);
    return query;
  };
  const getLastValue = (lastDropDownOption, row) => {
    switch (lastDropDownOption) {
      case "days":
        return row.lastNDays;
      case "months":
        return row.lastNMonths;
      case "quarters":
        return row.lastNQuarters;
      case "years":
        return row.lastNYears;
      default:
        break;
    }
  };
  const handleFieldChange = (id, field, value) => {
    setRows((prevRows) => prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row)));
  };

  const addRow = () => {
    const newRow = {
      ...initialRowValue,
      id: rows.length + 1,
      operator: "AND",
    };
    setRows((prevRows) => [...prevRows, newRow]);
  };

  const removeRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  const renderFieldBasedOnLastValue = (row) => {
    const { selectedValueFromLastDropdown: value, id } = row;
    let placeHolder = "";
    let fieldName = "";

    if (value === "days") {
      placeHolder = "Enter Days";
      fieldName = "lastNDays";
    } else if (value === "months") {
      placeHolder = "Enter Months";
      fieldName = "lastNMonths";
    } else if (value === "years") {
      placeHolder = "Enter Years";
      fieldName = "lastNYears";
    } else if (value === "quarters") {
      placeHolder = "Enter Quarters";
      fieldName = "lastNQuarters";
    }

    return (
      <div style={{ display: "flex", alignItems: "center", marginLeft: "12px" }}>
        <span style={{ width: "159px" }}>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            <div style={{ position: "relative" }}>
              <input
                type="checkbox"
                checked={row.LastNIncludeCurrent}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  handleFieldChange(row.id, "LastNIncludeCurrent", isChecked);
                }}
                style={{
                  position: "absolute",
                  opacity: 0,
                  cursor: "pointer",
                  width: "12px",
                  height: "12px",
                }}
              />
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  border: "2px solid #1976d2",
                  borderRadius: "50%",
                  padding: "2px",
                  background: "white",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    background: row.LastNIncludeCurrent ? "#1976d2" : "transparent",
                    transition: "background-color 0.2s",
                  }}
                />
              </div>
            </div>
            <span
              style={{
                color: "#666",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              Include Current
            </span>
          </label>
        </span>
        <input
          type="text"
          value={row[fieldName]}
          onChange={(e) => {
            handleFieldChange(id, fieldName, e.target.value);
          }}
          className={classes.input}
          placeholder={placeHolder}
        />
      </div>
    );
  };

  const getFormattedDate = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");

    return formattedDate;
  };

  const renderDynamicField = (row) => {
    const { columnFilter, id, selectedValueFromLastDropdown, multiTextValues, textValue, fromDate, toDate } =
      row;

    if (columnFilter === "from_to") {
      return (
        <div style={{ display: "flex", gap: 5 }}>
          <input
            value={fromDate}
            type="text"
            onChange={(e) => {
              handleFieldChange(id, "fromDate", getFormattedDate(e.target.value));
            }}
            className={classes.dateInput}
            placeholder="From"
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => {
              if (!e.target.value) {
                e.target.type = "text";
              }
            }}
          />

          <input
            value={toDate}
            type="text"
            onChange={(e) => {
              handleFieldChange(id, "toDate", getFormattedDate(e.target.value));
            }}
            className={classes.dateInput}
            placeholder="To"
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => {
              if (!e.target.value) {
                e.target.type = "text";
              }
            }}
          />
        </div>
      );
    } else if (columnFilter === "last") {
      return (
        <div style={{ display: "flex", gap: 10 }}>
          <Select
            onChange={(option) => handleFieldChange(id, "selectedValueFromLastDropdown", option.value)}
            value={lastDropdownOptions.find((o) => o.value === selectedValueFromLastDropdown)}
            defaultValue={{ label: "Select", value: "" }}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            options={lastDropdownOptions}
            styles={customStylesClientSelect}
          />
          {selectedValueFromLastDropdown && renderFieldBasedOnLastValue(row)}
        </div>
      );
    } else if (columnFilter === "in_a_list") {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            height: "38px",
            border: "1px solid black",
            backgroundColor: "white",
            padding: "0 10px",
          }}
        >
          {multiTextValues.map((value, index) => (
            <Chip
              key={index}
              label={value}
              onDelete={() => {
                handleFieldChange(
                  id,
                  "multiTextValues",
                  multiTextValues.filter((v) => v !== value)
                );
              }}
              style={{
                margin: "4px 4px 4px 0",
                backgroundColor: "#2563eb",
                color: "white",
                fontSize: "16px",
              }}
              size="small"
              deleteIcon={<CloseIcon style={{ color: "white", fontSize: "18px" }} />}
            />
          ))}

          <InputBase
            placeholder="Enter values"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value.trim()) {
                handleFieldChange(id, "multiTextValues", [...multiTextValues, e.target.value]);
                e.target.value = "";
              }
            }}
            style={{
              minWidth: "120px",
            }}
          />
        </Box>
      );
    } else if (columnFilter === "blank") {
      return null;
    } else if (columnFilter) {
      return (
        <input
          value={textValue}
          onChange={(e) => {
            handleFieldChange(row.id, "textValue", e.target.value);
          }}
          className={classes.input}
          placeholder="Enter Text"
        />
      );
    }
  };

  useEffect(() => {
    buildQuery();
  }, [rows]);

  useEffect(() => {
    setHeaders(columnsOrder);
  }, [columnsOrder]);

  useEffect(() => {
    const newQuery = buildQuery();
    setquery(newQuery);
  }, [rows]);

  const fetchRecords = async () => {
    try {
      setApiLoading(true);
      const res = await Endpoint.Dashboard.getDashboardData({ query });
      const resData = JSON.parse(res.data.body).result || [];
      setReportData(resData);
      if (headers.length <= 0) setHeaders(Object.keys(resData[0]) || []);
    } catch (error) {
      console.log(error);
    } finally {
      setApiLoading(false);
    }
  };

  const generateId = (prefix) => {
    const randomNumber = Math.floor(100000 + Math.random() * 900000);

    const id = `${prefix}${randomNumber}`;

    return id;
  };

  const getClientAmount = (fees) => {
    const matchingObject = fees.find((item) => item.end_date === "");
    return matchingObject ? matchingObject.amount : null;
  };
  function mapDataBasedOnHeaders(headers, data) {
    return data.map((item) => {
      const mappedItem = {};
      headers.forEach((header) => {
        mappedItem[header] = item[header] || null;
      });
      return mappedItem;
    });
  }

  const mapDataForAccountWorksheet = (client_details, sql_data, clientCode) => {
    const worksheetHeaders = [
      "Customer",
      "Invoice Date",
      "QC #",
      "Invoice Comment",
      "State",
      "Line Amt",
      "Item ID",
      "Customer ID",
      "Branch ID",
      "Subaccount",
      "Referral/Event Date",
    ];

    const accountingData = sql_data.map((obj) => {
      return [
        `${client_details.client_name}/OccuTrack`,
        moment().format("M/D/YY"),
        obj.order_number,
        clientCode,
        obj.state,
        obj.amount.toFixed(2),
        "OCCUTRACK",
        client_details.client_id,
        "CMC",
        `${client_details.sub_account}-${obj.state}`,
        moment(obj.order_date).format("M/D/YY"),
      ];
    });
    return { headers: worksheetHeaders, data: accountingData };
  };
  const fetchInvoices = async () => {
    try {
      setApiLoading(true);
      setReportData([]);
      const res = await Endpoint.Dashboard.getInvoices({ query });
      const resData = JSON.parse(res.data.body) || [];

      const { client_details, sql_result } = resData;

      const amount = parseInt(getClientAmount(client_details[0].fee_lines));
      const clientCode = generateId(client_details[0].client_code);
      const totalAmount = amount * sql_result.length;

      const sqlData = sql_result.map((d) => {
        return { ...d, amount };
      });
      const accountWorkSheetData = mapDataForAccountWorksheet(client_details[0], sqlData, clientCode);
      setReportData(sqlData);
      let invoiceData = [];

      setClientDetails(client_details[0]);
      let invoiceHeaders = [];
      if (headers.length <= 0) {
        const tempHeaders = Object.keys(sqlData[0]) || [];
        invoiceData = sqlData.map((obj) => Object.values(obj));
        setHeaders(tempHeaders);
        invoiceHeaders = tempHeaders;
      } else {
        invoiceHeaders = [...headers, "amount"];
        invoiceData = mapDataBasedOnHeaders(invoiceHeaders, sqlData).map((obj) => Object.values(obj));
      }
      if (client_details && client_details.length > 0 && sqlData.length > 0 && invoiceHeaders.length > 0)
        downloadInvoices(
          client_details[0],
          invoiceData,
          invoiceHeaders,
          totalAmount,
          accountWorkSheetData,
          clientCode
        );
    } catch (error) {
      console.log(error);
    } finally {
      setApiLoading(false);
    }
  };

  const LoadingComponent = ({ message }) => {
    return (
      <span style={{ textAlign: "center", fontSize: "1.2rem" }}>
        <Loading />
        <p>{message}</p>
      </span>
    );
  };

  const customStylesClientSelect = {
    textAlign: "end",
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "2px 0px 2px 8px",
    }),
    control: (base, state) => ({
      ...base,
      minHeight: "40px !important",
      minWidth: "150px",
      borderRadius: "8px",
      border: "1px solid black",
      width: "200px",
      fontWeight: 600,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  const switchContainerStyle = {
    display: "flex",
    borderRadius: "50px",
    backgroundColor: "#f8f9fa",
    minWidth: "120px",
  };

  const switchButtonStyle = (isActive) => ({
    flex: 1,
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "50px",
    backgroundColor: isActive ? "#2563eb" : "white",
    color: isActive ? "white" : "#64748b",
    border: "none",
    outline: "none",
    transition: "background-color 0.3s, color 0.3s",
  });

  return (
    <div style={{ width: "90%", margin: "auto", padding: 50 }}>
      <div className={`${classes.invoiceFormContainer}`}>
        {rows.map((row, index) => {
          return (
            <>
              <div
                key={row.id}
                style={{
                  display: "flex",
                  gap: 30,
                  marginBottom: "20px",
                  backgroundColor: "#b9d6fa",
                  borderRadius: "8px",
                  padding: "12px 10px",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <Button
                  size="small"
                  className={`${classes.postButton}`}
                  onClick={() => removeRow(row.id)}
                  style={{ display: index === 0 && "none" }}
                >
                  <DeleteIcon color="error" />
                </Button>

                {index !== 0 && (
                  <div style={switchContainerStyle}>
                    <button
                      onClick={() => handleFieldChange(row.id, "operator", "AND")}
                      style={switchButtonStyle(row.operator === "AND")}
                    >
                      AND
                    </button>
                    <button
                      onClick={() => handleFieldChange(row.id, "operator", "OR")}
                      style={switchButtonStyle(row.operator === "OR")}
                    >
                      OR
                    </button>
                  </div>
                )}
                <div style={{ width: "200px" }}>
                  <Select
                    onChange={(option) => handleFieldChange(row.id, "columnName", option.value)}
                    defaultValue={{ label: "Select Column", value: "" }}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    options={ColumnNames}
                    styles={customStylesClientSelect}
                  />
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span>
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <input
                          type="checkbox"
                          checked={row.columnFilterNotOperator}
                          onChange={(e) =>
                            handleFieldChange(row.id, "columnFilterNotOperator", e.target.checked)
                          }
                          style={{
                            position: "absolute",
                            opacity: 0,
                            cursor: "pointer",
                            width: "12px",
                            height: "12px",
                          }}
                        />
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                            border: "2px solid #1976d2",
                            borderRadius: "50%",
                            padding: "2px",
                            background: "white",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              background: row.columnFilterNotOperator ? "#1976d2" : "transparent",
                              transition: "background-color 0.2s",
                            }}
                          />
                        </div>
                      </div>
                      <span
                        style={{
                          color: "#666",
                          fontSize: "18px",
                          fontWeight: 600,
                        }}
                      >
                        NOT
                      </span>
                    </label>
                  </span>
                  <Select
                    onChange={(option) => handleFieldChange(row.id, "columnFilter", option.value)}
                    defaultValue={{ label: "Select Filter", value: "" }}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    options={columnFilterOptions}
                    styles={customStylesClientSelect}
                  />
                </div>
                {renderDynamicField(row)}
              </div>
            </>
          );
        })}
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={`${classes.postButton}`}
          style={{
            marginBottom: "20px",
            textDecoration: "none",
            textTransform: "none",
            alignSelf: "right",
            padding: "7px 20px",
          }}
          onClick={addRow}
          autoCapitalize="false"
        >
          Add Clause
        </Button>
        <div
          style={{
            marginTop: "50px",
            backgroundColor: "#b9d6fa",
            borderRadius: "8px",
            padding: "12px 10px",
          }}
        >
          <Select
            placeholder="Select Columns Order"
            onChange={(e) => {
              handleColumnOrderChange(e.value);
            }}
            value={{ label: "Select Columns Order", value: "" }}
            options={ColumnNames}
            styles={{
              ...customStylesClientSelect,
              control: (base, state) => ({
                ...base,
                ...customStylesClientSelect.control(),
                width: "100%",
              }),
            }}
          />
          <div style={{ marginTop: "5px" }}>
            {columnsOrder.map((value, index) => (
              <Chip
                key={index}
                label={getHeaderLabel(value)}
                onDelete={() => {
                  setColumnsOrder(columnsOrder.filter((v) => v !== value));
                }}
                style={{
                  margin: "4px 4px 4px 0",
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "16px",
                  border: "1px solid black",
                  borderRadius: "8px",
                  padding: "19px 10px",
                }}
                size="small"
                deleteIcon={<CloseIcon style={{ color: "black", fontSize: "18px" }} />}
              />
            ))}
          </div>
        </div>
        <div style={{ gap: 10, display: "flex" }}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={`${classes.postButton}`}
            style={{
              marginBottom: "20px",
              textDecoration: "none",
              textTransform: "none",
              alignSelf: "right",
              padding: "7px 20px",
              marginTop: "20px",
            }}
            autoCapitalize="false"
            onClick={fetchRecords}
          >
            Report
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={`${classes.postButton}`}
            style={{
              marginBottom: "20px",
              textDecoration: "none",
              textTransform: "none",
              alignSelf: "right",
              padding: "7px 20px",
              marginTop: "20px",
            }}
            autoCapitalize="false"
            onClick={fetchInvoices}
          >
            Invoice
          </Button>
        </div>
        {apiLoading ? (
          <LoadingComponent message={"Fetching Data"} />
        ) : (
          <DataTable headers={headers} reportData={reportData} />
        )}
      </div>
    </div>
  );
}

export default Invoices;
