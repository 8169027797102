import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { HOME } from "../../types/routeConstants";

import { makeStyles } from "@material-ui/styles";

import { Button, Tooltip } from "@material-ui/core";

import GlobalStyles from "../../helpers/GlobalStyles";
import UploadBatch from "./UploadBatch/UploadBatch";
import IndividualUpload from "./IndividualUpload/IndividualUpload";
// import CustomMenu from "../components/CustomMenu";

const useStyles = makeStyles(
  (theme) => ({
    content: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    postButton: {
      backgroundColor: theme.palette.primary.main,

      fontSize: ".8em",
      color: "#ffffff",
      textTransform: "inherit",
      marginRight: "2%",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      marginRight: "5%",
      marginLeft: "5%",
      border: "1px solid #3C72D9",
      height: "40px",
      width: "130px",

      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
  }),
  { index: 1 }
);

/**

A React functional component for rendering the Home tab. 
Use can be directed to Upload Batch or Individual Upload from here

*/
function Home() {
  const classes = useStyles();
  const globalClasses = GlobalStyles();
  const location = useLocation();

  const navigate = useNavigate();
  const [view, setView] = useState(null);

  useEffect(() => {
    if (location.pathname != HOME.path) {
      setView(location.pathname);
    } else {
      setView(null);
    }
  }, [location.pathname]);

  // Function for switching between client, order, and fee tabs
  const clientTabSwitch = () => {
    switch (view) {
      case HOME.childPath1: {
        return <UploadBatch />;
      }
      case HOME.childPath2:
        return <IndividualUpload />;
    }
  };

  return (
    <div className={`${globalClasses.root} ${classes.content}`}>
      {view ? (
        clientTabSwitch(view)
      ) : (
        <>
          <Tooltip title="Go To Batch Upload">
            <Button
              className={classes.postButton}
              onClick={() => navigate(HOME.childPath1)}
            >
              Batch Upload
            </Button>
          </Tooltip>
          <Tooltip title="Go To Individual Upload">
            <Button
              className={classes.postButton}
              onClick={() => navigate(HOME.childPath2)}
            >
              Individual Upload
            </Button>
          </Tooltip>
        </>
      )}
    </div>
  );
}

export default Home;
