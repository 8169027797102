import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../auth/AuthWrapper";
// import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import { Form, Formik } from "formik";
import { object, string } from "yup";
import { Button, InputBase, LinearProgress } from "@material-ui/core";

import globalStyles from "../../../helpers/GlobalStyles";

import Endpoint from "../../../endpoint";
import { alertError } from "../../../helpers";

import PredictionTable from "../../../components/PredictionTable";
import Loading from "../../Loading";

import NoSearchResults from "../../../dialogs/NoSearchResults";

const useStyles = makeStyles(
  (theme) => ({
    contentDivider: {
      margin: 0,
      marginLeft: "3vh",
      marginRight: "3vh",
      marginTop: "30px",
      borderLeft: "1px solid #00713E",
    },
    content: {
      width: "100%",
      paddingTop: "calc(3vh + 100px)",
      paddingLeft: "4vh",
      marginRight: "50vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    uploadBatchcontentTableDashboard: {
      width: "100%",
      paddingTop: "calc(3vh + 50px)",
      paddingLeft: "5vh",
      paddingRight: "2vh",
      marginRight: "50vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    updateBatchContent: {
      width: "100%",
      paddingTop: "calc(30vh + 100px)",
      paddingLeft: "39%",
      // marginRight: "10vh",
      fontFamily: "Helvetica Neue",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
      alignItems: "center",
      marginBottom: "10px",
    },
    actions: {
      alignItems: "center",
      marginBottom: "10px",
    },
    actionsUpdateBatch: {
      // marginTop: "200px",
      // fontFamily: "Helvetica Neue",
    },
    contentTitle: {
      color: "#147CFF",
      marginBottom: "10px",
      fontSize: "25px",
    },
    tableSearchAddress: {
      paddingRight: "1vh",
    },
    rightSide: {
      width: "100%",
    },
    form: {
      width: "30vh",
      minWidth: "250px",
      background: "#147CFF",
      padding: "25px",
      alignSelf: "flex-start",
      borderRadius: "3vh",
    },
    input: {
      height: "30px",
      width: "100%",
      padding: theme.spacing(2),
      marginTop: "3px",
      borderRadius: "30px",
      fontSize: "14px",
      backgroundColor: "#FFFFFF",
    },
    field: {
      // border: '1px solid red',
      width: "100%",
      margin: "0auto",
      // marginTop: '12px',
      marginTop: "0",
      "& label": {
        fontSize: "18px",
        color: "#FFFFFF",
      },
    },
  }),
  { index: 1 }
);

/**

A React functional component for rendering the Individual Upload Page

*/
function IndividualUpload() {
  const classes = useStyles();
  const { user, isLoading } = useContext(AuthContext);

  const globalClasses = globalStyles();

  const [showNoSearchResultsDialog, setShowNoSearchResultsDialog] =
    useState(false);
  const [statusList, setStatusList] = useState([]);

  // Function to show when no search results are returned
  const openNoSearchResultsDialog = () => {
    setShowNoSearchResultsDialog(true);
  };

  const handleCloseNoSearchResultsDialog = () => {
    setShowNoSearchResultsDialog(false);
  };

  // Function to get realtime-occupancy-prediction
  const handleSubmit = (values, { setSubmitting }) => {
    console.log("values");
    console.log(values);
    Endpoint.Users.getUserDetails(values)
      .then((res) => {
        console.log(res);
        // if (res.data.length === 0) {
        if (res.data.errorType === "IndexError") {
          openNoSearchResultsDialog();
        } else {
          setStatusList([res.data]);
          console.log(res);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log("Error!!!!!!!");
        console.log(err);
        alertError("something went wrong", err);
        setSubmitting(false);
      });
  };

  return (
    <div className={`${globalClasses.root}`}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={`${classes.content} ${globalClasses.flexRow}`}>
          {" "}
          <NoSearchResults
            handleCloseNoSearchResultsDialog={handleCloseNoSearchResultsDialog}
            showNoSearchResultsDialog={showNoSearchResultsDialog}
          />
          <div className={globalClasses.flexColumn}>
            <div className={`${classes.actions} ${globalClasses.flexRow}`}>
              <span className={classes.contentTitle}>Property Details</span>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                username: user ? user.username : null,
                first_name: "",
                last_name: "",
                street: "",
                city: "",
                state: "",
                zip: "",
              }}
              validationSchema={object().shape({
                first_name: string().required("Required."),
                last_name: string().required("Required."),
                street: string().required("Required."),
                city: string().required("Required."),
                state: string().required("Required."),
                zip: string().required("Required."),
              })}
              onSubmit={handleSubmit}
              validateOnBlur={false}
            >
              {({ isSubmitting, handleChange, errors }) => (
                <Form className={`${classes.form} ${globalClasses.flexColumn}`}>
                  <div
                    className={`${classes.field} ${globalClasses.flexColumn}`}
                  >
                    <label htmlFor="first_name">First name*</label>
                    <InputBase
                      className={classes.input}
                      name="first_name"
                      onChange={handleChange}
                      disabled={isSubmitting}
                      error={!!errors.first_name}
                    />
                    {
                      <div
                        className={`${globalClasses.validateError}`}
                        style={{
                          visibility: `${
                            errors.first_name ? "visible" : "hidden"
                          }`,
                        }}
                      >
                        {errors.first_name || "Required"}
                      </div>
                    }
                  </div>
                  <div
                    className={`${classes.field} ${globalClasses.flexColumn}`}
                  >
                    <label htmlFor="last_name">Last name*</label>
                    <InputBase
                      className={classes.input}
                      name="last_name"
                      onChange={handleChange}
                      disabled={isSubmitting}
                      error={!!errors.last_name}
                    />
                    {
                      <div
                        className={`${globalClasses.validateError}`}
                        style={{
                          visibility: `${
                            errors.last_name ? "visible" : "hidden"
                          }`,
                        }}
                      >
                        {errors.last_name || "Required"}
                      </div>
                    }
                  </div>
                  <div
                    className={`${classes.field} ${globalClasses.flexColumn}`}
                  >
                    <label htmlFor="street">Street name*</label>
                    <InputBase
                      className={classes.input}
                      name="street"
                      onChange={handleChange}
                      disabled={isSubmitting}
                      error={!!errors.street}
                    />
                    {
                      <div
                        className={`${globalClasses.validateError}`}
                        style={{
                          visibility: `${errors.street ? "visible" : "hidden"}`,
                        }}
                      >
                        {errors.street || "Required"}
                      </div>
                    }
                  </div>
                  <div
                    className={`${classes.field} ${globalClasses.flexColumn}`}
                  >
                    <label htmlFor="city">City*</label>
                    <InputBase
                      className={classes.input}
                      name="city"
                      onChange={handleChange}
                      disabled={isSubmitting}
                      error={!!errors.city}
                    />
                    {
                      <div
                        className={`${globalClasses.validateError}`}
                        style={{
                          visibility: `${errors.city ? "visible" : "hidden"}`,
                        }}
                      >
                        {errors.city || "Required"}
                      </div>
                    }
                  </div>
                  <div
                    className={`${classes.field} ${globalClasses.flexColumn}`}
                  >
                    <label htmlFor="state">State*</label>
                    <InputBase
                      className={classes.input}
                      name="state"
                      onChange={handleChange}
                      disabled={isSubmitting}
                      error={!!errors.state}
                    />
                    {
                      <div
                        className={`${globalClasses.validateError}`}
                        style={{
                          visibility: `${errors.state ? "visible" : "hidden"}`,
                        }}
                      >
                        {errors.state || "Required"}
                      </div>
                    }
                  </div>
                  <div
                    className={`${classes.field} ${globalClasses.flexColumn}`}
                  >
                    <label htmlFor="zip">Zip code*</label>
                    <InputBase
                      className={classes.input}
                      name="zip"
                      onChange={handleChange}
                      disabled={isSubmitting}
                      error={!!errors.zip}
                    />
                    {
                      <div
                        className={`${globalClasses.validateError}`}
                        style={{
                          visibility: `${errors.zip ? "visible" : "hidden"}`,
                        }}
                      >
                        {errors.zip || "Required"}
                      </div>
                    }
                  </div>
                  <div
                    className={`${classes.field} ${globalClasses.flexColumn}`}
                  >
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      className={`${globalClasses.button}`}
                    >
                      Submit
                      {isSubmitting && (
                        <LinearProgress
                          color="primary"
                          className={globalClasses.linearProgress}
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <hr className={classes.contentDivider} />
          <div
            className={`${classes.rightSide} ${globalClasses.flexColumn} ${classes.tableSearchAddress}`}
          >
            <span className={classes.contentTitle}>Status</span>
            <PredictionTable statusList={statusList} isAdmin={user?.isAdmin} />
          </div>
        </div>
      )}
    </div>
  );
}

export default IndividualUpload;
