import React from "react";
import { Input, InputAdornment, makeStyles } from "@material-ui/core";
import { Field, ErrorMessage } from "formik";
import FormError from "../../components/FormError";
import Select, { components } from "react-select";

import FMUInputField from "../../components/shared/FormIkMUFormComponent/FMUInputField";
import { Typography } from "@material-ui/core";

import { convertToAcronym } from "../../helpers/helper";

const useStyles = makeStyles((theme) => ({
  parentChild: {
    height: "30px",
    flex: "1",
    width: "100%",
    "&:nth-child(1)": {
      // paddingRight: "5vh",
    },
    "&:nth-child(2)": {
      // paddingLeft: "5vh",
    },
  },
  rowContainer: {
    display: "flex",
    // marginTop: "8px",
  },
  labelContainer: {
    display: "flex",
    width: "10%",
    alignItems: "center",
    "& p": {
      fontSize: "1em",
      // // fontWeight:"bold",
      color: "black",
    },
  },
  fieldContainer: {
    width: "100%",
  },
  input: {
    width: "100%",
    "& .MuiInputBase-input": {
      minWidht: "40px",
      fontSize: "1rem",
      textTransform: "capitalize",
      textAlign: "end",
    },
    height: "30px",
    paddingLeft: "3px",
    // borderBottom: "1px solid #BABABA",
    backgroundColor: "white",
  },
  emailInput: {
    // width: "95%",
    "& .MuiInputBase-input": {
      minWidht: "40px",
      fontSize: "1rem",
      // textIndent: "10px",
      textAlign: "end",
    },
    height: "30px",
    paddingLeft: "3px",

    // borderRadius: "10px",
    // border: "1px solid #9dc8d2",
    // backgroundColor: "#f8f8f8",
    // borderBottom: "1px solid #BABABA",
    backgroundColor: "white",
  },
  dateInput: {
    // width: "95%",
    "& .MuiInputBase-input": {
      minWidht: "40px",
      // textIndent: "10px",
      fontSize: "1rem",
      textAlign: "end",
    },
    height: "30px",
    paddingLeft: "3px",
    // borderBottom: "1px solid #BABABA",
  },
  textAreaInput: {
    // width: "95%",
    "& .MuiInputBase-input": {
      minWidht: "40px",
      // textIndent: "10px",
      fontSize: "1rem",
      textAlign: "left",
    },
    // height: "100px",

    paddingLeft: "3px",
    // border: "1px solid #BABABA",
  },
  error: {
    fontSize: "12px",
    color: "red",
    // marginTop: "0.25rem",
    paddingLeft: "9%",
    display: "flex",
  },
}));

const customStyles = {
  textAlign: "end",
  menu: (styles) => ({ ...styles, width: "200px", zIndex: 9999 }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  input: (styles) => ({ ...styles, margin: "0px" }),
  control: (base, state) => ({
    ...base,
    // width: "50px",
    maxHeight: "20px",
    minHeight: "20px",
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,
    borderStyle: null,
    borderBottom: null,
    // Removes weird border around container
    boxShadow: null,
  }),
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    {props.data.chipLabel}
  </components.SingleValue>
);

/**

A React functional component for rendering the File Search Results Page fields.

*/
function FileField({
  label,
  name,
  type,
  disabled,
  placeHolder,
  isEmail,
  required,
  input,
  customOnChange,
  style,
  setFieldValue,
  options,
  inputLabel,
  inputValue,
  value,
  icon,
}) {
  const handleOnChange = (e) => {
    return customOnChange(e);
  };
  const classes = useStyles();
  {
    // console.log(options?.chipLabel);
  }
  // Function for switching between client, order, and fee tabs
  const fieldSwitch = () => {
    switch (type) {
      case "select":
        return (
          <div style={{ minWidth: "100px" }}>
            <Select
              value={
                options
                  ? options.find((option) => option[inputValue] === value)
                  : ""
              }
              // value={values[name]}
              onChange={(e) => {
                console.log(e, inputValue);
                console.log(name, e[inputValue]);
                setFieldValue(name, e[inputValue], false);
              }}
              getOptionLabel={(option) => option[inputLabel]}
              getOptionValue={(option) => option[inputValue]}
              options={options}
              styles={customStyles}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              components={{ SingleValue }}
              disabled={disabled}
            />
          </div>
        );
      default:
        return (
          <div className={classes.fieldContainer}>
            <Field
              name={name}
              type={type}
              disabled={disabled}
              fullwidth={true}
              style={style}
              disableUnderline={true}
              startAdornment={
                icon ? (
                  <InputAdornment position="start">$</InputAdornment>
                ) : null
              }
              className={
                isEmail
                  ? classes.emailInput
                  : type === "date"
                  ? classes.dateInput
                  : type === "TextArea"
                  ? classes.textAreaInput
                  : classes.input
              }
              component={FMUInputField}
              label={placeHolder ? placeHolder : label}
            />
          </div>
        );
    }
  };
  return (
    <div className={classes.parentChild}>
      <div className={classes.rowContainer}>
        <div className={classes.labelContainer}>
          {required ? (
            <Typography>
              {label}
              <span style={{ color: "red", fontWeight: 500 }}>*</span>
            </Typography>
          ) : (
            <Typography>{label}</Typography>
          )}
        </div>
        {fieldSwitch(type)}
      </div>
      <div>
        <ErrorMessage
          name={name}
          component={FormError}
          className={classes.error}
        />
      </div>
    </div>
  );
}

export default FileField;
