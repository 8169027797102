import { createStyles, makeStyles } from "@material-ui/core";

const GlobalStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        height: "100vh",
        alignItems: "center",
      },
      flexColumn: {
        display: "flex",
        flexDirection: "column",
      },
      flexRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center", //testing might remove
      },
      flexRowReverse: {
        display: "flex",
        flexDirection: "row-reverse",
      },
      noSelect: {
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",
      },
      validateError: {
        color: "#f3c2c2",
        margin: "3px",
        marginLeft: "10px",
        fontSize: "11px",
        fontWeight: "bolder",
        // position: "absolute",
        // marginTop: "4%",
        // width: "100%",
      },
      validateErrorFilter: {
        color: "#f3c2c2",
        margin: "3px",
        marginLeft: "10px",
        fontSize: "11px",
        fontWeight: "bolder",
        inlineSize: "150px",
        overflowWrap: "break-word",
        // width: "100%",
      },

      validateErrorRed: {
        color: "red",
      },
      input: {
        height: "35px",
        width: "100%",
        padding: theme.spacing(2),
        marginTop: "3px",
        borderRadius: "30px",
        fontSize: "14px",
        border: "2px solid #2E414C; !important",
      },
      inputFilter: {
        height: "30px",
        width: "100%",
        padding: "4px",
        marginTop: "4px",
        // marginBottom: "15px",
        fontSize: "12px",
        border: "1px solid #808080; !important",
      },
      button: {
        width: "120px",
        height: "30px",
        fontSize: "16px",
        margin: "auto",
        marginTop: "12px",
        textTransform: "none",
      },

      filterButton: {
        width: "80px",
        height: "30px",
        fontSize: "14px",
        marginTop: "12px",
        textTransform: "none",
        alignSelf: "end",
      },
      actionButton: {
        backgroundColor: theme.palette.primary.main,
        fontSize: ".8em",
        color: "#ffffff",
        textTransform: "inherit",
        borderRadius: "50px",
        boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
        textAlign: "center",
        border: "1px solid #3C72D9",
        // height: "15px",
        padding: "8px",
        width: "100px",
        // border: "1px solid #3C72D9",
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          opacity: "0.8",
        },
        "&.Mui-disabled": {
          backgroundColor: "#e7e7e7",
          color: "white",
        },
      },
      linearProgress: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        opacity: 0.4,
        borderRadius: "50px",
      },
      clientTable: {
        width: "100%",
        textAlign: "left",
        // color: "#4e4e4e",
        borderCollapse: "separate",
        borderSpacing: "0 15px",
        fontSize: "1em",
        // maxWidth: "fit-content",
        // overflow: "visible",
        // display: "block",
        // overflow: "auto",
        whiteSpace: "nowrap",
      },
      tableHead: {
        backgroundColor: "#e7e7e7",
      },
      clientthtr: {
        backgroundColor: "#0000",
        borderRadius: "25px",
        paddingTop: "5px",
        height: "30px",
      },

      tableTd: {
        paddingLeft: "10px",
        whiteSpace: "nowrap",
      },
      tableTdName: {
        cursor: "pointer",
        color: "#197EFF",
        paddingLeft: "10px",
        whiteSpace: "nowrap",
      },
      tableTh: {
        textAlign: "left",
        paddingLeft: "10px",
        paddingRight: "10px",
        whiteSpace: "nowrap",
      },
      tableIcons: {
        // overflow: "visible",
        cursor: "pointer",
        marginRight: "15px",
        width: "20px",
        "&:hover": {
          color: "red",
        },
      },
    }),
  { index: 1 }
);

export default GlobalStyles;
