import React from "react";
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Box,
  Fade,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { convertToAcronym } from "../../helpers/helper";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { ACCOUNT } from "../../types/routeConstants";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(
  (theme) => ({
    avatarIcons: {
      fontSize: "1.5em",
      marginRight: "10px",
    },
  }),
  { index: 1 }
);

/**

A React functional component for User Avatar in header
This is used in the header.

*/
const UserAvatar = ({ username, handleLogout }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    // console.log(e.target.outerText);
    if (e.target.outerText == "Logout") {
      handleLogout();
    } else if (e.target.outerText == "Account") {
      // Go to Account
      navigate(ACCOUNT.path);
    }

    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Avatar
          src={
            "Usman Khalid" || "https://www.w3schools.com/howto/img_avatar.png"
          }
          style={{ width: 34, height: 34 }}
        >
          {convertToAcronym(username)}
        </Avatar>
      </IconButton>

      <Box>
        <Menu
          PaperProps={{
            style: {
              transform: "translateX(-10px) translateY(50px)",
            },
          }}
          disableScrollLock={true}
          TransitionComponent={Fade}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <ManageAccountsIcon className={classes.avatarIcons} /> {` Account`}
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ExitToAppIcon className={classes.avatarIcons} /> {` Logout`}
          </MenuItem>
        </Menu>
      </Box>
    </div>
  );
};

export default UserAvatar;
