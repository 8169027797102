export const HOME = {
  index: 0,
  path: "/",
  isTab: true,
  childPath1: "/batch-upload",
  childPath2: "/individual-upload",
};
export const DASHBOARD = { index: 1, path: "/dashboard", isTab: true };
export const CLIENT = {
  index: 2,
  path: "/client",
  isTab: true,
  childPathAdd: "/client/add",
  childPathView: "/client/view",
  childPathEdit: "/client/edit",
  childPathFee: "/fee",
};
export const SEARCH = {
  index: 3,
  path: "/search",
  isTab: false,
  childPathFile: "/search/:id",
};
export const ACCOUNT = { index: 4, path: "/account", isTab: false };
export const LOGIN = { index: -1, path: "/login", isTab: false };
