import { Button, IconButton, makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { array, object, string } from "yup";
import Endpoint from "../../endpoint/index";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import FeeList from "./FeeList";

// import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIosNewIcon";

const useStyle = makeStyles((theme) => ({
  caseContainer: {
    // width: "928px",
    width: "98%",
    paddingLeft: "2%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  topBar: {
    height: "50px",
    // backgroundColor: "#f8f8f8",
    padding: "0 2% 0 2%",
    display: "flex",
    alignItems: "center",
    // marginTop: "6vh",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: "1.2em",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  postButton: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: "poppins",
    //fontWeight: "bold",
    fontSize: "1em",
    color: "#ffffff",
    textTransform: "inherit",
    // borderRadius: "5px",
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    textAlign: "center",
    marginBottom: "2%",
    // marginRight: "2%",
    border: "1px solid #3C72D9",
    height: "40px",
    marginTop: "20px",
    width: "120px",

    // border: "1px solid #3C72D9",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e7e7e7",
      color: "white",
    },
  },
  contactLength: {
    fontSize: "13px",
    color: "#4e4e4e",
  },
  backButton: {
    border: "1px solid",
    padding: "5px",
    marginRight: "20px",
  },
}));

const customStyles = {
  textAlign: "end",
  control: (base, state) => ({
    ...base,
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
};

/**
A React functional component for Fee Line Tab for a client.
*/
function FeeTab({ fee, setFee, initState, getClient }) {
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  // const [dialog, setDialog] = useState(false);

  const formRef = useRef();

  // Handle Submit Function for adding/updating/deleting a client's FEE LINE.
  const handleSubmit = (v) => {
    console.log(v);
    Endpoint.Clients.addClient(v)
      .then((res) => {
        enqueueSnackbar("Fee Line updated successfully", {
          variant: "success",
        });

        getClient(v.client_id);
      })
      .catch((err) => {
        enqueueSnackbar("Sorry something went wrong, please try again later.", {
          variant: "error",
        });
      });
  };
  const validateSchema = object().shape({
    fee_lines: array().of(
      object().shape({
        orderID: string().required("Order is required"),
        amount: string().required("Amount is required"),
        start_date: string().optional(),
        end_date: string().optional(),
      })
    ),

    // attention: number().moreThan(0),
  });
  return (
    <>
      <div className={classes.topBar}>
        <span style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            className={classes.backButton}
            onClick={() => {
              setFee(false);
              navigate(-1);
            }}
          >
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
          <span className={classes.heading}>Back To Profile</span>
        </span>
      </div>
      <div className={classes.caseContainer}>
        <Formik
          enableReinitialize={true}
          initialValues={initState}
          validationSchema={validateSchema}
          validateOnBlur={false}
          innerRef={formRef}
          // validateOnChange
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          render={({ isSubmitting, dirty, touched, values, errors, setFieldValue }) => (
            <Form>
              {/* <FieldArray
                name="fee_lines"
                render={(arrayHelpers) => ( */}
              <div style={{ width: "70%" }}>
                <FeeList
                  values={values}
                  setFieldValue={setFieldValue}
                  customStyles={customStyles}
                  handleSubmit={handleSubmit}

                  // arrayHelpers={arrayHelpers}
                />

                <Button
                  className={classes.postButton}
                  style={{ marginTop: "40px", float: "right" }}
                  type={"submit"}
                >
                  Save Changes
                </Button>
              </div>
              {/* )}
              /> */}
            </Form>
          )}
        />
      </div>
    </>
  );
}

export default FeeTab;
