import React from "react";
import { Input, makeStyles } from "@material-ui/core";
import { Field, ErrorMessage } from "formik";
import FormError from "../../FormError";

import FMUInputField from "../FormIkMUFormComponent/FMUInputField";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  parentChild: {
    height: "40px",
    flex: "1",
    width: "100%",
    "&:nth-child(1)": {
      paddingRight: "5vh",
    },
    "&:nth-child(2)": {
      paddingLeft: "5vh",
    },
  },
  rowContainer: {
    display: "flex",
    marginTop: "8px",
  },
  labelContainer: {
    display: "flex",
    width: "10%",
    alignItems: "center",
    "& p": {
      fontSize: "1em",
      // // fontWeight:"bold",
      color: "black",
    },
  },
  fieldContainer: {
    width: "100%",
  },
  input: {
    width: "100%",

    "& .MuiInputBase-input": {
      // textIndent: "10px",
      fontSize: "1rem",
      textTransform: "capitalize",
      textAlign: "start",
    },
    height: "30px",
    paddingLeft: "3px",
    // borderBottom: "1px solid #BABABA",
    backgroundColor: "white",
  },
  emailInput: {
    width: "100%",
    "& .MuiInputBase-input": {
      fontSize: "1rem",
      // textIndent: "10px",
      textAlign: "start",
    },
    height: "30px",
    paddingLeft: "3px",

    // borderRadius: "10px",
    // border: "1px solid #9dc8d2",
    // backgroundColor: "#f8f8f8",
    // borderBottom: "1px solid #BABABA",
    backgroundColor: "white",
  },
  dateInput: {
    // width: "95%",
    "& .MuiInputBase-input": {
      // textIndent: "10px",
      fontSize: "1rem",
      textAlign: "end",
    },
    height: "30px",
    paddingLeft: "3px",
    // borderBottom: "1px solid #BABABA",
  },
  textAreaInput: {
    // width: "95%",
    "& .MuiInputBase-input": {
      // textIndent: "10px",
      fontSize: "1rem",
      textAlign: "left",
    },
    // height: "100px",

    paddingLeft: "3px",
    // border: "1px solid #BABABA",
  },
  error: {
    fontSize: "12px",
    color: "red",
    // marginTop: "0.25rem",
    paddingLeft: "9%",
    display: "flex",
  },
}));
function InvoiceField({
  label,
  name,
  type,
  disabled,
  placeHolder,
  isEmail,
  required,
  input,
  customOnChange,
  style,
}) {
  const handleOnChange = (e) => {
    return customOnChange(e);
  };
  const classes = useStyles();
  return (
    <div className={classes.parentChild}>
      <div className={classes.rowContainer}>
        <div className={classes.labelContainer}>
          {required ? (
            <Typography>
              {label}
              <span style={{ color: "red", fontWeight: 500 }}>*</span>
            </Typography>
          ) : (
            <Typography>{label}</Typography>
          )}
        </div>
        <div className={classes.fieldContainer}>
          {input ? (
            <Input
              name={name}
              rows={type == "TextArea" ? 10 : 1}
              type={type}
              disabled={disabled}
              fullwidth={true}
              onChange={handleOnChange}
              disableUnderline={true}
              className={
                isEmail
                  ? classes.emailInput
                  : type === "date"
                  ? classes.dateInput
                  : type === "TextArea"
                  ? classes.textAreaInput
                  : classes.input
              }
              label={placeHolder ? placeHolder : label}
            />
          ) : (
            <Field
              name={name}
              type={type}
              disabled={disabled}
              fullwidth={"true"}
              style={style}
              disableUnderline={true}
              className={
                isEmail
                  ? classes.emailInput
                  : type === "date"
                  ? classes.dateInput
                  : type === "TextArea"
                  ? classes.textAreaInput
                  : classes.input
              }
              component={FMUInputField}
              label={placeHolder ? placeHolder : label}
            />
          )}
        </div>
      </div>
      <div>
        <ErrorMessage
          name={name}
          component={FormError}
          className={classes.error}
        />
      </div>
    </div>
  );
}

export default InvoiceField;
