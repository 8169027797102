import { useEffect, useState } from "react";

import "./UploadBatchTable.css";

import { ErrorMessage, FieldArray, Form, Formik } from "formik";

import Select from "react-select";
import { Button, Tooltip } from "@material-ui/core";

import globalStyles from "../../../helpers/GlobalStyles";

import ReportIconProblem from "@mui/icons-material/ReportProblem";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import UploadBatchField from "../../../components/shared/UploadBatchField";
import { states } from "../../../helpers/states";

import DuplicateCheck from "../../../dialogs/DuplicateCheck";
import Endpoint from "../../../endpoint";
import Loading from "../../Loading";
import { useSnackbar } from "notistack";
import { getHeaderLabel } from "../../../helpers/helper";

/**

A React functional component for rendering the Upload Batch page

*/
function DuplicateTable({
  classes,
  customStyles,
  headers,
  data,
  reset,
  s3Key,
  currentStage,
  uploadingToInputTable,
  setupDataForDuplicateCheckResponse,
  setDuplicateCheckInProgress,
  duplicateCheckInProgress,
  uploadDataToInputTable,
  setUploadingToInputTable,
  skippingZillow,
}) {
  const globalClasses = globalStyles();
  const [showProceedDialog, setShowProceedDialog] = useState(false);
  const [type, setType] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseProceedDialog = (type) => {
    setShowProceedDialog(false);
    if (type == 1) {
      reset();
    }
  };

  const checkDuplicates = async () => {
    try {
      setDuplicateCheckInProgress(true);
      const res = await Endpoint.BatchUpload.duplicateCheck({ s3Key });

      // Add 'skip_status' field to each message object and set it to false
      setupDataForDuplicateCheckResponse(res.data);
    } catch (error) {
      enqueueSnackbar("Failed to check duplicates", {
        variant: "error",
      });
      console.log("Error in checkDuplicates", error);
    }
  };

  const handleSubmit = (values, type) => {
    uploadDataToInputTable(values.data);
    setShowProceedDialog(false);
  };

  useEffect(() => {
    if (skippingZillow === false && s3Key) checkDuplicates();
  }, [skippingZillow, s3Key]);

  useEffect(() => {
    setUploadingToInputTable(false);
  }, []);

  const LoadingComponent = ({ message }) => {
    return (
      <span className={classes.fileLoadingStyles}>
        <Loading />
        <p style={{ marginLeft: "10px" }}>{message}</p>
      </span>
    );
  };

  return (
    <>
      {duplicateCheckInProgress ? (
        <LoadingComponent message="Checking duplicates..." />
      ) : (
        <Formik enableReinitialize initialValues={{ data }} onSubmit={(values) => {}}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <FieldArray
                  name="data" // Use data directly
                  render={(arrayHelpers) => (
                    <>
                      <div style={{}}>
                        <table className={classes.clientTable}>
                          <thead className={globalClasses.tableHead}>
                            <tr className={globalClasses.clientthtr}>
                              <>
                                <th className={classes.tableTh} style={{ width: "40px" }}>
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;
                                      values.data.forEach((_, index) => {
                                        setFieldValue(`data.${index}.skip_status`, !isChecked);
                                      });
                                    }}
                                  />
                                </th>
                                {headers.map((header) => {
                                  if (header === "skip_status") return null;
                                  return (
                                    <th className={classes.tableTh} key={header}>
                                      {getHeaderLabel(header)}
                                    </th>
                                  );
                                })}
                              </>
                            </tr>
                          </thead>
                          <tbody>
                            {values.data.map((row, index) => (
                              <tr
                                style={{
                                  fontSize: "1em",
                                  color: "#4e4e4e",
                                }}
                                key={index}
                              >
                                <td className={classes.tableTd} style={{ textAlign: "center" }}>
                                  <div style={{ textAlign: "center" }}>
                                    {values.data[index]["skip_status"] ? (
                                      <Tooltip title="Skip Address">
                                        <CheckBoxOutlineBlankIcon
                                          className={classes.tableIconsUnskip}
                                          onClick={() =>
                                            setFieldValue(
                                              `data.${index}.${"skip_status"}`,
                                              !values.data[index]["skip_status"],
                                              false
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Unskip Address">
                                        <CheckBoxIcon
                                          className={classes.tableIconsSkip}
                                          onClick={() =>
                                            setFieldValue(
                                              `data.${index}.${"skip_status"}`,
                                              !values.data[index]["skip_status"],
                                              false
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                </td>
                                {headers.map((header) => {
                                  if (header === "skip_status") return null;
                                  return (
                                    <td className={classes.tableTd} key={header}>
                                      {header == "state" || header == "new_state" ? (
                                        <>
                                          <div className={classes.fieldContainer}>
                                            <div className={classes.parentChild}>
                                              <div className={classes.rowContainer}>
                                                <Select
                                                  value={{
                                                    label: values.data[index][header],
                                                  }}
                                                  onChange={(e) => {
                                                    setFieldValue(`data.${index}.${header}`, e.value, false);
                                                  }}
                                                  options={states}
                                                  styles={customStyles}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <>
                                            <div className={classes.fieldContainer}>
                                              {" "}
                                              <UploadBatchField
                                                type={"text"}
                                                name={`data.${index}.${header}`}
                                                setFieldValue={setFieldValue}
                                                value={values.data[index][header]}
                                              />
                                              <ErrorMessage name={`data.${index}.${header}`} />
                                            </div>
                                          </>
                                        </>
                                      )}
                                    </td>
                                  );
                                })}{" "}
                                <td className={classes.tableTd} style={{}}>
                                  <div style={{ textAlign: "center" }}>
                                    {values.data[index]["notes"] && (
                                      <Tooltip title="Missing Units">
                                        <ReportIconProblem className={classes.tableIconsAlert} />
                                      </Tooltip>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className={globalClasses.flexRow} style={{ alignContent: "center" }}>
                        <Tooltip title="End Batch Upload Process">
                          <Button
                            className={`${classes.actionButton} `}
                            style={{ marginLeft: "1%" }}
                            onClick={() => {
                              setType(1);
                              setShowProceedDialog(true);
                            }}
                          >
                            End
                          </Button>
                        </Tooltip>

                        {(currentStage === 2 || currentStage === 3) && (
                          <Tooltip title="Retrieve Data Sources">
                            <Button
                              className={`${classes.actionButton} `}
                              style={{ marginLeft: "1%" }}
                              onClick={() => {
                                setType(3);
                                setShowProceedDialog(true);
                              }}
                              disabled={uploadingToInputTable}
                            >
                              Retrieve Data Sources
                            </Button>
                          </Tooltip>
                        )}
                        {uploadingToInputTable && <LoadingComponent message="Processing..." />}
                      </div>
                      {type == 1 && (
                        <DuplicateCheck
                          showUploadBatchDialog={showProceedDialog}
                          setShowUploadBatchDialog={setShowProceedDialog}
                          handleCloseUploadBatchDialog={handleCloseProceedDialog}
                          msg={"Are You Sure You Want To End The Batch Upload Process?"}
                          type={type}
                        />
                      )}

                      {type == 3 && (
                        <DuplicateCheck
                          showUploadBatchDialog={showProceedDialog}
                          setShowUploadBatchDialog={setShowProceedDialog}
                          handleCloseUploadBatchDialog={handleCloseProceedDialog}
                          handleSubmit={handleSubmit}
                          msg={"Are You Sure You Want To Proceed?"}
                          secondaryMsg={"Note: This Will Incur Costs"}
                          type={type}
                          values={values}
                        />
                      )}
                    </>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}

export default DuplicateTable;
